import { AlertTriangleIcon, CircleAlertIcon } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "./alert";

type SystemMessageProps = {
  title: string;
  body: string;
  priority: "high" | "medium" | "low";
};

export function SystemMessage({ title, body, priority }: SystemMessageProps) {
  const Icon = priority === "high" ? AlertTriangleIcon : CircleAlertIcon;

  return (
    <Alert variant={priority}>
      <Icon className="h-4 w-4" />
      <AlertTitle>{title || <span>&nbsp;</span>}</AlertTitle>
      <AlertDescription>{body || <span>&nbsp;</span>}</AlertDescription>
    </Alert>
  );
}
