import useSWR from "swr";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { bisector } from "d3-array";
import { useContext, useEffect, useMemo, useState } from "react";
import { CogniteClient, Timeseries } from "@cognite/sdk";
import { Modal, App } from "antd";
import dayjs from "@properate/dayjs";
import _ from "lodash";
import {
  formatMeasurement,
  isOperationalHour,
  OperationalHoursType,
} from "@properate/common";
import { scaleLinear, scaleTime } from "@visx/scale";
import { Group } from "@visx/group";
import { Area, Line, LinePath } from "@visx/shape";
import {
  defaultStyles,
  Tooltip,
  useTooltip,
  useTooltipInPortal,
} from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { curveBasis } from "@visx/curve";
import styled, { ThemeContext } from "styled-components";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { PRIMARY } from "@/utils/ProperateColors";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { calculateSumOfEnergyDataPoints } from "@/utils/helpers";
import hd from "@/utils/holidays";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { formatCompact } from "@/utils/format";
import { NotificationInstance } from "@/utils/types";
import { WidgetHeader } from "../../widget-components/WidgetHeader";
import { DraggableCard } from "../../draggable/DraggableCard";
import { SettingModal } from "./SettingModal";

const ToolTipEpredTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  transform: translate(-108%, -20%);
  border-radius: 2px;
  padding: 4px 6px 4px 6px;
  background: white;
`;

interface Props {
  closedPublicHolidays: boolean;
  operationalHours: OperationalHoursType[];
  width: number;
  height: number;
  onClickRemoveButton: () => unknown;
}

type OverviewPoint = {
  empty?: boolean;
  time: number;
  value?: number;
  lastWeek?: number;
  max?: number;
  min?: number;
};

const getEnergy =
  (
    client: CogniteClient,
    closedPublicHolidays: boolean,
    operationalHours: OperationalHoursType[],
    notificationInstance: NotificationInstance,
  ) =>
  async (props: string) => {
    const result = await calculateSumOfEnergyDataPoints(
      client,
      notificationInstance,
    )(props);
    return result!.map(
      (d) =>
        ({
          empty:
            !isOperationalHour(
              dayjs(d.time),
              operationalHours,
              closedPublicHolidays,
            ) ||
            (closedPublicHolidays && hd.isHoliday(new Date(d.time))),
          ...d,
        }) as OverviewPoint,
    );
  };

const getEnergyEpredMin =
  (client: CogniteClient, notificationInstance: NotificationInstance) =>
  async (props: string) => {
    const result = await calculateSumOfEnergyDataPoints(
      client,
      notificationInstance,
    )(props);
    return result!.map(
      (d) =>
        ({
          time: d.time,
          min: d.value,
        }) as OverviewPoint,
    );
  };

const getEnergyEpredMax =
  (client: CogniteClient, notificationInstance: NotificationInstance) =>
  async (props: string) => {
    const result = await calculateSumOfEnergyDataPoints(
      client,
      notificationInstance,
    )(props);
    return result!.map(
      (d) =>
        ({
          time: d.time,
          max: d.value,
        }) as OverviewPoint,
    );
  };

const getEnergyTimeseries = async (client: CogniteClient, id: number[]) => {
  if (!id || id.length === 0) return [];
  return await client.timeseries
    .list({
      filter: {
        assetIds: id,
      },
    })
    .autoPagingToArray({ limit: -1 });
};

const getTimeseriesIds = async (
  client: CogniteClient,
  assetExternalId?: string,
) => {
  if (!assetExternalId) return [];
  const { items: data } = await client.timeseries.list({
    filter: {
      externalIdPrefix: assetExternalId,
    },
  });

  return data;
};

export function EnergyUsedThisWeekEpredWidget({
  closedPublicHolidays,
  operationalHours,
  width,
  height,
  onClickRemoveButton,
}: Props) {
  const t = useTranslations();
  const { client } = useCogniteClient();
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const { notification } = App.useApp();

  const {
    tooltipData: ePredTooltipData,
    tooltipLeft: ePredTooltipLeft,
    tooltipTop: ePredTooltipTop,
    tooltipOpen: ePredTooltipOpen,
    showTooltip: ePredShowTooltip,
    hideTooltip: ePredHideTooltip,
  } = useTooltip();
  const [minTimeseries, setMinTimeseries] = useState<Timeseries[]>([]);
  const [maxTimeseries, setMaxTimeseries] = useState<Timeseries[]>([]);
  const [ePredEnergyIds, setEPredEnergyIds] = useState<
    Timeseries[] | undefined
  >([]);

  const [showSettings, setShowSettings] = useState(false);
  const themeContext = useContext(ThemeContext);
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });
  const [start, end] = useMemo(
    () => [dayjs().startOf("w"), dayjs().endOf("w")],
    [],
  );

  const currentBuildingId = useCurrentBuildingId();

  const { data: preferences } = useUserSettings();
  const ePredUserPreferences = preferences?.buildings?.[currentBuildingId];

  const ePredPreferences = useMemo(
    () => (ePredUserPreferences?.ePred ? ePredUserPreferences?.ePred : []),
    [ePredUserPreferences],
  );

  const handleSelectAsset = async (assetId: number) => {
    await mutateUserSettings({
      buildings: {
        [currentBuildingId]: {
          ePred: ePredPreferences.includes(assetId)
            ? ePredPreferences.filter((id) => id !== assetId)
            : [assetId],
        },
      },
    });
  };

  useEffect(() => {
    const get = async () => {
      if (ePredPreferences && ePredPreferences.length > 0) {
        const parentTimeseries = await getEnergyTimeseries(
          client,
          ePredPreferences,
        );
        setEPredEnergyIds(parentTimeseries);
        const childTimeseries = await getTimeseriesIds(
          client,
          parentTimeseries[0].externalId,
        );

        const logIfMoreThanOne = (label: string, timeseries: Timeseries[]) => {
          const filtered = timeseries.filter((ts) =>
            ts.externalId!.endsWith(label),
          );
          if (filtered.length > 1) {
            console.warn(
              `more than one timeseries ends with ${label}!`,
              filtered.map((ts) => ts.id),
            );
            // @ts-ignore
            if (Ybug) {
              // @ts-ignore
              Ybug.log(
                `more than one timeseries ends with ${label}!`,
                filtered.map((ts) => ts.id),
              );
            }
          }
        };
        ["min", "max"].forEach((label) =>
          logIfMoreThanOne(label, childTimeseries),
        );

        childTimeseries.forEach((ts: Timeseries) => {
          if (ts.externalId!.endsWith("max")) {
            setMaxTimeseries([ts]);
          } else if (ts.externalId!.endsWith("min")) {
            setMinTimeseries([ts]);
          }
        });
      }
    };
    get();
  }, [client, currentBuildingId, ePredPreferences]);

  const { data } = useSWR(
    ePredEnergyIds
      ? JSON.stringify({
          granularity: "h",
          start: start.toDate().toISOString(),
          end: end.toDate().toISOString(),
          items: ePredEnergyIds.map((ts) => ts.id),
        })
      : [],
    getEnergy(client, closedPublicHolidays, operationalHours, notification),
  );
  const { data: min } = useSWR(
    minTimeseries && minTimeseries.length > 0
      ? JSON.stringify({
          granularity: "h",
          start: start.toDate().toISOString(),
          end: end.toDate().toISOString(),
          items: minTimeseries.map((ts) => ts.id),
        })
      : [],
    getEnergyEpredMin(client, notification),
  );

  const { data: max } = useSWR(
    maxTimeseries && maxTimeseries.length > 0
      ? JSON.stringify({
          granularity: "h",
          start: start.toDate().toISOString(),
          end: end.toDate().toISOString(),
          items: maxTimeseries.map((ts) => ts.id),
        })
      : [],
    getEnergyEpredMax(client, notification),
  );

  const graphValues = Object.values(
    _.merge(_.keyBy(data, "time"), _.keyBy(max, "time"), _.keyBy(min, "time")),
  ).sort((a, b) => a.time - b.time);

  // bounds
  const padding = 24;
  const headerHeight = 46 + 24;
  const graphWidth = width > 0 ? width - padding * 2 : 0;
  const graphHeight = height - padding * 2 - headerHeight;

  const yTop = 104;
  const yAxisWidth = 30;
  const xAxisHeight = 30;

  const xMax = graphWidth > 0 ? graphWidth - yAxisWidth : 0;
  const yMax = graphHeight - yTop - xAxisHeight;

  const timeScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        round: true,
        domain: [start.valueOf(), end.valueOf()],
      }),
    [xMax, start, end],
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [
          0,
          Math.max(
            ...[
              ...(graphValues || []).map((gv) => gv.value || 0),
              ...(graphValues || []).map((gv) => gv.max || 0),
              ...(graphValues || []).map((gv) => gv.min || 0),
            ],
          ),
        ],
      }),
    [yMax, graphValues],
  );

  const bisectDate = bisector<any, Date>((d) => new Date(d.time)).left;

  const getDataForEvent = (event: any) => {
    const coords = localPoint(event.target.ownerSVGElement, event) || {
      x: 0,
      y: 0,
    };
    const x0 = timeScale.invert(coords.x - yAxisWidth);
    const index = bisectDate(graphValues, x0, 1);
    const d0 = graphValues[index - 1];
    const d1 = graphValues[index];
    let d: any = d0;
    if (d1 && d1.time) {
      d =
        x0.valueOf() - d0.time.valueOf() > d1.time.valueOf() - x0.valueOf()
          ? d1
          : d0;
    }
    return d;
  };

  const handleEPredTooltip = (event: any) => {
    const d: any = getDataForEvent(event);

    if (
      typeof d?.value === "number" ||
      typeof d?.max === "number" ||
      typeof d?.min === "number"
    ) {
      const tooltipTop =
        yScale(d.max) + yTop + (yScale(d.min) - yScale(d.max)) / 2;
      ePredShowTooltip({
        tooltipData: {
          date: dayjs(d.time).format("dddd H:mm"),
          max: formatMeasurement({ value: d.max, unit: "kWh" }),
          min: formatMeasurement({ value: d.min, unit: "kWh" }),
          maxTop: yScale(d.max) + yTop,
          minTop: yScale(d.min) + yTop,
        },
        tooltipLeft:
          yAxisWidth + timeScale(d.time) + Math.ceil(xMax / (24 * 7) / 2 / 2),
        tooltipTop,
      });
    } else {
      hideTooltip();
    }
  };

  const handleTooltip = (event: any) => {
    handleEPredTooltip(event);
    const d: any = getDataForEvent(event);

    if (typeof d?.value === "number") {
      showTooltip({
        tooltipData: {
          date: dayjs(d.time).format("dddd H:mm"),
          value: formatMeasurement({ value: d.value, unit: "kWh" }),
          operational: !d.empty,
        },
        tooltipLeft:
          yAxisWidth + timeScale(d?.time) + Math.ceil(xMax / (24 * 7) / 2 / 2),
        tooltipTop: yScale(d.value) + yTop,
      });
    } else {
      hideTooltip();
    }
  };
  return (
    <div ref={containerRef}>
      <DraggableCard
        style={{ height }}
        bordered={false}
        styles={{
          body: { padding, position: "relative" },
        }}
        title={
          <WidgetHeader
            text={t("dashboard.widgets.energy-consumption.ePred-this-week")}
            onClickRemoveButton={onClickRemoveButton}
            onClickSettingsButton={() => setShowSettings(true)}
            isDraggable
          />
        }
      >
        <h3>
          {ePredEnergyIds &&
            ePredEnergyIds.length > 0 &&
            `${ePredEnergyIds[0]?.name} ${ePredEnergyIds[0]?.description}`}
        </h3>
        <div
          style={{
            position: "absolute",
            top: 12,
            right: 24,
            fontSize: "13px",
            lineHeight: "13px",
            color: themeContext.neutral4,
            textAlign: "right",
          }}
        >
          <table cellPadding={4}>
            <tbody>
              <tr>
                <td>{t("dashboard.widgets.energy-consumption.energy-use")}</td>
                <td style={{ color: PRIMARY, fontSize: "24px" }}>—</td>
              </tr>
              <tr>
                <td>{t("dashboard.widgets.energy-consumption.ePred")}</td>
                <td
                  style={{
                    color: themeContext.neutral3,
                    fontSize: "24px",
                  }}
                >
                  —
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <svg
          width={graphWidth}
          height={graphHeight}
          style={{ overflow: "visible" }}
        >
          <AxisLeft
            top={yTop}
            left={yAxisWidth}
            scale={yScale}
            tickStroke={themeContext.neutral4}
            stroke={themeContext.neutral4}
            tickFormat={(val): string => formatCompact(Number(val))}
            numTicks={5}
            hideAxisLine
            hideTicks
            tickLabelProps={{
              fill: themeContext.neutral4,
            }}
          />
          <GridRows
            numTicks={5}
            scale={yScale}
            width={xMax}
            top={yTop}
            left={yAxisWidth}
            fill={themeContext.neutral7}
          />
          <AxisBottom
            top={yMax + yTop}
            left={yAxisWidth}
            scale={timeScale}
            numTicks={7}
            stroke={themeContext.neutral4}
            tickStroke={themeContext.neutral4}
            tickFormat={(date: any) => {
              const m = dayjs(date);
              return m.format("dddd");
            }}
            tickLabelProps={{
              fill: themeContext.neutral4,
            }}
          />
          <Group>
            <g>
              {(max && max.length > 0) || (min && min.length > 0) ? (
                <g>
                  <Area
                    id={`${Math.random()}`}
                    data={graphValues}
                    x={(d: any) => yAxisWidth + timeScale(d.time)}
                    y0={(d: any) => yScale(d.max || 0) + yTop}
                    y1={(d: any) => yScale(d.min || 0) + yTop}
                    curve={curveBasis}
                    fill={themeContext.neutral3}
                    fillOpacity={0.4}
                  />
                  <LinePath
                    data={graphValues}
                    x={(d: any) => yAxisWidth + timeScale(d.time)}
                    y={(d: any) => yScale(d.max || 0) + yTop}
                    stroke={themeContext.neutral3}
                    strokeWidth={2}
                    curve={curveBasis}
                  />
                  <LinePath
                    data={graphValues}
                    x={(d: any) => yAxisWidth + timeScale(d.time)}
                    y={(d: any) => yScale(d.min || 0) + yTop}
                    stroke={themeContext.neutral3}
                    strokeWidth={2}
                    curve={curveBasis}
                  />
                </g>
              ) : null}
            </g>
            <g>
              <LinePath
                data={graphValues}
                x={(d: any) => yAxisWidth + timeScale(d.time)}
                y={(d: any) => yScale(d.value) + yTop}
                stroke={themeContext.primary}
                strokeWidth={2}
                curve={curveBasis}
              />
            </g>
          </Group>
          <rect
            x={yAxisWidth}
            y={yTop}
            width={xMax}
            height={yMax}
            fill="transparent"
            onMouseMove={(event) => handleTooltip(event)}
            onMouseLeave={() => {
              hideTooltip();
              ePredHideTooltip();
            }}
          />
          {ePredTooltipData && typeof ePredTooltipTop === "number" && (
            <g>
              <g>
                <circle
                  cx={ePredTooltipLeft}
                  cy={(ePredTooltipData as any).maxTop + 1 || 0}
                  r={4}
                  fill={themeContext.neutral3}
                  fillOpacity={0.1}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                  pointerEvents="none"
                />
                <circle
                  cx={ePredTooltipLeft}
                  cy={(ePredTooltipData as any).maxTop || 0}
                  r={4}
                  fill={themeContext.neutral3}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </g>
              <g>
                <circle
                  cx={ePredTooltipLeft}
                  cy={(ePredTooltipData as any).minTop + 1 || 0}
                  r={4}
                  fill={themeContext.neutral3}
                  fillOpacity={0.1}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                  pointerEvents="none"
                />
                <circle
                  cx={ePredTooltipLeft}
                  cy={(ePredTooltipData as any).minTop || 0}
                  r={4}
                  fill={themeContext.neutral3}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </g>
            </g>
          )}
          {tooltipData && typeof tooltipTop === "number" && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: yTop }}
                to={{ x: tooltipLeft, y: yTop + yMax }}
                stroke={themeContext.neutral5}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop! + 1 || 0}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop || 0}
                r={4}
                fill={themeContext.primary}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
      </DraggableCard>
      {tooltipOpen && (
        <>
          <TooltipInPortal
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={tooltipTop!}
            left={tooltipLeft! + 24}
          >
            <span
              style={{
                color: themeContext.primary,
                fontSize: "24px",
                verticalAlign: "middle",
                marginRight: "5px",
              }}
            >
              —
            </span>
            {(tooltipData as any)?.value}
          </TooltipInPortal>
          <Tooltip
            top={yTop - 5}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              //minWidth: 72,
              textAlign: "center",
              transform: "translate(-50%,-100%)",
            }}
          >
            {(tooltipData as any)?.date}
          </Tooltip>
        </>
      )}
      {ePredTooltipOpen && (
        <>
          <TooltipInPortal
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={ePredTooltipTop!}
            left={ePredTooltipLeft!}
            style={{
              ...defaultStyles,
              background: "transparent",
            }}
          >
            <ToolTipEpredTable>
              <tbody>
                <tr>
                  <td>
                    <span
                      style={{
                        color: themeContext.neutral3,
                        fontSize: "24px",
                        verticalAlign: "middle",
                        marginRight: "5px",
                      }}
                    >
                      —
                    </span>
                  </td>
                  <td align="right">
                    {(ePredTooltipData as any)?.max || "--"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        color: themeContext.neutral3,
                        fontSize: "24px",
                        verticalAlign: "middle",
                        marginRight: "5px",
                      }}
                    >
                      —
                    </span>
                  </td>
                  <td align="right">
                    {" "}
                    {(ePredTooltipData as any)?.min || "--"}
                  </td>
                </tr>
              </tbody>
            </ToolTipEpredTable>
          </TooltipInPortal>
          <Tooltip
            top={yTop - 5}
            left={ePredTooltipLeft}
            style={{
              ...defaultStyles,
              margin: 0,
              textAlign: "center",
              transform: "translate(-50%,-100%)",
            }}
          >
            {(ePredTooltipData as any)?.date}
          </Tooltip>
        </>
      )}
      {showSettings && (
        <Modal
          width={630}
          style={{ maxWidth: "90%" }}
          open
          title={t("dashboard.widgets.energy-consumption.settings-for-epred")}
          onOk={() => setShowSettings(false)}
          onCancel={() => setShowSettings(false)}
        >
          <SettingModal
            client={client}
            buildingId={currentBuildingId}
            selectedAssets={ePredPreferences}
            onSelectAsset={handleSelectAsset}
          />
        </Modal>
      )}
    </div>
  );
}
