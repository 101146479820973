import { useTranslations } from "@properate/translations";
import { Select } from "antd";
import { Role, User } from "@/eepApi";

type Params = {
  onChange: (role: Role) => void;
  role: User["role"];
};
export const UserRoleSwitcher = ({ role, onChange }: Params) => {
  const t = useTranslations();
  return (
    <Select
      style={{ minWidth: 150 }}
      value={role}
      onChange={onChange}
      size="small"
    >
      <Select.Option value="admin">{t("admin.roles.admin")}</Select.Option>
      <Select.Option value="user">{t("admin.roles.user")}</Select.Option>
      <Select.Option value="viewer">{t("admin.roles.viewer")}</Select.Option>
      <Select.Option value="no_access">
        {t("admin.roles.service-desk")}
      </Select.Option>
      <Select.Option value="super">{t("admin.roles.super")}</Select.Option>
    </Select>
  );
};
