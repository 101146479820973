import { useEffect, useState } from "react";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Modal } from "antd";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { Analysis } from "@/features/analysis";
import { createAnalysis } from "@/services/analysis";

export function AnalysisImport() {
  const t = useTranslations();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const analysis = useLoaderData() as Analysis;
  const { snapshotId } = useParams() as { snapshotId: string };
  const currentBuildingId = useCurrentBuildingId();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useUser();

  function getAnalysisOverviewPath() {
    const analysisType = pathname.match(
      /analysis\/(simple|scatterplot)\/\w+/,
    )?.[1];
    if (!analysisType) {
      throw new Error("Path does not contain 'simple' or 'scatterplot'");
    }
    return `/asset/${currentBuildingId}/analysis/${analysisType}`;
  }

  useEffect(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  async function handleOK() {
    try {
      setIsLoading(true);
      await createAnalysis({
        ...analysis,
        buildingId: currentBuildingId,
        owner: user.email,
        created: Date.now(),
        importedFrom: snapshotId,
      });
      navigate(getAnalysisOverviewPath());
    } finally {
      setIsLoading(false);
    }
  }

  function handleCancel() {
    navigate(getAnalysisOverviewPath());
  }

  return (
    <Modal
      open={isModalOpen}
      title={t("analysis.import-analysis")}
      onOk={handleOK}
      onCancel={handleCancel}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <p>
        {t("analysis.do-you-want-to-import-analysis", {
          name: analysis.title || t("analysis.no-name"),
        })}
      </p>
      <p>{t("analysis.after-import-description")}</p>
    </Modal>
  );
}
