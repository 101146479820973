import dayjs from "@properate/dayjs";
import { Avatar, Space } from "antd";
import { Timeseries } from "@cognite/sdk";
import {
  formatUnit,
  getSystemCodeFromExternalId,
  TimeSpan,
  WithRequired,
} from "@properate/common";
import { useTranslations } from "@properate/translations";
import { formatNonScientific } from "@/utils/format";
import { getItemByProp } from "@/utils/array";
import { useTranslateSystem } from "@/utils/tfm";
import { SimplePoint, SimplePointsWithMetadata } from "../../types";
import { TOOLTIP_DATE_FORMAT } from "../../utils";
import { filterClosestSimplePointsWithMetadataList } from "./utils";
import { Tooltip } from "./elements";

interface Props {
  timeseriesList: Timeseries[];
  simplePointsWithMetadataList: SimplePointsWithMetadata[];
  date: Date;
  zoomedTimeSpan: TimeSpan;
}

function hasMinAndMax(
  simplePoint: SimplePoint,
): simplePoint is WithRequired<SimplePoint, "yMin" | "yMax"> {
  return (
    typeof simplePoint.yMin === "number" && typeof simplePoint.yMax === "number"
  );
}

export function SimpleGraphTooltip({
  timeseriesList,
  simplePointsWithMetadataList,
  date,
  zoomedTimeSpan,
}: Props) {
  const tSystem = useTranslateSystem();
  const t = useTranslations();

  function formatValue(value: number, unit: string) {
    return `${formatNonScientific(value)}${formatUnit(unit)}`;
  }

  return (
    <Tooltip>
      <strong>{dayjs(date).format(TOOLTIP_DATE_FORMAT)}</strong>
      {filterClosestSimplePointsWithMetadataList({
        simplePointsWithMetadataList,
        zoomedTimeSpan,
        date,
      }).map(
        (
          {
            simplePointClosestToDate,
            item: {
              metadata: { timeseriesId, color, unit },
            },
          },
          index,
        ) => {
          const timeseries = getItemByProp(timeseriesList, timeseriesId);
          const system =
            timeseries.externalId &&
            getSystemCodeFromExternalId(timeseries.externalId);
          return (
            <Space key={`${timeseriesId}-${index}`}>
              <Avatar
                size="small"
                style={{
                  background: color,
                  flexShrink: 0,
                }}
              />
              <div style={{ whiteSpace: "nowrap" }}>
                {formatValue(simplePointClosestToDate.y, unit)}
              </div>
              {timeseries.name && (
                <div style={{ marginLeft: "auto", whiteSpace: "nowrap" }}>
                  {system && (
                    <b>
                      {system} {tSystem(system)}
                    </b>
                  )}
                  {timeseries.name}{" "}
                  {hasMinAndMax(simplePointClosestToDate) && (
                    <span>
                      {t("analysis.min-and-max-values", {
                        minValue: formatValue(
                          simplePointClosestToDate.yMin,
                          unit,
                        ),
                        maxValue: formatValue(
                          simplePointClosestToDate.yMax,
                          unit,
                        ),
                      })}
                    </span>
                  )}
                </div>
              )}
            </Space>
          );
        },
      )}
    </Tooltip>
  );
}
