import dayjs, { type Dayjs } from "@properate/dayjs";
import { CSSProperties } from "react";
import { TimeSpan, TimeSpanDayjs } from "@properate/common";
import { DatePicker } from "antd";
import { TimeSpanOption } from "@/features/analysis";

interface Props {
  onChange?: (value: TimeSpan) => unknown;
  value?: TimeSpan | null;
  presets?: TimeSpanOption[];
  withTime?: boolean;
  style?: CSSProperties;
  picker?: "time" | "date" | "week" | "month";
  notBefore?: number;
  notAfter?: number;
  format?: string;
  disabled?: boolean;
  allowClear?: boolean;
  disabledDate?: (value: Dayjs) => boolean;
}

const compareTwoTimeSpans = (a: TimeSpanDayjs, b: TimeSpanDayjs) => {
  if (!a || !b) return false;

  const aStartRounded = dayjs(a[0]).startOf("minute");
  const aEndRounded = dayjs(a[1]).startOf("minute");
  const bStartRounded = dayjs(b[0]).startOf("minute");
  const bEndRounded = dayjs(b[1]).startOf("minute");

  return aStartRounded.isSame(bStartRounded) && aEndRounded.isSame(bEndRounded);
};

export function RangePicker({
  value,
  onChange,
  presets,
  style,
  picker = "date",
  notBefore = 0,
  withTime = false,
  notAfter = Infinity,
  format = withTime ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY",
  disabled = false,
  allowClear = true,
  disabledDate = (date) => date.isBefore(notBefore) || date.isAfter(notAfter),
}: Props) {
  const presetsFinal = presets;

  function getTimeSpanStart(timeSpanStart: Dayjs, isPreset: boolean) {
    if (isPreset || picker !== "date" || withTime) {
      return timeSpanStart.valueOf();
    }
    return timeSpanStart.startOf("day").valueOf();
  }

  function getTimeSpanEnd(timeSpanEnd: Dayjs, isPreset: boolean) {
    if (isPreset) {
      // Adjust time span end to update "now" as the end of the time span
      if (Math.abs(timeSpanEnd.diff(Date.now(), "hour", true)) <= 1) {
        return Date.now();
      }
      return timeSpanEnd.valueOf();
    }
    return picker === "date" && !withTime
      ? timeSpanEnd.endOf("day").valueOf()
      : timeSpanEnd.valueOf();
  }

  function handleChange(selectedValues: TimeSpanDayjs) {
    if (onChange !== undefined && selectedValues !== null) {
      const [selectedValueStart, selectedValueEnd] = selectedValues;
      if (selectedValueStart && selectedValueEnd) {
        if (selectedValueStart > selectedValueEnd) {
          return;
        }
        const isPreset = presetsFinal
          ? Object.values(presetsFinal).some(({ value }) => {
              if (typeof value === "function") {
                const presetValue = value();
                if (!presetValue) return false;
                return compareTwoTimeSpans(
                  presetValue as TimeSpanDayjs,
                  selectedValues,
                );
              }
              return compareTwoTimeSpans(
                value as TimeSpanDayjs,
                selectedValues,
              );
            })
          : false;
        onChange([
          getTimeSpanStart(selectedValueStart, isPreset),
          getTimeSpanEnd(selectedValueEnd, isPreset),
        ]);
      }
    }
  }

  return (
    <DatePicker.RangePicker
      picker={picker}
      format={format}
      value={Array.isArray(value) ? [dayjs(value[0]), dayjs(value[1])] : null}
      onCalendarChange={handleChange}
      style={style}
      presets={presetsFinal}
      disabledDate={disabledDate}
      disabled={disabled}
      allowClear={allowClear}
      {...(withTime ? { showTime: { format: "HH:mm" } } : null)}
    />
  );
}
