import { DERIVED_UNITS, SchemaType, WithSnapshotId } from "@properate/common";
import axios from "axios";
import dayjs from "@properate/dayjs";
import { Datapoints, Timeseries } from "@cognite/sdk";
import { HeatMapViewMode } from "@/features/heatmap";
import { TimeseriesSettings } from "@/services/timeseriesSettings";
import { createAnalysis } from "@/services/analysis";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { AnalysisCreateRedirectButtonProps } from "@/utils/types";
import { getSubBuildingAndSystemCode } from "@/components/Dashboard/TechnicalFacilityKeyFigures/IndoorClimateSettings";

export const getStateDescription = (
  stateDescription?: string,
): undefined | Record<number, string> => {
  if (!stateDescription) {
    return undefined;
  }
  try {
    const stateDescriptionParsed = JSON.parse(
      stateDescription.replace(/'/g, '"'),
    );

    const result: { [key: number]: any } = {};

    for (const key in stateDescriptionParsed) {
      const numericKey = Number(key);
      if (!isNaN(numericKey)) {
        result[numericKey] =
          typeof stateDescriptionParsed[key] === "string" &&
          stateDescriptionParsed[key]?.length > 0
            ? stateDescriptionParsed[key]
            : key;
      }
    }

    return result;
  } catch (error) {
    return undefined;
  }
};

export const convertUnit = (
  fromUnit: string | undefined,
  toUnit: string | undefined,
  value: number | undefined,
): { value: number | undefined; unit: string | undefined } => {
  if (value === undefined || toUnit === undefined || fromUnit === toUnit) {
    return { value, unit: fromUnit };
  }

  if (
    fromUnit === undefined ||
    !DERIVED_UNITS[fromUnit] ||
    !DERIVED_UNITS[fromUnit][toUnit]
  ) {
    console.error(`Invalid unit conversion from ${fromUnit} to ${toUnit}`);
    return { value, unit: fromUnit };
  }

  return {
    value: DERIVED_UNITS[fromUnit][toUnit].to(value),
    unit: toUnit,
  };
};
export function parseError(error: unknown) {
  if (axios.isAxiosError(error)) {
    // Handle AxiosError
    // https://github.com/axios/axios/issues/960
    if (error.response?.data) {
      return JSON.parse(JSON.stringify(error.response?.data)).message;
    }
    return error.message;
  }
  return `${error}`;
}

export const getSchemaTitle = (schema: SchemaType | undefined) => {
  if (!schema) {
    return null;
  }
  const { name, system } = schema;
  if (name) {
    if (system) {
      return system + " " + name;
    }
    return name;
  }
  return null;
};

export function getCurrentStartOfWeekMonthDateOrReferenceDate(
  viewMode: HeatMapViewMode,
  referenceDate: number,
  isCurrentStartOfWeekMonthChecked?: boolean,
) {
  if (isCurrentStartOfWeekMonthChecked) {
    const startOfTheCurrentWeek = dayjs().startOf("week").valueOf();
    const startOfTheCurrentMonth = dayjs().startOf("month").valueOf();
    return viewMode === "week" ? startOfTheCurrentWeek : startOfTheCurrentMonth;
  }
  return referenceDate;
}

type SchemaValue = {
  value: number | undefined;
  unit: string;
  textValue: string | false | undefined;
  timestamp: Date;
};

export const mapSchemaValue = (
  value: Datapoints,
  overrideUnits: WithSnapshotId<TimeseriesSettings>,
  timeseries?: Timeseries,
): SchemaValue => {
  const stateDescription = getStateDescription(
    timeseries?.metadata?.state_description,
  );
  const dataPoint =
    value?.datapoints.length === 0 ? undefined : value.datapoints[0];

  const overrideUnit =
    value && overrideUnits && overrideUnits[value.externalId!]?.unit;

  const convertUnit = overrideUnit && overrideUnit !== value.unit;

  const convert =
    convertUnit && DERIVED_UNITS[value.unit!]?.[overrideUnit]
      ? DERIVED_UNITS[value.unit!][overrideUnit].to
      : (x: any) => x;

  return {
    ...dataPoint,
    value:
      dataPoint && typeof dataPoint.value == "number"
        ? convert(dataPoint.value)
        : undefined,
    unit: overrideUnit || value.unit || "",
    textValue:
      stateDescription &&
      dataPoint &&
      typeof dataPoint.value === "number" &&
      stateDescription[dataPoint.value],
    timestamp: dataPoint?.timestamp || new Date(),
  };
};
export function getTitle(timeseries: Timeseries) {
  const subBuildingAndSystemCode =
    timeseries && getSubBuildingAndSystemCode(timeseries.externalId!);

  if (typeof timeseries.description === "string") {
    if (typeof timeseries.name === "string") {
      return subBuildingAndSystemCode
        ? `${subBuildingAndSystemCode} ${timeseries.name} ${timeseries.description}`
        : `${timeseries.name} ${timeseries.description}`;
    }
    return subBuildingAndSystemCode
      ? `${subBuildingAndSystemCode} ${timeseries.description}`
      : timeseries.description;
  }
  if (typeof timeseries.name === "string") {
    return subBuildingAndSystemCode
      ? `${subBuildingAndSystemCode} ${timeseries.name}`
      : timeseries.name;
  }
  return subBuildingAndSystemCode ? subBuildingAndSystemCode : "Ny analyse";
}

export async function createSimpleAnalysis({
  buildingId,
  timeseries,
  auxiliaryTimeseries,
  yAxisSettings,
  userEmail,
  colors,
}: AnalysisCreateRedirectButtonProps) {
  const settingsValueLimit =
    yAxisSettings?.min || yAxisSettings?.max
      ? {
          valueLimit: {
            min: yAxisSettings?.min,
            max: yAxisSettings?.max,
          },
        }
      : {};

  const analysisName = getTitle(timeseries);

  const { id } = await createAnalysis({
    title: analysisName,
    buildingId: buildingId,
    owner: userEmail,
    settingsTimeseriesList: [timeseries, ...(auxiliaryTimeseries || [])].map(
      (ts, i) => {
        return {
          id: ts.id,
          hidden: false,
          color: (colors || Object.values(DEFAULT_GLOBAL_THEME.color))[i],
          unitSelected: timeseries?.unit || "",
          ...settingsValueLimit,
        };
      },
    ),
    created: Date.now(),
    mergeUnits: true,
  });

  return `../analysis/simple/${id}`;
}
