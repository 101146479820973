import {
  Aggregate,
  Asset,
  CogniteClient,
  DatapointAggregate,
  DatapointAggregates,
  Datapoints,
  DatapointsMultiQuery,
  DoubleDatapoint,
  IdEither,
  SyntheticDatapoint,
  SyntheticDataValue,
  SyntheticQuery,
  SyntheticQueryResponse,
  Timeseries,
} from "@cognite/sdk";
import { RcFile } from "antd/es/upload/interface";
import _ from "lodash";
import Color from "color";
import {
  formatSubBuildingFromExternalId,
  getSystemCodeFromExternalId,
  WithSnapshotId,
} from "@properate/common";
import dayjs, { Dayjs, QUnitType } from "@properate/dayjs";
import { z } from "zod";
import { convertUnit } from "@/pages/common/utils";
import { TimeseriesSettings } from "@/services/timeseriesSettings";
import { ImageInfo, NotificationInstance, TransformedDataPoint } from "./types";

export const FUNCTION_URL_BASE = process.env.REACT_APP_PRODUCTION
  ? "https://europe-west1-properate-v1.cloudfunctions.net/"
  : "https://europe-west1-eep-frontend-v1-dev.cloudfunctions.net/";

// default number of seconds to show error messages
export const DEFAULT_MESSAGE_DURATION = 7;
export const DEFAULT_NOTES_LEVELS = ["error", "warning", "info"];
export const disabledFutureDates = (current: Dayjs) => {
  // Can not select future dates
  if (current !== undefined) {
    return current.isAfter(dayjs().endOf("day"));
  }
  return false;
};

const getAverageRGB = (imgEl: any) => {
  const blockSize = 5, // only visit every 5 pixels
    canvas = document.createElement("canvas"),
    context = canvas.getContext && canvas.getContext("2d"),
    rgb = { r: 0, g: 0, b: 0 };

  if (!context) {
    return "#000";
  }

  const height = (canvas.height =
    imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height);
  const width = (canvas.width =
    imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width);

  context.drawImage(imgEl, 0, 0);

  try {
    const data = context.getImageData(0, 0, width, height);

    let i = -4;
    let count = 0;
    while ((i += blockSize * 4) < data.data.length) {
      ++count;
      rgb.r += data.data[i];
      rgb.g += data.data[i + 1];
      rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    return Color.rgb(rgb.r, rgb.g, rgb.b).hex();
  } catch (error: any) {
    return "#000";
  }
};

export const getImageInfo = (image: any): Promise<ImageInfo> => {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const img = new Image();

        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
            color: getAverageRGB(img),
          });
        };

        img.src = fileReader.result as any;
      };

      fileReader.readAsDataURL(image.file);
    } catch (error: any) {
      reject(error);
    }
  });
};

export const getLatestDataPoints = (
  client: CogniteClient,
  overrideUnits: WithSnapshotId<TimeseriesSettings> | undefined,
) => {
  return async (items: string): Promise<any> => {
    const chunks: number[][] = _.chunk(
      JSON.parse(items).map((item: string) => parseInt(item)),
      100,
    );
    return Promise.all(
      chunks.map((timeseries: number[]) => {
        return client.datapoints
          .retrieveLatest(
            timeseries.map((ts) => ({
              id: ts,
              before: "now",
            })),
          )
          .then((points) => {
            return points.map((point) => {
              const overrideUnit =
                overrideUnits &&
                point?.externalId &&
                overrideUnits[point.externalId]?.unit;
              if (typeof overrideUnit === "string") {
                const convertedValueToOverrideUnit = point.datapoints[0].value
                  ? convertUnit(
                      point.unit,
                      overrideUnit,
                      point.datapoints[0].value as number,
                    )
                  : { value: point.datapoints[0].value, unit: point.unit };
                return {
                  ...point.datapoints[0],
                  value: convertedValueToOverrideUnit.value,
                  id: point.id,
                  unit: convertedValueToOverrideUnit.unit,
                };
              }
              return {
                ...point.datapoints[0],
                id: point.id,
                unit: point.unit,
              };
            });
          });
      }),
    ).then((results: any) => {
      return results.flat().reduce((prev: any, current: any) => {
        const { id, ...props } = current;
        prev[id] = props;
        return prev;
      }, {});
    });
  };
};

export const getLatestDataPointsByExternalId = (client: CogniteClient) => {
  return async (items: string): Promise<any> => {
    const chunks: string[][] = _.chunk(
      JSON.parse(items).map((item: string) => item),
      100,
    );
    return Promise.all(
      chunks.map((timeseries: string[]) => {
        return client.datapoints
          .retrieveLatest(
            timeseries.map((externalId) => ({
              externalId,
              before: "now",
            })),
          )
          .then((points) => {
            return points.map((point) => ({
              ...point.datapoints[0],
              externalId: point.externalId,
              unit: point.unit,
            }));
          });
      }),
    ).then((results: any) => {
      return results.flat().reduce((prev: any, current: any) => {
        const { externalId, ...props } = current;
        prev[externalId] = props;
        return prev;
      }, {});
    });
  };
};

export const granularitySchema = z.enum(["M", "w", "d", "h"] as const);

export type Granularity = z.infer<typeof granularitySchema>;

export type PeriodInfo = {
  name?: string;
  description?: string;
  start: string | Date;
  end?: string | Date;
  granularity: Granularity;
  items?: string;
};

export type DataPeriodType = Record<string, PeriodInfo>;

export const DATA_PERIOD: DataPeriodType = {
  LAST_YEAR_WEEKLY: {
    name: "last-year-weeks",
    description: "last-year-weeks-description",
    start: "365d-ago",
    granularity: "w",
  },
  LAST_YEAR: {
    name: "last-year-days",
    description: "last-year-days-description",
    start: "365d-ago",
    granularity: "d",
  },
  LAST_6_MONTHS: {
    name: "last-183-days",
    description: "last-183-days-description",
    start: "183d-ago",
    granularity: "d",
  },
  LAST_MONTH: {
    name: "last-30-days",
    description: "last-30-days-description",
    start: "30d-ago",
    granularity: "h",
  },
  LAST_2_WEEKS: {
    name: "last-14-days",
    description: "last-14-days-description",
    start: "14d-ago",
    granularity: "h",
  },
  LAST_WEEK: {
    name: "last-7-days",
    description: "last-7-days-description",
    start: "7d-ago",
    granularity: "h",
  },
};

export const parseStart = (start: string, granularity: string) => {
  if (start.endsWith("-ago")) {
    const [, amount, unit] = start.split(/(\d+)(\w+)/);
    return dayjs()
      .startOf(granularity as QUnitType)
      .subtract(parseInt(amount), unit as QUnitType)
      .toDate();
  } else if (start === "now") {
    return new Date();
  }
  return new Date(start);
};

export const parseEnd = (start: string, granularity: string) => {
  if (start.endsWith("-ago")) {
    const [, amount, unit] = start.split(/(\d+)(\w+)/);
    return dayjs()
      .endOf(granularity as QUnitType)
      .subtract(parseInt(amount), unit as QUnitType)
      .toDate();
  } else if (start === "now") {
    return new Date();
  }
  return new Date(start);
};

export type GraphPoint = {
  time: number;
  value: number;
};

const LIMIT = 10000;
const getAll = async (
  fn: Function,
  start: Date,
  incrementUnit: string,
): Promise<DatapointAggregates[]> => {
  const data = await fn(start);
  if (data[0].datapoints.length === LIMIT) {
    const next = await getAll(
      fn,
      dayjs(data[0].datapoints[data[0].datapoints.length - 1].timestamp)
        .add(1, incrementUnit as QUnitType)
        .toDate(),
      incrementUnit,
    );
    return [
      {
        ...data[0],
        datapoints: [...data[0].datapoints, ...next[0].datapoints],
      },
    ];
  }
  return data;
};

const retrieve = async (
  client: CogniteClient,
  id: string,
  aggregate: string,
  granularity: string,
  start: Date,
  end?: Date,
) => {
  return getAll(
    async (currentStart: Date) =>
      await datapointsRetrieve(client)({
        limit: LIMIT,
        items: [
          {
            id: parseInt(id),
          },
        ],
        aggregates: [aggregate as Aggregate],
        start: currentStart,
        end: end || "now",
        granularity: granularity,
        includeOutsidePoints: false,
      }),
    start,
    granularity,
  );
};

export async function syntheticQuery(
  client: CogniteClient,
  items: SyntheticQuery[],
): Promise<SyntheticQueryResponse[]> {
  const [, timeSeries] = items[0].expression.split(/\((.+)\)/);
  const result: DatapointAggregates[][] = await Promise.all(
    timeSeries.split("+").map((ts: string) => {
      const [, id, aggregate, granularity] = ts.split(
        /TS{id=(\d+), aggregate='(\w+)', granularity='([a-zA-Z0-9]+)'}/,
      );
      const startDate =
        typeof items[0].start === "string"
          ? parseStart(items[0].start as string, granularity)
          : items[0].start;
      return retrieve(
        client,
        id,
        aggregate,
        granularity,
        startDate as Date,
        items[0].end && items[0].end !== "now"
          ? new Date(items[0].end)
          : undefined,
      );
    }),
  );

  const datapoints = _.chain(result.map((data) => data[0].datapoints))
    .flatten()
    .groupBy("timestamp")
    .map((point, timestamp) => ({
      timestamp: new Date(timestamp),
      average:
        point.length > 0 &&
        point[0].average !== undefined &&
        _.meanBy(point, "average"),
      sum:
        point.length > 0 && point[0].sum !== undefined && _.sumBy(point, "sum"),
    }))
    .map(
      (point) =>
        ({
          timestamp: point.timestamp,
          value:
            typeof point.sum === "number"
              ? point.sum
              : typeof point.average === "number"
              ? point.average
              : undefined,
        }) as SyntheticDatapoint,
    )
    .orderBy(["timestamp"], ["asc"])
    .value();

  return [{ datapoints: datapoints }];
}

export const calculateSumOfEnergyDataPoints =
  (client: CogniteClient, notificationInstance: NotificationInstance) =>
  async (query: string) => {
    const period = JSON.parse(query);
    if (period.items.length === 0) {
      return Promise.resolve(null);
    }

    const startDate = parseStart(period.start as string, period.granularity);
    try {
      //const result = await client.timeseries.syntheticQuery([
      const result = await syntheticQuery(client, [
        {
          expression: `(${period.items
            .map(
              (id: number) =>
                `TS{id=${id}, aggregate='sum', granularity='${period.granularity}'}`,
            )
            .join(" + ")})`,
          start: startDate,
          end: period.end ? new Date(period.end) : "now",
        },
      ]);
      return result[0].datapoints.map((point) => ({
        time: point.timestamp.valueOf(),
        value: (point as SyntheticDataValue).value,
      }));
    } catch (error: any) {
      notificationInstance.error({
        message: (error as any).errorMessage || (error as any).message,
      });
      throw error;
    }
  };

export const retrieveDataPoints =
  (client: CogniteClient) => async (query: string) => {
    const period = JSON.parse(query);
    const startDate =
      typeof period.start === "string"
        ? parseStart(period.start as string, period.granularity)
        : period.start;

    const endDate =
      typeof period.end === "string"
        ? parseEnd(period.end as string, period.granularity)
        : period.end;

    const result = await retrieve(
      client,
      period.items[0],
      period.aggregate || "average",
      period.granularity,
      startDate,
      endDate,
    );

    return (result[0]?.datapoints as DatapointAggregate[]).map(
      (point: DatapointAggregate) => ({
        time: point.timestamp.valueOf(),
        value: (point.sum !== undefined ? point.sum : point.average) as number,
      }),
    );
  };

const retrieveWasteDataPoints =
  (client: CogniteClient) => async (query: string) => {
    const period = JSON.parse(query);
    const startDate =
      typeof period.start === "string"
        ? parseStart(period.start as string, period.granularity)
        : period.start;

    const result = await retrieve(
      client,
      period.items[0],
      "sum",
      period.granularity,
      startDate,
      period.end ? dayjs(period.end).toDate() : undefined,
    );

    return (result[0]?.datapoints as DatapointAggregate[]).map(
      (point: DatapointAggregate) => ({
        time: point.timestamp.valueOf(),
        value: point.sum as number,
      }),
    );
  };

export const getWasteData = async (
  client: CogniteClient,
  period: PeriodInfo,
  waste: Timeseries[],
) => {
  const wasteData = await Promise.all(
    waste.map((ts) =>
      retrieveWasteDataPoints(client)(
        JSON.stringify({
          ...period,
          items: [ts.id],
        }),
      ),
    ),
  );

  return wasteData
    .map((data, index) => ({
      name: waste[index].externalId!.includes("-NU")
        ? waste[index].description!
        : waste[index].externalId!,
      datapoints: data,
    }))
    .filter((data) => !data.datapoints.every((d) => d.value === 0))
    .sort((a, b) => b.datapoints[0].value - a.datapoints[0].value);
};

export const retrieveData =
  (client: CogniteClient, notificationInstance: NotificationInstance) =>
  async (query: string) => {
    const period = JSON.parse(query);
    try {
      const result = await datapointsRetrieve(client)({
        limit: 1000,
        items: (period.items as number[]).map<IdEither>((e: number) => ({
          id: e,
        })),
        aggregates: ["average"],
        start: period.end ? dayjs(period.start).toDate() : period.start,
        end: period.end ? dayjs(period.end).toDate() : "now",
        granularity: period.granularity,
      });

      return (result[0] as DatapointAggregates).datapoints.map(
        (d: DatapointAggregate) => ({
          time: d.timestamp.valueOf(),
          value: d.average as number,
        }),
      );
    } catch (error: any) {
      notificationInstance.error({
        message: (error as any).errorMessage || (error as any).message,
      });
      throw error;
    }
  };

// if aggregate is day or week then check if a timezone change occurs in the period, if a timezone change occurs split the query into 3 queries,
// one for the period before the change, one for the day of the change and one for the period after the change, all other queries can be passed
// along to the cognite function unchanged

export const getDate = (date?: Date | string | number | undefined): Dayjs =>
  date instanceof Date || typeof date === "number"
    ? dayjs(date)
    : parseDateString(date || "now");

const parseDateString = (date: string) => {
  if (date.endsWith("-ago")) {
    const [, amount, unit] = date.split(/(\d+)(\w+)/);
    return dayjs().subtract(parseInt(amount), unit as QUnitType);
  } else if (date === "now") {
    return dayjs();
  }
  return dayjs(date);
};

const getGranularity = (granularity: "d" | "w") =>
  granularity === "d" ? 24 : 168;

const getDSTChangeGranularity = (
  granularity: "d" | "w",
  changeToWintertime: boolean,
) => getGranularity(granularity) + (changeToWintertime ? 1 : -1);

export const numberFormatForAxis = new Intl.NumberFormat("nb-NO", {
  notation: "compact",
  maximumFractionDigits: 2,
});

/**
 * The cognite library operates is UTC time, we need our queries to be correct for norwegian time. The fix is to
 * align the queries for days and weeks to the norwegian start time of the period and make queries for 24h or 168h instead.
 * We also perform specialized queries if it is a change of summer/winter time in the period.
 */
export const datapointsRetrieve =
  (client: CogniteClient) =>
  async (
    query: DatapointsMultiQuery,
  ): Promise<(DatapointAggregates | Datapoints)[]> => {
    return datapointsRetrieveTimezoneFix(client)(query);
  };

function hasDST(day: dayjs.Dayjs) {
  const julyOffset = new Date(day.year(), 6, 1).getTimezoneOffset();

  return julyOffset === day.toDate().getTimezoneOffset();
}
export const isSameSummerOrWinterTime = ({
  first,
  second,
  granularity = "h",
}: {
  first: dayjs.Dayjs;
  second: dayjs.Dayjs;
  granularity?: Granularity;
}) => {
  if (granularity === "h") {
    const bothSummertimeAndWithoutAnyOtherSummertimePeriodBetweenThem =
      hasDST(first.add(1, "second")) &&
      hasDST(second.subtract(1, "second")) &&
      second.isSameOrBefore(getWinterTimeStartFromDate(first));

    const bothWintertimeAndWithoutAnyOtherWintertimePeriodBetweenThem =
      !hasDST(first.add(1, "second")) &&
      !hasDST(second.subtract(1, "second")) &&
      second.isSameOrBefore(getSummerTimeStartFromDate(first));

    return (
      bothSummertimeAndWithoutAnyOtherSummertimePeriodBetweenThem ||
      bothWintertimeAndWithoutAnyOtherWintertimePeriodBetweenThem
    );
  }
  if (granularity === "d" || granularity === "w") {
    const bothSummertimeAndWithoutAnyOtherSummertimePeriodBetweenThem =
      hasDST(first.startOf(granularity)) &&
      hasDST(second.startOf(granularity)) &&
      second
        .startOf(granularity)
        .isSameOrBefore(getWinterTimeStartFromDate(first.startOf(granularity)));

    const bothWintertimeAndWithoutAnyOtherWintertimePeriodBetweenThem =
      !hasDST(first.startOf(granularity)) &&
      !hasDST(second.startOf(granularity)) &&
      second
        .startOf(granularity)
        .isSameOrBefore(getSummerTimeStartFromDate(first.startOf(granularity)));

    return (
      bothSummertimeAndWithoutAnyOtherSummertimePeriodBetweenThem ||
      bothWintertimeAndWithoutAnyOtherWintertimePeriodBetweenThem
    );
  }
};

export const datapointsRetrieveTimezoneFix =
  (client: CogniteClient) =>
  async (
    query: DatapointsMultiQuery,
  ): Promise<(DatapointAggregates | Datapoints)[]> => {
    // If aggregate is month then we have to separate queries for each month in the period
    if (
      query.aggregates &&
      query.items.length === 1 &&
      query.granularity === "M"
    ) {
      let current = getDate(query.start).startOf(query.granularity);
      const end = getDate(query.end);

      let monthlyAggregates: DatapointAggregates | null = null;

      while (current.isBefore(end)) {
        const next = current.add(1, query.granularity);
        const monthData = await client.datapoints.retrieve({
          ...query,
          granularity: `${dayjs(next).diff(current, "hours")}h`,
          start: current.toDate(),
          end: next.toDate(),
          aggregates: query.aggregates,
        });
        if (!monthlyAggregates) {
          monthlyAggregates = monthData[0] as DatapointAggregates;
        } else {
          monthlyAggregates.datapoints.push(
            ...(monthData[0] as DatapointAggregates).datapoints,
          );
        }
        current = next;
      }

      return monthlyAggregates ? [monthlyAggregates] : [];
    }

    if (
      query.aggregates &&
      query.items.length === 1 &&
      (query.granularity === "d" || query.granularity === "w")
    ) {
      // parse the dates and align them to the current granularity in the current timezone
      const start = getDate(query.start).startOf(query.granularity);
      const end = getDate(query.end);

      if (
        isSameSummerOrWinterTime({
          first: dayjs(start),
          second: dayjs(end),
        })
      ) {
        // if both the start and the end time is in the same timezone then just execute the query
        return client.datapoints.retrieve({
          ...query,
          granularity: getGranularity(query.granularity) + "h",
          start: start.toDate(),
          end: end.toDate(),
        });
      }

      const startsOnDaylightSavingTime =
        start.utcOffset() > start.month(0).utcOffset() || // northern hemisphere
        start.utcOffset() > start.month(5).utcOffset(); // southern hemisphere

      const DSTChange = startsOnDaylightSavingTime
        ? getWinterTimeStartFromDate(start)
        : getSummerTimeStartFromDate(start);
      const startOfDSTChange = DSTChange.startOf(query.granularity);

      const afterDSTChange = startOfDSTChange.add(1, query.granularity);

      const [before, DSTChangePeriod, rest] = await Promise.all([
        start.toDate().valueOf() === startOfDSTChange.toDate().valueOf()
          ? [{ datapoints: [] }]
          : client.datapoints.retrieve({
              ...query,
              granularity: getGranularity(query.granularity) + "h",
              start: start.toDate(),
              end: startOfDSTChange.toDate(),
            }),
        client.datapoints.retrieve({
          ...query,
          granularity:
            getDSTChangeGranularity(
              query.granularity,
              startsOnDaylightSavingTime,
            ) + "h",
          start: startOfDSTChange.toDate(),
          end: afterDSTChange.toDate(),
        }),
        afterDSTChange.isBefore(end)
          ? await datapointsRetrieveTimezoneFix(client)({
              ...query,
              start: afterDSTChange.toDate(),
              end: end.toDate(),
            })
          : [{ datapoints: [] }],
      ]);
      return [
        {
          ...before[0],
          datapoints: [
            ...before[0].datapoints,
            ...DSTChangePeriod[0].datapoints,
            ...rest[0].datapoints,
          ],
        } as DatapointAggregates,
      ];
    }
    const result = await client.datapoints.retrieve(query);
    // check if any of the returned datapoints have the same timestamp in the norwegian timezone,
    // if so we have to replace the two of them with one datapoint with the average of the two
    if (
      query.aggregates &&
      query.aggregates.length === 1 &&
      query.aggregates[0] === "average"
    ) {
      return result.map((item, index) => {
        const datapoints = item.datapoints;
        const datapointsWithFixedTimezone = [];
        for (let i = 0; i < datapoints.length; i++) {
          const current = datapoints[i] as DatapointAggregate;
          const next = datapoints[i + 1] as DatapointAggregate;
          if (
            next &&
            dayjs(current.timestamp).format("YYYY-MM-DD HH:mm:ss") ===
              dayjs(next.timestamp).format("YYYY-MM-DD HH:mm:ss")
          ) {
            const aggreate = query.aggregates![index] as Aggregate;

            datapointsWithFixedTimezone.push({
              timestamp: current.timestamp,
              [aggreate]: (current[aggreate]! + next[aggreate]!) / 2,
            });
            i++;
          } else {
            datapointsWithFixedTimezone.push(current);
          }
        }
        return {
          ...item,
          datapoints: datapointsWithFixedTimezone,
        } as DatapointAggregates;
      });
    }
    return result;
  };

export const formatDateYearAccordingToGranularityShort: Record<
  Granularity,
  Function
> = {
  M: (time: number) => {
    const start = dayjs(time).startOf("month");
    const end = dayjs(time).endOf("month");

    if (start.year() === end.year()) {
      if (start.month() === end.month()) {
        return `${start.format("MMMM YYYY")}`;
      }
      return `${start.format("MMMM")} - ${end.format("MMMM YYYY")}`;
    }
    return `${start.format("MMMM YYYY")} - ${end.format("MMMM YYYY")}`;
  },
  w: (time: number) => {
    const start = dayjs(time).startOf("week");
    const end = dayjs(time).endOf("week");

    if (start.month() === end.month() && start.year() === end.year()) {
      return `${start.format("D")} - ${end.format("D MMM YYYY")}`;
    } else if (start.year() === end.year()) {
      return `${start.format("D MMM")} - ${end.format("D MMM YYYY")}`;
    }
    return `${start.format("D MMM YYYY")} - ${end.format("D MMM YYYY")}`;
  },
  d: (time: number) => dayjs(time).format("D. MMM YYYY"),
  h: (time: number) => dayjs(time).format("HH:mm D/M YYYY"),
};

const getLastSundayBefore = (date: Dayjs) => {
  const weekday = date.get("day");
  const dayDiff = weekday === 0 ? 7 : weekday;
  return date.subtract(dayDiff, "day");
};

export const getWinterTimeStartFromDate = (date: Dayjs) => {
  const month = date.month(10).startOf("month").hour(2);
  return month.isBefore(date)
    ? getLastSundayBefore(month.add(1, "year"))
    : getLastSundayBefore(month);
};

export const getSummerTimeStartFromDate = (date: Dayjs) => {
  const month = date.month(3).startOf("month").hour(2);
  return month.isBefore(date)
    ? getLastSundayBefore(month.add(1, "year"))
    : getLastSundayBefore(month);
};

export const getTimeseriesForAssets = async (
  client: CogniteClient,
  assetIds: number[],
) => {
  const getChunk = async (ids: number[]) => {
    return client!.timeseries
      .list({
        filter: {
          assetIds: ids,
        },
      })
      .autoPagingToArray({ limit: -1 });
  };
  return (
    await Promise.all(_.chunk(assetIds, 100).map((chunk) => getChunk(chunk)))
  ).flat();
};

export const getTimeseriesWithLabels = async (
  client: CogniteClient,
  id: number,
  labels: string[],
  skipLabels?: string[],
) => {
  const assets = await client.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        labels: { containsAll: labels.map((label) => ({ externalId: label })) },
      },
      limit: 1000,
    })
    .autoPagingToArray({ limit: -1 });

  return getTimeseriesForAssets(
    client,
    assets
      .filter(
        (asset) =>
          !(asset.labels || [])
            .map((label) => label.externalId)
            .some((label) => (skipLabels || []).includes(label)),
      )
      .map((asset) => asset.id),
  );
};

export const getAssetsWithChildLabels = async (
  client: CogniteClient,
  id: number,
  labels: string[],
) => {
  const childAssets = await getAssetsWithLabels(client, id, labels);

  const tempArray: number[] = Array.from(
    new Set(childAssets.map((asset) => asset.parentId!)).values(),
  );
  const assets =
    tempArray && tempArray.length > 0
      ? await client.assets.retrieve(tempArray.map((id) => ({ id })))
      : [];

  return {
    assets,
    childAssets,
  };
};

export async function getEPredAssets(client: CogniteClient, id: number) {
  const { assets } = await getAssetsWithChildLabels(client, id, [
    "epred",
    "real_value",
  ]);
  return assets;
}

export async function getTimeseriesWithAssetLabels(
  client: CogniteClient,
  id: number,
  labels: string[],
) {
  const assets = await getAssetsWithLabels(client, id, labels);
  const timeseries = await getTimeseriesForAssets(
    client,
    assets.map(({ id }) => id),
  );

  return { assets, timeseries };
}

type TimeseriesProps = {
  client: CogniteClient;
  id: number;
  name?: string;
  labels?: string[];
  skipLabels?: string[];
  parentId?: number;
  metadata?: Record<string, string>;
};

export const getTimeseries = async ({
  client,
  id,
  name,
  labels = [],
  skipLabels,
  parentId,
}: TimeseriesProps) => {
  const assets = await client.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        parentIds: parentId ? [parentId] : undefined,
        name: name,
        labels:
          labels.length > 0
            ? { containsAll: labels.map((label) => ({ externalId: label })) }
            : undefined,
      },
    })
    .autoPagingToArray({ limit: -1 });

  if (assets.length === 0) {
    return [];
  }
  return getTimeseriesForAssets(
    client,
    assets
      .filter(
        (asset) =>
          !(asset.labels || [])
            .map((label) => label.externalId)
            .some((label) => (skipLabels || []).includes(label)),
      )
      .map((asset) => asset.id),
  );
};

export const getTimeseriesByExternalId = async (
  client: CogniteClient,
  externalId: string,
) => {
  return (await client.timeseries.retrieve([{ externalId }]))[0];
};

export const getTimeseriesWithAnyLabel = async (
  client: CogniteClient,
  id: number,
  labels: string[],
) => {
  const assets = await client.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        labels: { containsAny: labels.map((label) => ({ externalId: label })) },
      },
    })
    .autoPagingToArray({ limit: -1 });
  if (assets.length === 0) {
    return [];
  }

  return getTimeseriesForAssets(
    client,
    assets.map((asset) => asset.id),
  );
};
export const getChildTimeseriesWithAnyLabel = async (
  client: CogniteClient,
  id: number,
  labels: string[],
) => {
  const assets = await client.assets
    .list({
      filter: {
        parentIds: [id],
        labels: { containsAny: labels.map((label) => ({ externalId: label })) },
      },
    })
    .autoPagingToArray({ limit: -1 });
  if (assets.length === 0) {
    return [];
  }
  return getTimeseriesForAssets(
    client,
    assets.map((asset) => asset.id),
  );
};

export const getAssetsWithLabels = async (
  client: CogniteClient,
  id: number,
  labels: string[],
) => {
  return client.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        labels: { containsAll: labels.map((label) => ({ externalId: label })) },
      },
    })
    .autoPagingToArray({ limit: -1 });
};

export const getAssetByName = async (
  client: CogniteClient,
  id: number,
  name: string,
): Promise<Asset | undefined> => {
  const assets = await client.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        name,
      },
    })
    .autoPagingToArray({ limit: -1 });

  return assets.length > 0 ? assets[0] : undefined;
};

export const getAllAssetChildren = async (
  client: CogniteClient,
  id: number,
) => {
  return client.assets
    .list({
      filter: {
        parentIds: [id],
      },
    })
    .autoPagingToArray({ limit: -1 });
};

export const getAssetById = async (client: CogniteClient, id: number) => {
  const results = await client.assets.retrieve([{ id }]);

  return results[0];
};

export const getName = async (client: CogniteClient, timeseries: any) => {
  const system = getSystemCodeFromExternalId(timeseries.externalId!);

  if (system.startsWith("200")) {
    const asset = await getAssetById(client, timeseries.assetId!);
    const parent = await getAssetById(client, asset.parentId!);
    return `${parent.name} ${parent.description || ""}`;
  }
  return timeseries.name;
};

export const getAssetsByIds = async (client: CogniteClient, ids: number[]) => {
  return client.assets.retrieve(ids.map((id) => ({ id })));
};

type AssetProps = {
  client: CogniteClient;
  ids?: number[];
  parentId?: number;
  labels?: string[];
};

export const getAssets = async ({
  client,
  ids,
  parentId,
  labels,
}: AssetProps) => {
  return client.assets
    .list({
      filter: {
        assetSubtreeIds: ids ? ids.map((id) => ({ id })) : undefined,
        parentIds: parentId ? [parentId] : undefined,
        labels: labels
          ? { containsAll: labels.map((label) => ({ externalId: label })) }
          : undefined,
      },
    })
    .autoPagingToArray({ limit: -1 });
};

export const getTimeseriesForAsset = async (
  client: CogniteClient,
  id: number,
) => {
  const result = (
    await client.timeseries
      .list({ filter: { assetIds: [id] } })
      .autoPagingToArray({ limit: -1 })
  ).filter((ts) => !ts.externalId!.startsWith("TR_"));
  return result.length > 0 ? result[0] : undefined;
};

export type HierarchyNode = {
  node: Timeseries;
  children: HierarchyNode[];
};

export const getBuildingAssets = async ({
  client,
  id,
  labels,
  metadata = {},
}: TimeseriesProps): Promise<Asset[]> => {
  if (!id) {
    return [];
  }
  return await client.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        metadata,
        labels:
          labels && labels.length > 0
            ? { containsAll: labels.map((label) => ({ externalId: label })) }
            : undefined,
      },
    })
    .autoPagingToArray({ limit: -1 });
};

export const getEnergyHierarchyForBuilding = async (
  client: CogniteClient,
  id: number,
  organization: string,
) => {
  const subSerialMeterAssets = await getBuildingAssets({
    client,
    id,
    labels: ["oe_common_sub_serial_meter"],
    metadata: { organization },
  });

  const serialMeterAssets = await getBuildingAssets({
    client,
    id,
    labels: ["oe_common_serial_meter"],
    metadata: { organization },
  });

  const [mainMeterAsset] = await getBuildingAssets({
    client,
    id,
    labels: ["oe_common_main_meter"],
    metadata: { organization },
  });

  if (mainMeterAsset) {
    const assetIdToTimeseriesMap = (
      await getTimeseriesForAssets(client, [
        mainMeterAsset.id,
        ...(subSerialMeterAssets || []).map((asset) => asset.id),
        ...(serialMeterAssets || []).map((asset) => asset.id),
      ])
    ).reduce<Record<number, Timeseries>>(
      (acc, ts) => ({ ...acc, [ts.assetId!]: ts }),
      {},
    );

    const isDescendant = (
      nodeExternalId: string,
      subNodeExternalId: string,
    ) => {
      const nodeCategory = getSystemCodeFromExternalId(nodeExternalId);
      const nodeSubCategory = getSystemCodeFromExternalId(subNodeExternalId);
      return nodeSubCategory.startsWith(nodeCategory.substring(0, 1));
    };

    const root: HierarchyNode = {
      node: assetIdToTimeseriesMap[mainMeterAsset.id],
      children: (serialMeterAssets || []).map((node) => ({
        node: assetIdToTimeseriesMap[node.id]!,
        children: (subSerialMeterAssets || [])
          .filter((subNode) =>
            isDescendant(node.externalId!, subNode.externalId!),
          )
          .map((node) => ({
            node: assetIdToTimeseriesMap[node.id]!,
            children: [],
          })),
      })),
    };

    return root;
  }
  return undefined;
};

export const traverseEnergyHierarchy = (node: HierarchyNode): Timeseries[] => {
  return [node.node, ...node.children.flatMap(traverseEnergyHierarchy)];
};

export const linksFromEnergyHierarchy = (
  node: HierarchyNode,
): { source: Timeseries; target: Timeseries }[] => {
  return [
    ...node.children.map((ts) => ({
      source: node.node,
      target: ts.node,
    })),
    ...node.children.flatMap((child) => linksFromEnergyHierarchy(child)),
  ];
};

export const numberFormatter = (num?: number) => {
  if (!num) {
    return "";
  }
  const SI = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  let i;
  for (i = SI.length - 1; i > 0; i--) {
    if (num >= SI[i].value) {
      break;
    }
  }
  return num / SI[i].value + SI[i].symbol;
};

export const getIndoorClimateAvailableSensors = async (
  client: CogniteClient,
  id: number,
) => {
  const subBuildings = await client.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        labels: { containsAll: [{ externalId: "building" }] },
      },
    })
    .autoPagingToArray({ limit: Infinity });

  return subBuildings.reduce(
    async (prev: Promise<Record<string, string[]>>, current: Asset) => {
      const res = await prev;
      const sb = /\+(.*?)=/.exec(current.externalId!);

      res[sb![1]] = (
        await Promise.all(
          ["temperature", "co2", "VOC"].map(async (label) => {
            const assets = await client.assets.list({
              filter: {
                assetSubtreeIds: [{ id: current.id }],
                labels: { containsAll: [{ externalId: label }] },
              },
              limit: 1,
            });
            return assets.items.length > 0 ? label : undefined;
          }),
        )
      ).filter((sensorId) => sensorId !== undefined) as string[];
      return res;
    },
    Promise.resolve({} as Record<string, string[]>),
  );
};

export function mergeTimeseries(
  graphPointsA: TransformedDataPoint[],
  graphPointsB: TransformedDataPoint[],
): TransformedDataPoint[] {
  const merged: TransformedDataPoint[] = [];
  let i = 0,
    j = 0;
  while (i < graphPointsA.length && j < graphPointsB.length) {
    if (graphPointsA[i].timestamp < graphPointsB[j].timestamp) {
      merged.push(graphPointsA[i]);
      i++;
    } else if (graphPointsA[i].timestamp > graphPointsB[j].timestamp) {
      merged.push(graphPointsB[j]);
      j++;
    } else {
      merged.push(_.merge(graphPointsA[i], graphPointsB[j]));
      i++;
      j++;
    }
  }

  while (i < graphPointsA.length) {
    merged.push(graphPointsA[i]);
    i++;
  }

  while (j < graphPointsB.length) {
    merged.push(graphPointsB[j]);
    j++;
  }

  return merged;
}

export function mergeDatapoints(
  data1: DoubleDatapoint[],
  data2: DoubleDatapoint[],
): DoubleDatapoint[] {
  const merged: DoubleDatapoint[] = [];
  let i = 0,
    j = 0;

  while (i < data1.length && j < data2.length) {
    if (data1[i].timestamp < data2[j].timestamp) {
      merged.push(data1[i]);
      i++;
    } else if (data1[i].timestamp > data2[j].timestamp) {
      merged.push(data2[j]);
      j++;
    } else {
      merged.push({
        timestamp: data1[i].timestamp,
        value: data1[i].value + data2[j].value,
      });
      i++;
      j++;
    }
  }

  while (i < data1.length) {
    merged.push(data1[i]);
    i++;
  }

  while (j < data2.length) {
    merged.push(data2[j]);
    j++;
  }

  return merged;
}

export function isStringNumeric(value: string | undefined) {
  if (value === undefined) {
    return false;
  }
  return /^-?\d+$/.test(value);
}

// align the date to compare to on the same day of the week as the start date
export const getClosestDay = (start: Dayjs, compareToStart: Dayjs) => {
  let diff = start.day() - compareToStart.day();

  if (diff === 0) {
    return compareToStart;
  }
  if (Math.abs(diff) <= 3) {
    return compareToStart.add(diff, "day");
  }
  diff = diff < 0 ? 7 + diff : diff - 7;
  return compareToStart.add(diff, "day");
};

export async function getExtendedTimeseriesDescription(
  client: CogniteClient,
  timeseries: Timeseries,
) {
  if (typeof timeseries.assetId === "number") {
    const [assetForTimeseries] = (await client.assets.retrieve([
      { id: timeseries.assetId },
    ])) as [Asset] | [];
    if (typeof assetForTimeseries?.parentId === "number") {
      const [roomAsset] = (await client.assets.retrieve([
        { id: assetForTimeseries.parentId },
      ])) as [Asset] | [];
      const isRoomAsset =
        roomAsset !== undefined &&
        roomAsset.labels !== undefined &&
        roomAsset.labels.some((label) => label.externalId === "room");
      if (isRoomAsset) {
        return formatExtendedTimeseriesDescription(timeseries, roomAsset);
      }
    }
  }
  return formatExtendedTimeseriesDescription(timeseries);
}

export function formatExtendedTimeseriesDescription(
  timeseries: Timeseries,
  roomAsset?: Asset | null,
  ignoreName = false,
) {
  const system = timeseries.externalId
    ? getSystemCodeFromExternalId(timeseries.externalId)
    : null;
  const subBuilding = timeseries.externalId
    ? ` ${formatSubBuildingFromExternalId(timeseries.externalId)}`
    : null;
  const description = timeseries.description
    ? ` ${timeseries.description}`
    : "";
  const roomNumber = roomAsset?.name ? ` (${roomAsset.name})` : "";
  const name = ignoreName
    ? ""
    : roomAsset?.description
    ? ` ${roomAsset.description}`
    : timeseries.name
    ? ` ${timeseries.name}`
    : "";
  return `${system}${subBuilding}${description}${roomNumber}${name}`;
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
