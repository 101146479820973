import { Edge, Node, Viewport } from "reactflow";
import { DatapointInfo } from "@cognite/sdk";

const EVERYDAY = ["Everyday"];

const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const WORKDAYS = ["Workday", "Weekend"];
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const HOLIDAYS = ["Holidays"];
const DATERANGE = ["DateRange"];
export const VALIDDATESELECTION = [
  ...EVERYDAY,
  ...WEEKDAYS,
  ...WORKDAYS,
  ...HOLIDAYS,
  ...MONTHS,
  ...DATERANGE,
] as const;

export interface Units {
  units: string[][];
}

export interface Datapoint extends DatapointInfo {
  value: number | null;
}
export interface DatapointList extends DatapointInfo {
  [externalId: string]: number | null;
}
interface CalculationFlowGraph {
  edges: Edge[];
  nodes: Node[];
  viewport: Viewport;
}

interface ScheduleItem {
  dateSelection: DATESELECTION;
  timeRange: [Dayjs, Dayjs];
}

export interface ScheduleItemWithKey extends ScheduleItem {
  key: number;
}

export interface FlexAutomationPotential {
  maxKW: number; // Can be negative (increased effect) or positive (decreased effect)
  rampUpSec: number;
  bufferSec: number;
  maxDurationSec: number;
  rampDownSec: number;
}

export interface FlexAutomationMetaData {
  priority: number; // impact severity: 1-33 low, 34-66 medium, 67-100 high
  potential: FlexAutomationPotential;
  activationExternalId: string;
  meterIds: string[];
}

interface CalculationFlowNotification {
  timestamp: number;
  targetExternalId: string | null;
  message: string;
  type: "error" | "warning" | "info";
  dataTimestamp: number | null;
  count: number;
}

export interface CalculationFlowMetaData {
  id: string | null;
  name: string;
  building_id: number;
  frequency: number;
  plan?: ScheduleItem[];
  exceptions?: ScheduleItem[];
  description: string;
  user: string | null;
  type: "virtualSensor" | "cloudAutomation" | "flexAutomation";
  notifications: CalculationFlowNotification[];
  health_ext_id?: string | null;
  last_run: number | null;
  metadata?: FlexAutomationMetaData;
}

type CalculationNode = Pick<Node, "data" | "id">;

type SimplifiedCalculationFlowGraph = {
  edges: Edge[];
  nodes: CalculationNode[];
};

export interface CalculationFlow extends CalculationFlowMetaData {
  calculation_flow: CalculationFlowGraph;
}

export interface SimplifiedCalculationFlow extends CalculationFlowMetaData {
  calculation_flow: SimplifiedCalculationFlowGraph;
}

export interface Timeseries {
  /**
   * External id of the timeseries the datapoints belong to.
   */
  externalId: CogniteExternalId;
  /**
   * Whether the timeseries is a step series or not
   */
  isStep?: boolean;
  /**
   * The list of datapoints
   */
  datapoints: Datapoint[];
  /**
   * Name of the physical unit of the time series
   */
  unit?: string;
}

export interface DataFrame {
  /**
   * For dataframes, the externalId is only used in simulations to uniquely reference a table
   */
  externalId: CogniteExternalId;
  /**
   * Name of the dataframe
   */
  name: string;
  /**
   * Description of the dataframe
   */
  description: string;
  /**
   * Whether the timeseries is a step series or not
   */
  isStep?: boolean;
  /**
   * The list of datapoints
   */
  datapoints: DatapointList[];
  /**
   * Name of the physical unit of the time series
   */
  unit?: string;
}

export interface CalculationFlowRunResults {
  timeseries: Timeseries[];
  dataframes: DataFrame[];
  notifications: CalculationFlowNotification[];
}

interface GraphModalData {
  id: number;
  unit: string;
}

type Sort = {
  key: keyof CalculationFlow;
  order: "asc" | "desc";
};

export type TimeseriesFilter =
  | "none"
  | "greater"
  | "greaterEqual"
  | "less"
  | "lessEqual"
  | "equal"
  | "notEqual"
  | "between";
