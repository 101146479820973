import Color from "color";
import { useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";
import { scaleOrdinal } from "@visx/scale";
import { ScaleOrdinal } from "d3-scale";
import { DESCRIPTION_LEGEND_VALUES } from "@/features/energy";

export interface ColorScale {
  colorScale: ScaleOrdinal<string, string>;
}

export function useGetColorForEnergyGraphPoint(): ColorScale {
  const themeContext = useContext(ThemeContext);
  const OPERATIONAL_COLOR = Color(themeContext.primary).alpha(0.8).toString();
  const NON_OPERATIONAL_COLOR = Color(themeContext.accent1)
    .alpha(0.8)
    .toString();
  const HOLIDAY_COLOR = themeContext.neutral4;
  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        range: [OPERATIONAL_COLOR, NON_OPERATIONAL_COLOR, HOLIDAY_COLOR],
        domain: [
          DESCRIPTION_LEGEND_VALUES.operational,
          DESCRIPTION_LEGEND_VALUES.nonOperational,
          DESCRIPTION_LEGEND_VALUES.holidays,
        ],
      }),
    [OPERATIONAL_COLOR, NON_OPERATIONAL_COLOR, HOLIDAY_COLOR],
  );
  return { colorScale };
}
