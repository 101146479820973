import { Dispatch, useCallback, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { FormContext, FormDispatchContext } from "./FormContextProvider";
import {
  FormContextAction,
  FormContextActionType,
  FormContextEntries,
  FormContextEntry,
  FormContextOptions,
  FormContextType,
} from "./types";

export function useFormContext(): {
  entries: Readonly<FormContextEntries>;
  options: Readonly<FormContextOptions>;
  dispatch: Dispatch<FormContextAction>;
} {
  const { entries, options } = useContext(FormContext) as FormContextType;
  const dispatch = useContext(
    FormDispatchContext,
  ) as Dispatch<FormContextAction>;

  return { entries, options, dispatch };
}

export function useFormEntry<ValueType = unknown>(id?: string) {
  const { entries } = useFormContext();
  if (!id) {
    return undefined;
  }
  return entries[id] as Readonly<FormContextEntry<ValueType>>;
}

export function useFormDefaultValue<ValueType = unknown>(
  id: string,
): ValueType {
  const { options } = useFormContext();
  const entry = useFormEntry<ValueType>(id);
  const [searchParams] = useSearchParams();

  if (options.useUrlSearchParams) {
    const searchValue = searchParams.get(id);
    if (searchValue !== null) {
      return JSON.parse(searchValue);
    }
  }

  return entry?.defaultValue as Readonly<ValueType>;
}

export function useFormValue<ValueType = unknown>(
  id?: string,
): [ValueType, (value: ValueType) => void, string | undefined] {
  const { options, dispatch } = useFormContext();
  const entry = useFormEntry<ValueType>(id);
  const [_searchParams, setSearchParams] = useSearchParams();

  useEffect(
    // When we come across a field that has no value, but has a default-value, set the defaultValue as current value
    () => {
      if ((entry?.value as Readonly<ValueType>) === undefined) {
        const defaultValue = entry?.defaultValue as Readonly<ValueType>;
        if (defaultValue) {
          setValue(defaultValue);
        }
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [entry],
  );

  const setValue = useCallback(
    function setValue(value: ValueType) {
      if (!id) {
        return;
      }
      if (options.useUrlSearchParams) {
        setSearchParams((prev) => {
          prev.set(id, JSON.stringify(value));
          return prev;
        });
      }

      dispatch({
        type: FormContextActionType.change,
        id,
        value,
      });
    },
    [id, options.useUrlSearchParams, setSearchParams, dispatch],
  );

  function getErrorMessage() {
    if (!entry?.hasError) {
      return undefined;
    }
    if (entry?.errorMessage) {
      return entry?.errorMessage;
    }
    return entry?.defaultErrorMessage ?? "--";
  }

  const value = entry?.value as Readonly<ValueType>;
  const errorMessage = getErrorMessage();
  return [value, setValue, errorMessage];
}
