import dayjs from "@properate/dayjs";
import { HolidaysTypes } from "date-holidays";
import {
  getOperationalHoursAsDays,
  OperationalHoursType,
} from "@properate/common";
import {
  timeDay,
  timeHour,
  timeMinute,
  timeMonday,
  timeMonth,
  timeSecond,
  timeTicks,
  timeYear,
} from "d3-time";
import { timeFormatLocale, timeFormat } from "d3-time-format";
import { Granularity } from "@/utils/helpers";
import { Note } from "@/features/notes";
import hd from "../../../utils/holidays";

const localeNO = timeFormatLocale({
  dateTime: "%A den %d. %B %Y %X",
  date: "%d.%m.%Y",
  time: "%H:%M:%S",
  periods: ["AM", "PM"],
  days: [
    "søndag",
    "mandag",
    "tirsdag",
    "onsdag",
    "torsdag",
    "fredag",
    "lørdag",
  ],
  shortDays: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
  months: [
    "januar",
    "februar",
    "mars",
    "april",
    "mai",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "desember",
  ],
  shortMonths: [
    "jan",
    "feb",
    "mars",
    "apr",
    "mai",
    "juni",
    "juli",
    "aug",
    "sep",
    "okt",
    "nov",
    "des",
  ],
});

export function multiFormat(date: Date, locale: string) {
  const formatter = locale === "en" ? timeFormat : localeNO.format;
  if (timeSecond(date) < date) {
    return formatter(".%L")(date);
  }
  if (timeMinute(date) < date) {
    return formatter(":%S")(date);
  }
  if (timeHour(date) < date) {
    return formatter("%H:%M")(date);
  }
  if (timeDay(date) < date) {
    return formatter("%H:%M")(date);
  }
  if (timeMonth(date) < date) {
    return formatter("%b %d")(date);
  }
  if (timeYear(date) < date) {
    return formatter("%B")(date);
  }
  return formatter("%Y")(date);
}

export const getTickFormat = (
  date: Date,
  locale: string,
  granularity?: Granularity,
) => {
  if (granularity === "w") {
    return multiFormat(timeMonday(date), locale);
  } else if (granularity === "d") {
    return multiFormat(timeDay(date), locale);
  } else if (granularity === "M") {
    return multiFormat(timeMonth(date), locale);
  } else if (granularity === "h") {
    return multiFormat(timeDay(date), locale);
  }
  return multiFormat(date, locale);
};

export const getTickValues = (
  start: Date,
  end: Date,
  granularity: Granularity,
) => {
  if (granularity === "d") {
    const startDay = dayjs(start);
    const endDay = dayjs(end);
    const diffDays = endDay.diff(startDay, "day") + 1;
    const ticksCount = diffDays < 10 ? diffDays : 10;
    return timeTicks(start, end, ticksCount).map((d) => d.valueOf());
  }
};

export const getHolidayPeriods = (
  granularity: Granularity,
  start: Date,
  end: Date,
) => {
  if (granularity === "h" || granularity === "d") {
    const periods: { start: number; end: number }[] = [];

    const startYear = dayjs(start).year();
    const endYear = dayjs(end).year();
    const years = Array.from(
      { length: endYear - startYear + 1 },
      (v, k) => k + startYear,
    );

    const holidays = years
      .reduce<HolidaysTypes.Holiday[]>(
        (acc, year) => [...acc, ...hd.getHolidays(year, "NO")],
        [],
      )
      .filter((holiday) => holiday.end >= start && holiday.start <= end);

    if (holidays.length) {
      holidays.forEach((holiday) => {
        periods.push({
          start: holiday.start.valueOf(),
          end: holiday.end.valueOf(),
        });
      });
    }
    return periods;
  }
  return [];
};

export type OperationalPeriods = { start: number; end: number };
export type NoteOperationalPeriods = OperationalPeriods & { noteTitle: string };
export const getNonOperationalPeriods = (
  operationalHours: OperationalHoursType[],
  start: Date,
  end: Date,
) => {
  const periods: OperationalPeriods[] = [];

  const days = getOperationalHoursAsDays(operationalHours);
  const HOUR_FORMAT = "HH:mm";

  let current = dayjs(start);
  while (current.valueOf() < end.valueOf()) {
    const day = dayjs(current).isoWeekday() - 1;
    const dayHours = days[day];

    if (dayHours) {
      const startHour = dayjs(dayHours[0], HOUR_FORMAT).hour();
      if (current.hour() < startHour) {
        periods.push({
          start: current.valueOf(),
          end: dayjs(current).hour(startHour).valueOf(),
        });
      }
      current = dayjs(current).hour(dayjs(dayHours[1], HOUR_FORMAT).hour());

      periods.push({
        start: current.valueOf(),
        end: dayjs(current).hour(0).add(1, "day").valueOf(),
      });
    } else {
      periods.push({
        start: current.valueOf(),
        end: dayjs(current).hour(0).add(1, "day").valueOf(),
      });
    }
    current = dayjs(current).hour(0).add(1, "day");
  }

  return periods;
};

const getStartOfHour = (unixDate: number) => {
  return dayjs(unixDate).startOf("hour").valueOf();
};
const getStartOfNextHour = (unixDate: number) => {
  return dayjs(unixDate).add(1, "hour").startOf("hour").valueOf();
};
const getStartOfDay = (unixDate: number) => {
  return dayjs(unixDate).startOf("day").valueOf();
};
const getStartOfNextDay = (unixDate: number) => {
  return dayjs(unixDate).add(1, "day").startOf("day").valueOf();
};
const addWeek = (unixDate: number) => {
  return dayjs(unixDate).add(1, "week")?.valueOf();
};
const addMonth = (unixDate: number) => {
  return dayjs(unixDate).add(1, "month")?.valueOf();
};
const getStartOfMonth = (unixDate: number) => {
  return dayjs(unixDate).startOf("month").valueOf();
};

const isNextUnit = (time: number) => {
  const endMinutes = dayjs(time).minute();
  return endMinutes > 0;
};

export const getNotePeriods = ({
  granularity,
  notes,
  startDate,
  endDate,
}: {
  granularity: Granularity;
  notes: Note[] | null;
  startDate: Date;
  endDate: Date;
}): NoteOperationalPeriods[] => {
  const start = startDate.valueOf();
  const end = endDate.valueOf();
  if (!notes) return [];

  if (granularity === "h") {
    return notes?.map(({ startTime, endTime, content }) => {
      const endValue = isNextUnit(endTime)
        ? getStartOfNextHour(endTime)
        : getStartOfHour(endTime);
      return {
        start: getStartOfHour(start > startTime ? start : startTime),
        end: end < endValue ? getStartOfHour(end) : endValue,
        noteTitle: content,
      };
    });
  }

  if (granularity === "d") {
    return notes?.map(({ startTime, endTime, content }) => {
      const endValue = isNextUnit(endTime)
        ? getStartOfNextDay(endTime)
        : getStartOfDay(endTime);
      return {
        start: getStartOfDay(start > startTime ? start : startTime),
        end: end < endValue ? end : endValue,
        noteTitle: content,
      };
    });
  }

  if (granularity === "w") {
    return notes?.map(({ startTime, endTime, content }) => {
      const startMonday = timeMonday(
        start >= startTime ? startDate : new Date(getStartOfDay(startTime)),
      );
      const endValue = isNextUnit(endTime) ? addWeek(endTime) : endTime;

      const endMonday = timeMonday(
        end <= endValue ? endDate : new Date(endValue),
      );
      return {
        start: startMonday.valueOf(),
        end: endMonday.valueOf(),
        noteTitle: content,
      };
    });
  }

  if (granularity === "M") {
    return notes?.map(({ startTime, endTime, content }) => {
      const startMonth = timeMonth(
        start >= startTime ? startDate : new Date(getStartOfMonth(startTime)),
      );
      const endValue = isNextUnit(endTime) ? addMonth(endTime) : endTime;
      const endMonth = timeMonth(
        end <= endValue ? endDate : new Date(endValue),
      );
      return {
        start: startMonth.valueOf(),
        end: endMonth.valueOf(),
        noteTitle: content,
      };
    });
  }

  return [];
};
