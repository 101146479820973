import { useTranslations } from "@properate/translations";
import { useEffect } from "react";
import { useAvailableSystems } from "../../hooks/useAvailableSystems";
import {
  TimeseriesMainFilters,
  TimeseriesFilters,
  TimeseriesHideableFilter,
  CategoryOption,
  TimeseriesInitialFilters,
} from "../../types";
import { FilterForm } from "./elements";
import { TimeseriesSelectionCategoryFilter } from "./TimeseriesSelectionCategoryFilter";
import { TimeseriesSelectionFilter } from "./TimeseriesSelectionFilter";
import { TimeseriesSelectionSystemFilter } from "./TimeseriesSelectionSystemFilter";

interface Props {
  filters: TimeseriesFilters & Pick<TimeseriesMainFilters, "buildingId">;
  hiddenFilters: TimeseriesHideableFilter[];
  categoryOptions: CategoryOption[];
  onChangeCategory: (value: string) => unknown;
  onChangeSystem: (value: string | null) => unknown;
  onChangeSubBulding: (value: string) => unknown;
  subBuildings?: string[];
  initialFilters?: TimeseriesInitialFilters;
}

export function TimeseriesSelectionFilters({
  filters,
  hiddenFilters,
  categoryOptions,
  onChangeCategory,
  onChangeSystem,
  onChangeSubBulding,
  subBuildings,
  initialFilters,
}: Props) {
  const t = useTranslations();
  const { systems, isLoadingSystems } = useAvailableSystems(
    filters,
    categoryOptions,
  );
  useEffect(() => {
    if (
      systems &&
      systems.length > 0 &&
      filters.system === null &&
      initialFilters?.system &&
      systems.includes(initialFilters.system)
    ) {
      onChangeSystem(initialFilters.system);
    }
  }, [systems, initialFilters, filters.system, onChangeSystem]);

  return (
    <FilterForm>
      {!hiddenFilters.includes("category") && (
        <TimeseriesSelectionCategoryFilter
          value={filters.category}
          onChange={onChangeCategory}
          options={categoryOptions}
        />
      )}
      {!hiddenFilters.includes("subBuilding") && (
        <TimeseriesSelectionFilter
          label={t("common.timeseries-modal.building")}
          value={filters.subBuilding}
          options={subBuildings || []}
          onChange={onChangeSubBulding}
          isLoading={false}
        />
      )}
      {!hiddenFilters.includes("system") && (
        <TimeseriesSelectionSystemFilter
          value={filters.system}
          options={systems}
          isLoading={isLoadingSystems}
          onChange={onChangeSystem}
        />
      )}
    </FilterForm>
  );
}
