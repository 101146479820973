import {
  DoubleDatapoints,
  DoubleDatapoint,
  DatapointAggregate,
  DatapointAggregates,
  Aggregate,
} from "@cognite/sdk";
import dayjs from "@properate/dayjs";
import { cogniteClient } from "@/services/cognite-client";
import { Granularity } from "@/utils/helpers";

const COGNITE_GRANULARITY: Record<Granularity, string> = {
  h: "h",
  d: "d",
  w: "7d",
  M: "mo",
};

export const retrieveAggregateOfDatapoints = async ({
  ids,
  start,
  end,
  granularity,
  aggregate,
}: {
  ids: number[];
  start: Date;
  end: Date;
  granularity: Granularity;
  aggregate: Aggregate;
}): Promise<DoubleDatapoints[]> => {
  if (dayjs(start).isSame(end)) {
    throw Error("End same as start");
  }

  // if we expect too many datapoints then we do a query for each id
  const limit = dayjs(end).diff(start, granularity) + 1;

  const datapoints =
    limit * ids.length > 10000
      ? (
          await Promise.all(
            ids.map((id) =>
              cogniteClient.datapoints.retrieve({
                items: [
                  {
                    id,
                  },
                ],
                start,
                end,
                granularity: COGNITE_GRANULARITY[granularity],
                aggregates: [aggregate],
                timeZone: "Europe/Oslo",
                limit: 10000,
              }),
            ),
          )
        ).flat()
      : ((await cogniteClient.datapoints.retrieve({
          items: ids.map((id) => ({ id })),
          start: start,
          end: end,
          granularity: COGNITE_GRANULARITY[granularity],
          aggregates: [aggregate],
          timeZone: "Europe/Oslo",
          limit,
        })) as DatapointAggregates[]);

  const datapointsMap = datapoints.reduce(
    (prev, datapoints) => ({
      ...prev,
      [datapoints.id]: [
        ...(prev[datapoints.id] || []),
        ...(datapoints as DatapointAggregates).datapoints,
      ],
    }),
    {} as Record<number, DatapointAggregate[]>,
  );

  return ids.map((id) => ({
    id,
    isString: false,
    datapoints: datapointsMap[id].map(
      (datapoint) =>
        ({
          timestamp: datapoint.timestamp,
          value: datapoint[aggregate],
        }) as DoubleDatapoint,
    ),
  }));
};
