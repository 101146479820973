import { Asset } from "@cognite/sdk";
import { useRouteLoaderData } from "react-router-dom";
import { App } from "antd";
import useSWR from "swr";
import { useTranslations } from "@properate/translations";
import { fetchBuildingsAreal } from "@/eepApi";
import { parseError } from "@/pages/common/utils";
import { DEFAULT_MESSAGE_DURATION } from "@/utils/helpers";

type BuildingArealResult = Record<string, { area?: number }>;

async function getBuildingsAreal(
  externalIds?: (string | undefined)[],
): Promise<BuildingArealResult> {
  return await fetchBuildingsAreal(externalIds);
}

const useGetBuildingsAreal = () => {
  const t = useTranslations();
  const rootAssets = useRouteLoaderData("root") as Asset[] | null;
  const { message } = App.useApp();
  const externalIdsList = rootAssets?.map((asset) => asset.externalId);
  const { data, isLoading, mutate } = useSWR(
    externalIdsList,
    getBuildingsAreal,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (retryCount >= 1) {
          return;
        }
        setTimeout(() => revalidate({ retryCount }), 1000);
      },
      onError: (error) => {
        console.error(error);
        message.open({
          key: "get-buildings-areal",
          type: "error",
          content: t("common.cant-get-use-area", {
            error: parseError(error),
          }),
          duration: DEFAULT_MESSAGE_DURATION,
        });
      },
    },
  );
  return { data: data || {}, loading: isLoading, mutate };
};
export default useGetBuildingsAreal;
