import { max } from "d3-array";
import { useCallback, useMemo } from "react";
import dayjs from "@properate/dayjs";
import { ScaleOrdinal } from "d3-scale";
import {
  EnergyGraph,
  EnergyNoteInfo,
  getTickValues,
  OperationalPeriods,
  TooltipData,
} from "@/features/energy";
import { Granularity } from "@/utils/helpers";

type Props = {
  width: number;
  height: number;
  startNext: Date;
  endNext: Date;
  granularity: Granularity;
  graphPoints: Record<string, number | string>[];
  legend: string[];
  nonOperationalPeriodsNext: OperationalPeriods[];
  holidayPeriodsNext: OperationalPeriods[];
  colorScale: ScaleOrdinal<string, string>;
  operationalScale: ScaleOrdinal<string, string>;
  showNonOperational: boolean;
  showHolidays: boolean;
  showTemperature: boolean;
  highlightedLegend: string | null;
  onShowTooltip: ({ tooltipData }: { tooltipData: TooltipData }) => void;
  tooltipData?: TooltipData;
  tooltipOpen: boolean;
  onHideTooltip: () => void;
  noteInfo?: EnergyNoteInfo;
  id?: string;
};
export const NextEnergyGraph = ({
  width,
  height,
  startNext,
  endNext,
  granularity,
  graphPoints,
  legend,
  nonOperationalPeriodsNext,
  holidayPeriodsNext,
  onShowTooltip,
  tooltipData,
  tooltipOpen,
  onHideTooltip,
  colorScale,
  operationalScale,
  showNonOperational,
  showHolidays,
  showTemperature,
  highlightedLegend,
  noteInfo: {
    notesDataPeriods = [],
    isNoteModeOn = false,
    setSelectedPoints,
    selectedPoints,
  } = {},
  id,
}: Props) => {
  const nextBands = useMemo(() => {
    const bands = [];
    let current = startNext;
    while (current < endNext) {
      bands.push(current);
      current = dayjs(current).add(1, granularity).toDate();
    }
    return bands;
  }, [startNext, endNext, granularity]);

  const nextData = useMemo(
    () =>
      graphPoints.map((d) => ({
        ...legend.reduce(
          (prev, legend) => ({
            ...prev,
            [legend]: d["next-" + legend],
          }),
          {},
        ),
        total: d["next-total"],
        temperature: d["next-temperature"],
        timestamp: d["next-timestamp"],
        "next-timestamp": d.timestamp,
        "next-temperature": d.temperature,
        "next-total": d.total,
        operational: d["next-operational"],
        "next-operational": d.operational,
      })),
    [graphPoints, legend],
  );

  const dataMax = useMemo(
    () =>
      max(nextData, (d) =>
        Math.max(
          (d.total as number) || Number.MIN_VALUE,
          (d["next-total"] as number) || Number.MIN_VALUE,
        ),
      )!,
    [nextData],
  );
  const tickValuesNext = useMemo(
    () => getTickValues(startNext, endNext, granularity),
    [startNext, endNext, granularity],
  );
  const showNextTooltip = useCallback(
    (data: { tooltipData: TooltipData }) => {
      const notes = notesDataPeriods.filter((noteRange) => {
        return (
          noteRange.start <= data.tooltipData["next-timestamp"]! &&
          dayjs(noteRange.end).subtract(1, granularity).valueOf() >=
            data.tooltipData["next-timestamp"]!
        );
      });

      onShowTooltip({
        tooltipData: {
          ...data.tooltipData,
          timestamp: data.tooltipData["next-timestamp"]!,
          "next-timestamp": data.tooltipData.timestamp,
          energy: data.tooltipData["next-energy"],
          temperature: data.tooltipData["next-temperature"],
          operational: data.tooltipData["next-operational"],
          "next-energy": data.tooltipData.energy,
          "next-temperature": data.tooltipData.temperature,
          "next-operational": data.tooltipData.operational,
          ...(notes &&
            notes?.length > 0 && {
              note: {
                title: notes?.[0]?.noteTitle,
                count: notes?.length,
              },
            }),
        },
      });
    },
    [onShowTooltip, granularity, notesDataPeriods],
  );

  const nextTooltipData = useMemo(() => {
    if (!tooltipData) return undefined;
    const { note, ...data } = tooltipData;
    return {
      ...data,
      timestamp: tooltipData["next-timestamp"]!,
      energy: tooltipData["next-energy"]!,
      temperature: tooltipData["next-temperature"]!,
      operational: tooltipData["next-operational"]!,
    };
  }, [tooltipData]);

  return (
    <EnergyGraph
      width={width}
      height={height}
      bands={nextBands}
      max={dataMax}
      nonOperationalPeriods={nonOperationalPeriodsNext}
      holidayPeriods={holidayPeriodsNext}
      tickValues={tickValuesNext}
      graphPoints={nextData}
      onShowTooltip={showNextTooltip}
      tooltipOpen={tooltipOpen}
      tooltipData={nextTooltipData}
      onHideTooltip={onHideTooltip}
      colorScale={colorScale}
      operationalScale={operationalScale}
      showHolidays={showHolidays}
      showNonOperational={showNonOperational}
      showTemperature={showTemperature}
      showEPred={false}
      legend={legend}
      highlightedLegend={highlightedLegend}
      showNotes={false}
      granularity={granularity}
      noteInfo={{
        notesDataPeriods,
        isNoteModeOn,
        setSelectedPoints,
        selectedPoints,
      }}
      id={id}
    />
  );
};
