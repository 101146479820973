import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Collapse, Space, Tooltip } from "antd";
import styled from "styled-components";
import { useTranslations } from "@properate/translations";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getSystemCodeFromExternalId } from "@properate/common";
import { useLocalStorageState } from "ahooks";
import { ImportCalendarButton } from "@/pages/properateCalendar/components/ImportCalendarButton";

import {
  CalendarStatusMap,
  ColoredProperateCalendar,
  ProperateCalendar,
} from "@/utils/types";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { SearchInput } from "@/components/SearchIndex";
import { useTranslateSystem } from "@/utils/tfm";
import { Group } from "./components/elements";
import HolidayCalendarInfo from "./components/HolidayCalendarInfo";
import CalendarInfo from "./components/CalendarInfo";

const InfoIconContainer = styled.span`
  .anticon {
    border-radius: 50%;
    background-color: ${(props) => props.theme.warning};
    color: ${(props) => props.theme.white};
    padding: 2px;
  }
`;

const ErrorIconContainer = styled.span`
  .anticon {
    border-radius: 50%;
    background-color: ${(props) => props.theme.error};
    color: ${(props) => props.theme.white};
    padding: 2px;
  }
`;

const WarningIconContainer = styled.span`
  .anticon {
    border-radius: 50%;
    background-color: ${(props) => props.theme.warning};
    color: ${(props) => props.theme.white};
    padding: 2px;
  }
`;

type Props = {
  timeseries: ProperateCalendar[];
  timeseriesMap: Record<string, ColoredProperateCalendar>;
  refreshCalendarList: () => Promise<void>;
  selectedTimeseries: string[];
  selectTimeseries: (selection: string[]) => void;
  selectedCalendars: string[];
  status: CalendarStatusMap;
  selectCalendars: (selection: string[]) => void;
  onUpdatePreferences: (calendar_id: string, update: { color: string }) => void;
  onUpdateCalendar: (
    calendar_id: string,
    update: {
      defaultValue?: string;
      validValues?: Record<string, string>;
      active?: boolean;
      extendedDescription?: string;
      ownedByProperate?: boolean;
    },
  ) => void;
  onDeleteCalendar: (calendar_id: string) => void;
};

export const getDescription = (calendar: ProperateCalendar) => {
  return `${calendar.system} ${calendar.name} ${calendar.description}`;
};
export const Side = ({
  status,
  timeseries,
  timeseriesMap,
  selectTimeseries,
  selectedTimeseries,
  selectedCalendars,
  selectCalendars,
  onUpdatePreferences,
  onUpdateCalendar,
  refreshCalendarList,
  onDeleteCalendar,
}: Props) => {
  const t = useTranslations();
  const tfm = useTranslateSystem();
  const [search, setSearch] = useState("");

  const [collapseActiveKeys = [], setCollapseActiveKeys] = useLocalStorageState(
    "calendar:collapse-active-keys",
    { defaultValue: [] as string[] },
  );

  const PendingIcon = (ts: ProperateCalendar) =>
    status?.[ts.calendar_id] &&
    status[ts.calendar_id].status === "Pending" && (
      <Tooltip title={t("calendar.waiting-for-calendar-update")}>
        <InfoIconContainer>
          <LoadingOutlined />{" "}
        </InfoIconContainer>
      </Tooltip>
    );
  const ErrorIcon = (ts: ProperateCalendar) =>
    status?.[ts.calendar_id] &&
    (status[ts.calendar_id].status === "Error" ||
      status[ts.calendar_id].status === "Warning") && (
      <Tooltip
        title={
          <>
            {status[ts.calendar_id].errors?.map((error, index) => (
              <div key={index}>
                {t("calendar.fail")}: {error.message}
              </div>
            ))}
            {status[ts.calendar_id].warnings?.map((warning, index) => (
              <div key={index}>
                {t("calendar.warning")}
                {warning.message}
              </div>
            ))}
          </>
        }
      >
        {status[ts.calendar_id].status === "Error" ? (
          <ErrorIconContainer>
            <ExclamationCircleOutlined />
          </ErrorIconContainer>
        ) : (
          <WarningIconContainer>
            <WarningOutlined />
          </WarningIconContainer>
        )}{" "}
      </Tooltip>
    );

  function searchTimeseries(search: string) {
    const searchTerms = search
      .trim()
      .split(" ")
      .map((term) => term.toLowerCase());

    return timeseries
      .filter((item) => {
        const extendedDescription =
          timeseriesMap[item.calendar_id].extended_description ||
          getDescription(item);
        return searchTerms.every((term) =>
          (extendedDescription || "").toLowerCase().includes(term),
        );
      })
      .sort((a, b) => a.system.localeCompare(b.system));
  }
  const timeseriesData = search
    ? searchTimeseries(search)
    : timeseries.sort((a, b) => a.system.localeCompare(b.system));

  const getCollapseGroupName = useCallback(
    (ts: ProperateCalendar): string => {
      const systemNumber = ts.system.slice(0, 2);
      return `${systemNumber} ${tfm(systemNumber)}`;
    },
    [tfm],
  );

  useEffect(() => {
    if (selectedTimeseries.length) {
      setCollapseActiveKeys((prev) => [
        ...new Set([
          ...(prev || []),
          ...selectedTimeseries.map((ts) => {
            const systemNumber = getSystemCodeFromExternalId(ts).slice(0, 2);
            return `${systemNumber} ${tfm(systemNumber)}`;
          }),
        ]),
      ]);
    }
  }, [selectedTimeseries, setCollapseActiveKeys, t, tfm]);

  const groupedTimeseries = useMemo(() => {
    return timeseriesData.reduce<Record<string, ProperateCalendar[]>>(
      (acc, ts) => {
        const groupKey = getCollapseGroupName(ts);
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(ts);
        return acc;
      },
      {},
    );
  }, [timeseriesData, getCollapseGroupName]);

  const collapseItems = Object.entries(groupedTimeseries).map(
    ([groupKey, groupItems]) => {
      const selectedCount = selectedTimeseries.filter(
        (ts) =>
          getSystemCodeFromExternalId(ts).slice(0, 2) ===
          groupKey.split(" ")[0],
      ).length;

      return {
        key: groupKey,
        label: (
          <b>{`${groupKey} ${
            selectedCount > 0 ? `(${selectedCount})` : ""
          }`}</b>
        ),
        children: groupItems.map((ts) => (
          <CalendarInfo
            key={ts.calendar_id}
            extendedDescription={
              timeseriesMap[ts.calendar_id].extended_description ||
              getDescription(ts)
            }
            originalExtendedDescription={getDescription(ts)}
            onChangeExtendedDescription={(extendedDescription) => {
              onUpdateCalendar(ts.calendar_id, {
                extendedDescription,
              });
            }}
            color={timeseriesMap[ts.calendar_id].color}
            onChangeColor={(color) => {
              onUpdatePreferences(ts.calendar_id, { color });
            }}
            defaultValue={ts.default_value}
            onChangeDefaultValue={(value) => {
              onUpdateCalendar(ts.calendar_id, {
                defaultValue: value,
              });
            }}
            validValues={ts.valid_values}
            setValidValues={(value) => {
              onUpdateCalendar(ts.calendar_id, {
                validValues: value,
              });
            }}
            originalValidValues={ts.original_valid_values}
            checkValue={ts.calendar_id}
            active={ts.active}
            setActive={(active) => onUpdateCalendar(ts.calendar_id, { active })}
            ownedByProperate={ts.owned_by_properate}
            setOwnedByProperate={(ownedByProperate) =>
              onUpdateCalendar(ts.calendar_id, { ownedByProperate })
            }
            softwareCalendar={ts.software_calendar}
            deleteCalendar={() => onDeleteCalendar(ts.calendar_id)}
          >
            {PendingIcon(ts)}
            {ErrorIcon(ts)}
            <ProperateHighlighter
              searchWords={search?.split(" ") || [""]}
              autoEscape
              textToHighlight={
                timeseriesMap[ts.calendar_id].extended_description ||
                getDescription(ts)
              }
            />
            {!ts.active && (
              <Button
                type="primary"
                onClick={() =>
                  onUpdateCalendar(ts.calendar_id, { active: true })
                }
                size="small"
                style={{ position: "absolute", right: 0, top: 3 }}
              >
                {t("calendar.activate")}
              </Button>
            )}
            {ts.active &&
              ts.default_value !== null &&
              t("calendar.default", {
                defaultValue:
                  ts.valid_values[ts.default_value] || ts.default_value,
              })}
          </CalendarInfo>
        )),
      };
    },
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        padding: "8px 20px",
        overflow: "auto",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <h2>{t("calendar.building-calendars")}</h2>

        <SearchInput
          allowClear
          style={{ width: 300, marginBottom: 8 }}
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
          placeholder={t("calendar.search-placeholder")}
        />
        <Group
          value={selectedTimeseries}
          onChange={(selection) => {
            selectTimeseries(selection as string[]);
          }}
          style={{ width: "100%" }}
        >
          {Object.keys(groupedTimeseries).length > 0 && (
            <Collapse
              items={collapseItems}
              style={{
                width: "360px",
                marginBottom: 16,
              }}
              activeKey={
                search
                  ? timeseriesData.map((ts) => getCollapseGroupName(ts))
                  : collapseActiveKeys
              }
              onChange={(keys) => setCollapseActiveKeys(keys as string[])}
            />
          )}
          <ImportCalendarButton refreshCalendarList={refreshCalendarList} />
        </Group>
        <h2>{t("calendar.other-calendars")}</h2>
        <Group
          style={{ width: "100%" }}
          value={selectedCalendars}
          onChange={(selection) => {
            selectCalendars(selection as string[]);
          }}
        >
          {timeseriesMap["holidays"] && (
            <HolidayCalendarInfo
              color={timeseriesMap["holidays"].color}
              onChangeColor={(color) => {
                onUpdatePreferences("holidays", { color });
              }}
              checkValue={"holidays"}
            >
              {timeseriesMap["holidays"].name}{" "}
              {timeseriesMap["holidays"].description}
            </HolidayCalendarInfo>
          )}
        </Group>
      </Space>
    </div>
  );
};
