import { Button, message, Tooltip } from "antd";
import { useUser } from "@properate/auth";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { AccessModules } from "@properate/common";
import { useCallback, useState } from "react";
import { useBuildingModulesAccess } from "@/services/featureAccess/useAccessModuleFeatureAccess";
import { AnalysisCreateRedirectButtonProps } from "@/utils/types";
import { createAnalysis } from "@/eepApi";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { isErrorWithMessage } from "@/utils/api";
import { getTitle } from "@/pages/common/utils";

export function AnalysisCreateRedirectButton({
  buildingId,
  timeseries,
  auxiliaryTimeseries,
  yAxisSettings,
  style,
  colors,
}: AnalysisCreateRedirectButtonProps) {
  const user = useUser();
  const navigate = useNavigate();
  const { accessModules, isLoading } = useBuildingModulesAccess();
  const [isCreateAnalysisLoading, setIsCreateAnalysisLoading] = useState(false);
  const t = useTranslations();

  const analysisName = getTitle(timeseries);

  const redirectToAnalysis = useCallback(async () => {
    setIsCreateAnalysisLoading(true);

    const settingsValueLimit =
      yAxisSettings?.min || yAxisSettings?.max
        ? {
            valueLimit: {
              min: yAxisSettings?.min,
              max: yAxisSettings?.max,
            },
          }
        : {};

    try {
      const data = await createAnalysis({
        title: analysisName,
        buildingId,
        owner: user.email,
        settingsTimeseriesList: [
          timeseries,
          ...(auxiliaryTimeseries || []),
        ].map((ts, i) => {
          return {
            id: ts.id,
            hidden: false,
            color: (colors || Object.values(DEFAULT_GLOBAL_THEME.color))[i],
            unitSelected: timeseries?.unit || "",
            ...settingsValueLimit,
          };
        }),
        created: Date.now(),
        mergeUnits: true,
      });
      navigate(`../analysis/simple/${data.id}`);
    } catch (error) {
      message.error({
        key: "AnalysisCreateRedirectButton",
        content: isErrorWithMessage(error)
          ? t("analysis.error.create-analysis", { error: error.message })
          : t("analysis.error.create-analysis-no-message"),
        duration: 2,
      });
    } finally {
      setIsCreateAnalysisLoading(false);
    }
  }, [
    yAxisSettings?.min,
    yAxisSettings?.max,
    analysisName,
    buildingId,
    user.email,
    timeseries,
    auxiliaryTimeseries,
    navigate,
    colors,
    t,
  ]);

  const hasUserAccessToAnalysis = accessModules.includes(
    AccessModules.enum.analysis,
  );

  return isLoading
    ? null
    : hasUserAccessToAnalysis && (
        <Tooltip
          title={t("analysis.timeseries-graph.create-analysis-button-text")}
        >
          <Button
            onClick={() => redirectToAnalysis()}
            disabled={isCreateAnalysisLoading}
            loading={isCreateAnalysisLoading}
            style={style}
          >
            {t("analysis.timeseries-graph.create-analysis-button-text")}
          </Button>
        </Tooltip>
      );
}
