import { PageHeader } from "@ant-design/pro-layout";
import {
  Avatar,
  Button,
  Card,
  Form,
  Input,
  Popconfirm,
  Radio,
  Space,
  Switch,
  Typography,
} from "antd";
import { RightOutlined, WarningOutlined } from "@ant-design/icons";
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslations } from "@properate/translations";
import {
  AlertRule,
  getSimplifiedPriorityFromOldPriority,
  SimplifiedAlarmPriority,
} from "@properate/common";
import { useMemo, useState } from "react";
import useSWR from "swr";
import { useUser } from "@properate/auth";
import * as React from "react";
import { useTheme } from "styled-components";
import { CompactContent } from "@/components/CompactContent";
import {
  getAlertConfiguration,
  getUnresolvedIncidentsCountByAlarmId,
} from "@/eepApi";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { AlertRuleUpdateModal } from "@/pages/alarmSystem/ruleDetails/AlertRuleUpdateModal";
import { useGetNewAlarms } from "@/features/alarms";
import { BellIcon } from "../rules/icons/BellIcon";
import {
  FormValues,
  mapValues,
  useCreateAlertRule,
  useDeleteAlertRule,
  useUpdateAlertRule,
} from "./utils";
import { AlertRuleDetailsGroups } from "./AlertRuleDetailsGroups";
import { AlertRuleDetailsExceptions } from "./AlertRuleDetailsExceptions";
import { AlarmsSelector } from "./AlarmsSelector";
import { AlarmsQueryBasedSelector } from "./AlarmsQueryBasedSelector";
import { AlertRuleDetailsFallbackGroup } from "./AlertRuleDetailsFallbackGroup";

type LoaderData = {
  rule?: AlertRule;
};

type Props = {
  rule?: AlertRule;
};

const DEFAULT_VALUES = {
  name: "",
  muted: false,
  type: "query",
  alarm_rule_selector: {
    severity: undefined,
    category: undefined,
  },
  alarm_rule_ids: [],
  group_rules: [],
  group_rules_exceptions: [],
};

function AlertRuleForm(props: Props) {
  const t = useTranslations();
  const theme = useTheme();
  const [form] = Form.useForm<FormValues>();
  const type = Form.useWatch("type", form);
  const muted = Form.useWatch("muted", form);
  const currentBuilding = useCurrentBuilding();
  const buildingId = currentBuilding.id;
  const params = useParams();
  const navigate = useNavigate();
  const user = useUser();

  const update = useUpdateAlertRule();
  const create = useCreateAlertRule();
  const remove = useDeleteAlertRule();

  const isSubmitting = update.isMutating || create.isMutating;

  const severity = Form.useWatch(["alarm_rule_selector", "severity"], form) as
    | SimplifiedAlarmPriority[]
    | undefined;
  const category = Form.useWatch(["alarm_rule_selector", "category"], form);

  const [showAlertValidationModal, setShowAlertValidationModal] =
    useState<boolean>(false);

  const {
    data: unresolvedIncidentsCount,
    isLoading: unresolvedIncidentsCountLoading,
  } = useSWR(["unresolvedIncidentsCount", params.alertRuleId], () =>
    params.alertRuleId
      ? getUnresolvedIncidentsCountByAlarmId({
          id: params.alertRuleId,
          building_external_id: currentBuilding.externalId!,
        })
      : undefined,
  );

  const groupedCategoriesByLevel = useMemo(() => {
    if (category?.length) {
      return Object.groupBy(category, (c) => c.length);
    }
    return undefined;
  }, [category]);

  const allAlarms = useGetNewAlarms(buildingId);
  const queryAlarms = useGetNewAlarms(
    buildingId,
    undefined,
    undefined,
    groupedCategoriesByLevel?.[1],
    groupedCategoriesByLevel?.[2],
    severity?.map((s) => getSimplifiedPriorityFromOldPriority(s)),
  );

  const showAlertValidationWarningButton =
    !!props.rule && !!unresolvedIncidentsCount;

  function handleSubmit(values: FormValues) {
    if (props.rule) {
      const values = form.getFieldsValue();
      return update.trigger({
        rule: {
          ...props.rule,
          ...mapValues(values),
          alarm_rule_ids:
            values.type === "query"
              ? queryAlarms.data.map((alarm) => alarm.alarm_id)
              : values.alarm_rule_ids,
        },
        id: params.alertRuleId as string,
      });
    }

    return create.trigger({
      ...mapValues(values),
      alarm_rule_ids:
        values.type === "query"
          ? queryAlarms.data.map((alarm) => alarm.alarm_id)
          : values.alarm_rule_ids,
    });
  }

  function handleRemove() {
    if (params.alertRuleId) {
      remove.trigger({
        id: params.alertRuleId,
      });
    }
  }

  const onCancel = () => {
    navigate(`/asset/${buildingId}/alertConfiguration`);
  };

  const isChangedToMuted = props.rule && muted;

  const saveButton = isChangedToMuted ? (
    <Popconfirm
      key="submit"
      title={t("alert-groups.save-confirm")}
      onConfirm={() => {
        form.submit();
      }}
    >
      <Button
        key="submit"
        type="primary"
        ghost
        disabled={remove.isMutating || unresolvedIncidentsCountLoading}
        loading={isSubmitting}
      >
        {props.rule ? t("ui.save") : t("ui.create")}
      </Button>
    </Popconfirm>
  ) : (
    <Button
      key="submit"
      type="primary"
      htmlType="submit"
      ghost
      disabled={remove.isMutating || unresolvedIncidentsCountLoading}
      loading={isSubmitting}
    >
      {props.rule ? t("ui.save") : t("ui.create")}
    </Button>
  );

  return (
    <Form<FormValues>
      form={form}
      initialValues={props.rule ?? DEFAULT_VALUES}
      autoComplete="off"
      onFinish={handleSubmit}
      onFinishFailed={console.error}
      disabled={isSubmitting || remove.isMutating || !user.isAdmin}
      className="h-full w-full flex flex-col mb-24"
    >
      <PageHeader
        title={
          <Space align="center">
            <Link to={`/asset/${buildingId}/alertConfiguration`}>
              {t("alert-rule.title")}
            </Link>
            <RightOutlined />
            {props.rule
              ? t("alert-rule.title-update")
              : t("alert-rule.title-create")}
            <div className="flex items-center">
              <BellIcon />
            </div>
          </Space>
        }
        extra={
          user.isAdmin
            ? [
                <Button key="filter" htmlType="button" onClick={onCancel}>
                  {t("ui.cancel")}
                </Button>,
                props.rule && (
                  <Popconfirm
                    key="delete"
                    title={t("alert-rule.delete-confirm")}
                    onConfirm={handleRemove}
                  >
                    <Button
                      key="delete"
                      type="primary"
                      htmlType="button"
                      disabled={isSubmitting}
                      loading={remove.isMutating}
                      danger
                      ghost
                    >
                      {t("ui.delete")}
                    </Button>
                  </Popconfirm>
                ),
                saveButton,
                showAlertValidationWarningButton && (
                  <Avatar
                    onClick={() => setShowAlertValidationModal(true)}
                    shape="square"
                    style={{
                      backgroundColor: theme.warningBg,
                      color: theme.warningFg,
                    }}
                    size="default"
                    icon={<WarningOutlined />}
                  />
                ),
              ]
            : []
        }
      />
      <CompactContent className="h-full flex-1 space-y-6 mt-4">
        <Card>
          <>
            <div className="max-w-[450px]">
              <Form.Item<FormValues>
                label={t("alert-rule.field-name")}
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item<FormValues>
                label={t("alert-rule.field-mute")}
                name="muted"
                rules={[{ required: true }]}
              >
                <Switch />
              </Form.Item>
              <Form.Item<FormValues>
                label={t("alert-rule.field-type")}
                name="type"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio.Button value="select">
                    {t("alert-rule.field-type-select")}
                  </Radio.Button>
                  <Radio.Button value="query">
                    {t("alert-rule.field-type-query")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            {type === "select" ? (
              <div className="max-w-[450px]">
                <AlarmsSelector alarms={allAlarms} />
              </div>
            ) : (
              <AlarmsQueryBasedSelector queryAlarms={queryAlarms} form={form} />
            )}
          </>
        </Card>
        <div className="space-y-1">
          <Typography.Text strong>
            {t("alert-rule.section-groups-title")}
          </Typography.Text>
          <Card>
            <Form.List name="group_rules" rules={[]}>
              {(fields, { add, remove }) => (
                <AlertRuleDetailsGroups
                  form={form}
                  fields={fields}
                  onAdd={add}
                  onRemove={remove}
                />
              )}
            </Form.List>
          </Card>
        </div>
        <div className="space-y-1">
          <Typography.Text strong>
            {t("alert-rule.section-exceptions-title")}
          </Typography.Text>
          <Card>
            <Form.List name="group_rules_exceptions">
              {(fields, { add, remove }) => (
                <AlertRuleDetailsExceptions
                  form={form}
                  fields={fields}
                  onAdd={add}
                  onRemove={remove}
                />
              )}
            </Form.List>
          </Card>
        </div>
        <AlertRuleDetailsFallbackGroup form={form} />
      </CompactContent>
      {showAlertValidationModal && (
        <AlertRuleUpdateModal
          onClose={() => setShowAlertValidationModal(false)}
        />
      )}
    </Form>
  );
}

export function AlertRuleEditor() {
  const data = useLoaderData() as LoaderData;

  return <AlertRuleForm rule={data.rule} />;
}

export function AlertRuleCreator() {
  return <AlertRuleForm />;
}

AlertRuleEditor.loader = async function Loader(
  args: LoaderFunctionArgs,
): Promise<LoaderData> {
  const id = args.params.alertRuleId;

  if (id) {
    return {
      rule: await getAlertConfiguration(id),
    };
  }

  return {};
};
