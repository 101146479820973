import {
  App,
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Switch,
  Tooltip,
} from "antd";
import { useState } from "react";
import { IoHandRightOutline } from "@react-icons/all-files/io5/IoHandRightOutline";
import { useTranslations } from "@properate/translations";
import { DeleteOutlined } from "@ant-design/icons";
import { DEFAULT_MESSAGE_DURATION } from "@/utils/helpers";
import { parseError } from "@/pages/common/utils";
import { DEFAULT_GLOBAL_THEME } from "../../theme/graph";
import { updateSetPoint } from "../../eepApi";
import { StatusType } from "./types";

export const EditSetPointModal = ({
  editStatus,
  hide,
}: {
  editStatus: StatusType;
  hide: Function;
}) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const t = useTranslations();

  const [priorityMap, setPriorityMap] = useState(
    editStatus["priority-array"]
      ? editStatus["priority-array"].reduce<Record<number, number | null>>(
          (prev, current) => {
            prev[current.index] = current.value;
            return prev;
          },
          {},
        )
      : null,
  );

  const [saving, setSaving] = useState(false);
  const minValue = editStatus.metadata?.min_value;
  const maxValue = editStatus.metadata?.max_value;
  return (
    <Modal
      title={editStatus.name}
      open
      onCancel={() => {
        form.resetFields();
        hide();
      }}
      onOk={async () => {
        form.submit();
      }}
      okButtonProps={{ loading: saving, disabled: saving }}
      cancelButtonProps={{ disabled: saving }}
    >
      {!priorityMap && (
        <Form
          layout="vertical"
          form={form}
          onFinish={async (fields) => {
            setSaving(true);
            try {
              if (typeof fields.value === "number") {
                const value = fields.value;
                const result = await updateSetPoint({
                  external_id: editStatus.externalId,
                  value,
                  audit_source: "web",
                });
                setSaving(false);
                hide({
                  ...editStatus,
                  ...result.data,
                });
              } else {
                setSaving(false);
                hide();
              }
            } catch (error) {
              setSaving(false);
              hide();
              console.error(`Klarte ikke å oppdatere settpunkt: ${error}`);
              const errorMessage = parseError(error);
              message.open({
                type: "error",
                content: t(
                  "writable.edit-set-point-modal.error-update-set-point",
                  { errorMessage },
                ),
                duration: DEFAULT_MESSAGE_DURATION,
              });
            }
            form.resetFields();
          }}
          initialValues={
            typeof editStatus["present-value"] === "number"
              ? {
                  value: editStatus["present-value"],
                }
              : {}
          }
        >
          <h2>{t("writable.edit-set-point-modal.set-point")}</h2>
          <Space direction="vertical">
            <Form.Item
              label="Verdi"
              name="value"
              noStyle
              rules={[
                {
                  required: true,
                  message: t("writable.edit-set-point-modal.rules-set-value"),
                },
                {
                  type: "number",
                  min: editStatus.metadata!.min_value
                    ? Number(editStatus.metadata!.min_value)
                    : Number.MIN_VALUE,
                  max: editStatus.metadata!.min_value
                    ? Number(editStatus.metadata!.max_value)
                    : Number.MAX_VALUE,
                  message: t(
                    "writable.edit-set-point-modal.rules-value-must-be-between",
                    {
                      minValue: Number(minValue),
                      maxValue: Number(maxValue),
                    },
                  ),
                },
              ]}
            >
              <InputNumber
                placeholder={
                  editStatus.metadata?.default_value
                    ? `${DEFAULT_GLOBAL_THEME.numberFormat.format(
                        Number(editStatus.metadata!.default_value),
                      )}`
                    : undefined
                }
                decimalSeparator=","
              />
            </Form.Item>
            <Space>
              {editStatus.metadata.max_value
                ? t("writable.edit-set-point-modal.max-value", {
                    maxValue: Number(maxValue),
                  })
                : ""}{" "}
              {editStatus.metadata.min_value
                ? t("writable.edit-set-point-modal.min-value", {
                    minValue: Number(minValue),
                  })
                : ""}
            </Space>
          </Space>
        </Form>
      )}
      {priorityMap && (
        <Form
          layout="vertical"
          form={form}
          onFinish={async (fields) => {
            setSaving(true);
            let resultData;
            try {
              if (typeof fields.pri8 === "number") {
                const value = fields.pri8;
                setPriorityMap({ ...priorityMap, 8: value });
                const result = await updateSetPoint({
                  external_id: editStatus.externalId,
                  priority: 8,
                  value,
                  audit_source: "web",
                });
                resultData = result.data;
              } else if (typeof fields.pri16 === "number") {
                if (
                  (editStatus["priority-array"] || []).find(
                    (pri: any) =>
                      pri.index === 8 && typeof pri.value === "number",
                  )
                ) {
                  // remove 8
                  await updateSetPoint({
                    external_id: editStatus.externalId,
                    priority: 8,
                    audit_source: "web",
                  });
                }
                const value = fields.pri16;
                setPriorityMap({ ...priorityMap, 16: value });
                const result = await updateSetPoint({
                  external_id: editStatus.externalId,
                  priority: 16,
                  value,
                  audit_source: "web",
                });
                resultData = result.data;
              }

              const removedMidPriorities = editStatus["priority-array"].filter(
                (item) => !(item.index in priorityMap) && item.index > 8,
              );

              // send requests to update removed priorities between 8 and 16
              for (const priority of removedMidPriorities) {
                const result = await updateSetPoint({
                  external_id: editStatus.externalId,
                  priority: priority.index,
                  audit_source: "web",
                });

                resultData = result.data;
              }

              setSaving(false);
              hide(
                Object.keys(resultData).length
                  ? { ...editStatus, ...resultData }
                  : undefined,
              );
            } catch (error) {
              setSaving(false);
              hide();
              console.error(`Klarte ikke å oppdatere settpunkt: ${error}`);
              const errorMessage = parseError(error);
              message.open({
                type: "error",
                content: t(
                  "writable.edit-set-point-modal.error-update-set-point",
                  { errorMessage },
                ),
                duration: DEFAULT_MESSAGE_DURATION,
              });
            }
            form.resetFields();
          }}
          fields={[
            {
              name: "pri8",
              value: priorityMap[8],
            },
            {
              name: "pri16",
              value: priorityMap[16],
            },
          ]}
        >
          <Form.Item
            label={t("writable.edit-set-point-modal.set-point")}
            style={{ display: "flex" }}
          >
            <div className="flex flex-col gap-1">
              {[...Array(8).keys()]
                .filter((n) => n in priorityMap)
                .map((n) => {
                  return (
                    <Row className="min-h-[28px]" gutter={[8, 8]} key={n}>
                      <Col className="flex items-center gap-1" flex="auto">
                        {t("writable.edit-set-point-modal.priority-n", { n })}
                      </Col>
                      <Col className="flex items-center gap-1" flex="none">
                        {DEFAULT_GLOBAL_THEME.numberFormat.format(
                          Number(priorityMap[n]),
                        )}
                      </Col>
                    </Row>
                  );
                })}
              {8 in priorityMap && (
                <Row className="min-h-[28px]" gutter={[8, 8]}>
                  <Col className="flex items-center gap-1" flex="auto">
                    {t("writable.edit-set-point-modal.priority")} 8{" "}
                    <IoHandRightOutline />
                  </Col>
                  <Col className="flex items-center gap-1" flex="none">
                    <Form.Item
                      name="pri8"
                      noStyle
                      rules={[
                        {
                          type: "number",
                          min: Number(editStatus.metadata!.min_value),
                          max: Number(editStatus.metadata!.max_value),
                          message: t(
                            "writable.edit-set-point-modal.rules-value-must-be-between",
                            {
                              minValue: Number(minValue),
                              maxValue: Number(maxValue),
                            },
                          ),
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder={
                          editStatus.metadata!.default_value
                            ? `${DEFAULT_GLOBAL_THEME.numberFormat.format(
                                Number(editStatus.metadata!.default_value),
                              )}`
                            : undefined
                        }
                        step=".1"
                        disabled={saving}
                        decimalSeparator=","
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {[...Array(7).keys()]
                .map((n) => n + 8 + 1)
                .filter((n) => n in priorityMap)
                .map((n) => {
                  return (
                    <Row className="min-h-[28px]" gutter={[8, 8]} key={n}>
                      <Col className="flex items-center gap-1" flex="auto">
                        {" "}
                        {t("writable.edit-set-point-modal.priority-n", { n })}
                      </Col>
                      <Col className="flex items-center gap-1" flex="none">
                        {DEFAULT_GLOBAL_THEME.numberFormat.format(
                          Number(priorityMap[n]),
                        )}
                      </Col>
                      <div className="absolute right-[-32px]">
                        <Form.Item name={n.toString()}>
                          <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              form.setFieldValue(n.toString(), undefined);
                              const { [n]: _, ...rest } = priorityMap;
                              setPriorityMap(rest);
                            }}
                          />
                        </Form.Item>
                      </div>
                    </Row>
                  );
                })}
              <Row className="min-h-[28px]" gutter={[8, 8]}>
                <Col className="flex items-center gap-1" flex="auto">
                  {t("writable.edit-set-point-modal.priority")} 16
                </Col>
                <Col className="flex items-center gap-1" flex="none">
                  <Form.Item
                    name="pri16"
                    noStyle
                    rules={[
                      {
                        required: 16 in priorityMap,
                        message: t(
                          "writable.edit-set-point-modal.rules-priority-16",
                        ),
                      },
                      {
                        type: "number",
                        min: Number(editStatus.metadata!.min_value),
                        max: Number(editStatus.metadata!.max_value),
                        message: t(
                          "writable.edit-set-point-modal.rules-value-must-be-between",
                          {
                            minValue: Number(minValue),
                            maxValue: Number(maxValue),
                          },
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={
                        editStatus.metadata!.default_value
                          ? `${DEFAULT_GLOBAL_THEME.numberFormat.format(
                              Number(editStatus.metadata!.default_value),
                            )}`
                          : undefined
                      }
                      disabled={8 in priorityMap || saving}
                      step=".1"
                      decimalSeparator=","
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="min-h-[28px]" gutter={8} style={{ marginTop: 8 }}>
                <Col flex="auto" />
                <Col flex="none">
                  <Tooltip
                    title={t(
                      "writable.edit-set-point-modal.add-higher-priority-tooltip",
                    )}
                  >
                    <Switch
                      disabled={saving}
                      checked={8 in priorityMap}
                      checkedChildren={<IoHandRightOutline />}
                      unCheckedChildren={<IoHandRightOutline />}
                      onChange={(on) => {
                        if (on) {
                          setPriorityMap({ ...priorityMap, 8: null });
                        } else {
                          const { [8]: _, ...rest } = priorityMap;
                          setPriorityMap(rest);
                        }
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>
              <Row className="min-h-[28px]" style={{ marginTop: 8 }}>
                <Col span={24}>
                  {editStatus.metadata.max_value
                    ? t("writable.edit-set-point-modal.max-value", {
                        maxValue: Number(maxValue),
                      })
                    : ""}{" "}
                  {editStatus.metadata.min_value
                    ? t("writable.edit-set-point-modal.min-value", {
                        minValue: Number(minValue),
                      })
                    : ""}
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
