import { CogniteClient, Timeseries } from "@cognite/sdk";
import {
  formatSubBuildingFromExternalId,
  getSystemCodeFromExternalId,
} from "@properate/common";
import { Checkbox, Col, Radio, Row, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useMemo, useState } from "react";
import { useTranslations, MessageKey } from "@properate/translations";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { getBuildingAssets } from "@/utils/helpers";
import { SearchInput } from "@/components/SearchIndex";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { getTimeseriesForAssets } from "./utils";

type Props = {
  onChangeEnergyMeter: (change: CheckboxChangeEvent) => void;
  setSelectedMeters: (meters: number[]) => void;
  selectedMeters: number[];
  showExternalId: boolean;
};
export const SelectEnergyInstant = ({
  onChangeEnergyMeter,
  setSelectedMeters,
  selectedMeters,
  showExternalId,
}: Props) => {
  const t = useTranslations();

  const { client } = useCogniteClient();
  const building = useCurrentBuilding();
  const [instantaneousTimeSeries, setInstantaneousTimeSeries] = useState<
    Record<string, Timeseries[]>
  >({});
  const [subBuilding, setSubBuilding] = useState("all");
  const [search, setSearch] = useState("");

  useEffect(() => {
    const load = async (client: CogniteClient) => {
      const instantaneousEnergyMeterList = await getBuildingAssets({
        client,
        id: building.id,
        labels: ["instantaneous", "energy_meter"],
      });

      const instantaneousEnergyTimeseriesList = (
        await getTimeseriesForAssets(client, [
          ...instantaneousEnergyMeterList.map((asset) => asset.id),
        ])
      ).reduce<Record<number, Timeseries>>(
        (acc, ts) => ({ ...acc, [ts.assetId!]: ts }),
        {},
      );

      setInstantaneousTimeSeries(
        instantaneousEnergyMeterList
          .filter((asset) => instantaneousEnergyTimeseriesList[asset.id])
          .map((asset) => instantaneousEnergyTimeseriesList[asset.id])
          .sort((a, b) =>
            getSystemCodeFromExternalId(a.externalId!) <
            getSystemCodeFromExternalId(b.externalId!)
              ? -1
              : 1,
          )
          .reduce<Record<string, Timeseries[]>>(
            (prev, current) => ({
              ...prev,
              [getSystemCodeFromExternalId(current.externalId!).substring(
                0,
                1,
              )]: [
                ...(prev[
                  getSystemCodeFromExternalId(current.externalId!).substring(
                    0,
                    1,
                  )
                ] || []),
                current,
              ],
            }),
            {},
          ),
      );
    };
    load(client);
  }, [client, building.id]);

  const subBuildings = useMemo(
    () =>
      [
        ...Object.values(instantaneousTimeSeries).reduce((prev, timeseries) => {
          return timeseries.reduce((prev, ts) => {
            return prev.add(formatSubBuildingFromExternalId(ts.externalId!));
          }, prev);
        }, new Set<string>()),
      ].sort((a, b) => a.localeCompare(b)),
    [instantaneousTimeSeries],
  );
  function searchAssets(search: string) {
    const searchTerms = search
      .trim()
      .split(" ")
      .map((term) => term.toLowerCase());

    return Object.entries(instantaneousTimeSeries).reduce(
      (acc, [key, items]) => {
        const filteredItems = items.filter((item) => {
          return searchTerms.every(
            (term) =>
              (item.externalId || "").toLowerCase().includes(term) ||
              (item.name || "").toLowerCase().includes(term) ||
              (item.description || "").toLowerCase().includes(term),
          );
        });

        if (filteredItems.length > 0) {
          acc[key] = filteredItems;
        }

        return acc;
      },
      {} as Record<string, Timeseries[]>,
    );
  }

  const onChangeSelectAll = (key: string, change: CheckboxChangeEvent) => {
    const allIds: number[] = instantaneousTimeSeriesData[key]
      .filter(
        (ts) =>
          subBuilding === "all" ||
          subBuilding === formatSubBuildingFromExternalId(ts.externalId!),
      )
      .map((ts) => ts.id);

    if (change.target.checked) {
      setSelectedMeters([...selectedMeters, ...allIds]);
    } else {
      setSelectedMeters(selectedMeters.filter((id) => !allIds.includes(id)));
    }
  };
  const instantaneousTimeSeriesData = search
    ? searchAssets(search)
    : instantaneousTimeSeries;
  return (
    <>
      <div className="mb-2.5">
        <Space>
          <SearchInput
            allowClear
            style={{ width: 300 }}
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
            placeholder={t("energy.placeholder.search")}
          />
          {subBuildings.length > 1 && (
            <Space>
              <b>{t("energy.sub-buildings")}:</b>
              <Radio.Group
                onChange={(event) => setSubBuilding(event.target.value)}
                value={subBuilding}
              >
                <Radio value="all" key="all">
                  {t("energy.show-all")}
                </Radio>
                {subBuildings.map((subBuilding) => (
                  <Radio value={subBuilding} key={subBuilding}>
                    {subBuilding}
                  </Radio>
                ))}
              </Radio.Group>
            </Space>
          )}
        </Space>
      </div>
      {Object.keys(instantaneousTimeSeriesData)
        .sort()
        .map((key) => (
          <div style={{ marginBottom: 16, width: "100%" }} key={key}>
            <span className="flex items-center">
              <Checkbox
                onChange={(change) => onChangeSelectAll(key, change)}
                checked={instantaneousTimeSeriesData[key]
                  .filter(
                    (ts) =>
                      subBuilding === "all" ||
                      subBuilding ===
                        formatSubBuildingFromExternalId(ts.externalId!),
                  )
                  .every((ts) => selectedMeters.includes(ts.id))}
              />
              <h3 className="m-0 ml-2">
                {t(
                  `tfm.system.buildingPartNumber.${[
                    parseInt(key),
                  ]}` as MessageKey,
                )}
              </h3>
            </span>

            <Row>
              {instantaneousTimeSeriesData[key]
                .filter(
                  (ts) =>
                    subBuilding === "all" ||
                    subBuilding ===
                      formatSubBuildingFromExternalId(ts.externalId!),
                )
                .map((ts) => {
                  const externalIdDisplay = showExternalId ? (
                    <div>
                      <small>{ts.externalId}</small>
                    </div>
                  ) : (
                    ""
                  );

                  return (
                    <Col span={8} key={ts.id} className="pl-6">
                      <Checkbox
                        value={ts}
                        checked={selectedMeters.some((val) => val === ts.id)}
                        onChange={onChangeEnergyMeter}
                      >
                        <ProperateHighlighter
                          searchWords={search?.split(" ") || [""]}
                          autoEscape
                          textToHighlight={`${getSystemCodeFromExternalId(
                            ts.externalId!,
                          )} ${formatSubBuildingFromExternalId(
                            ts.externalId!,
                          )} ${ts?.description} ${ts?.name} ${externalIdDisplay}`}
                        />
                      </Checkbox>
                    </Col>
                  );
                })}
            </Row>
          </div>
        ))}
    </>
  );
};
