import { useLoaderData, useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { lazy, useCallback, useEffect } from "react";
import { cogniteClient } from "@/services/cognite-client";
import { getComponentTitle } from "@/pages/Components/details/utils";
import { ComponentPagePreview } from "@/pages/Components/details/types";
import { triggerGetUrn } from "@/pages/autodeskViewer/Viewer-helpers";

const FileViewer = lazy(() => import("@properate/file-viewer"));

export const ComponentFilePreviewPage = () => {
  const { component, fileId, files } = useLoaderData() as ComponentPagePreview;

  const navigate = useNavigate();
  const t = useTranslations();

  const goBack = () => {
    navigate(`../components/${component.id}`);
  };

  const onGetUrn = async () => {
    triggerGetUrn(fileId.toString());
  };

  const handleChangeFile = useCallback(
    (fileId: number | string) => {
      navigate({
        pathname: `../components/${component.id}/preview/${fileId}`,
        search: window.location.search,
      });
    },
    [component.id, navigate],
  );

  useEffect(() => {
    if (!files.find((f) => f.id === Number(fileId))) {
      handleChangeFile(files[0].id);
    }
  }, [fileId, files, handleChangeFile]);

  return (
    <div className="absolute h-screen w-screen left-0 top-0 bg-background">
      <FileViewer
        autoScreenDetection
        path={[t("components.details.title"), getComponentTitle(component, t)]}
        files={files}
        file={files.find((f) => f.id === Number(fileId)) || files[0]}
        onClose={goBack}
        client={cogniteClient}
        onGetUrn={onGetUrn}
        onChangeFile={handleChangeFile}
      />
    </div>
  );
};
