import { CheckOutlined } from "@ant-design/icons";
import { cn } from "@properate/ui";
import { FloorPlanSensorAlarm } from "./hooks/useFloorPlanSensorAlarm";

type Props = {
  alarms: FloorPlanSensorAlarm[];
  hideSuccess?: boolean;
};

export function FloorPlanAlarmSign(props: Props) {
  const info = props.alarms.find((alarm) => alarm.level === "status");
  const warning = props.alarms.find((alarm) => alarm.level === "warning");
  const error = props.alarms.find((alarm) => alarm.level === "error");

  if (props.hideSuccess && !error && !warning && !info) {
    return null;
  }

  return (
    <div
      // We're using [data-level] as a CSS selector
      data-level={cn({
        error,
        warning,
        info,
      })}
      className={cn(
        "h-6 w-6 min-w-[1.5rem] rounded-full text-white text-lg flex items-center justify-center",
        {
          "bg-primary": !props.hideSuccess,
          "bg-gray-500": info,
          "bg-yellow-400": warning,
          "bg-red-600": error,
        },
      )}
      title={error?.message ?? warning?.message ?? info?.message}
    >
      {error || warning ? "!" : <CheckOutlined className="text-base" />}
    </div>
  );
}
