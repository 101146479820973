import { useUser } from "@properate/auth";
import { Suspense, useCallback, useContext, useMemo, useState } from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import dayjs from "@properate/dayjs";
import { Button, Col, Row, Space, Switch } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  DownloadOutlined,
  LineChartOutlined,
  TableOutlined,
} from "@ant-design/icons";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { useTooltip } from "@visx/tooltip";
import { extent, max } from "d3-array";
import * as XLSX from "xlsx";
import { ToggleSidebarButton, useSidebarData } from "@properate/ui";
import { useTranslations, MessageKey } from "@properate/translations";
import { DoubleDatapoint } from "@cognite/sdk";
import { getTypeNameKey, getTypePath } from "@/pages/energy/unit";
import {
  EnergyGraphWithLegend,
  EnergyPage,
  EnergyTable,
  ETChart,
  getColorsForAssets,
  getNotePeriods,
  getTickValues,
  GraphTitle,
  Legend,
  LoadingArray,
  NoteOperationalPeriods,
  SelectedEnergyPoints,
  SelectEnergy,
  SelectGranularity,
  TooltipData,
  TrendLineModal,
  useEnergyNoteByDateFiltering,
} from "@/features/energy";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { formatDateYearAccordingToGranularityShort } from "@/utils/helpers";
import { useTrendLines } from "@/services/trendLines";
import { TimeSpanSelection } from "@/components/TimeSpanSelection";
import {
  NoteBuilding,
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
  NotesSidebarValues,
  useAssetList,
  useNotes,
  NotesSidebarViewState,
  NoteCreationModeButton,
} from "@/features/notes";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { EnergyNoteCreationModeCustomContent } from "@/pages/energy/EnergyNoteCreationModeCustomContent";

export const Energy = ({ table }: { table?: boolean }) => {
  const t = useTranslations();

  useBuildingPageTitle(t("energy.energy-consumption"));
  const { viewState } = useSidebarData<NotesSidebarValues>();

  const themeContext = useContext(ThemeContext);
  const user = useUser();
  const page = useLoaderData() as EnergyPage;
  const [showActiveMeter, setShowActiveMeter] = useState(false);
  const [showEnergyPerArea, setShowEnergyPerArea] = useState(false);
  const [showTrendLineDialog, setShowTrendLineDialog] = useState(false);
  const [showNotes, setShowNotes] = useState(true);
  const navigate = useNavigate();
  const { showTooltip, tooltipOpen, tooltipData, hideTooltip } =
    useTooltip<TooltipData>();
  const trendLines = useTrendLines(page.assetId);
  const currentBuilding = useCurrentBuilding();
  const { assetList } = useAssetList();

  const assetListIds = useMemo(
    () => assetList?.map(({ id }) => id) ?? [],
    [assetList],
  );

  useEnergyNoteByDateFiltering(page.start, page.end);

  const bands = useMemo(() => {
    const bands = [];
    let current = page.start;
    while (current <= page.end) {
      bands.push(current);
      current = dayjs(current).add(1, page.granularity).toDate();
    }
    return bands;
  }, [page.start, page.end, page.granularity]);

  const maxTemperature = useCallback((weather: DoubleDatapoint[]) => {
    return Math.max(...weather.map((d) => d.value));
  }, []);

  const minTemperature = useCallback((weather: DoubleDatapoint[]) => {
    return Math.min(...weather.map((d) => d.value));
  }, []);

  const tickValues = useMemo(
    () => getTickValues(page.start, page.end, page.granularity),
    [page.start, page.end, page.granularity],
  );

  const downloadReport = useCallback(async () => {
    const energy = (await page.data) || [];

    if (energy.length === 0) {
      console.error("no data to download");
      return;
    }

    const excelRows = energy.map((entry) => {
      const { timestamp, total, temperature, operational, ...rest } = entry;
      return {
        [t("energy.report.date")]:
          formatDateYearAccordingToGranularityShort[page.granularity](
            timestamp,
          ),
        [t("energy.report.temperature")]: temperature,
        ...rest,
        [t("energy.report.operative")]: t(
          `energy.legend-values.${operational}` as MessageKey,
        ),
        [t("energy.report.total")]: total,
      };
    });

    const sheetName = dayjs(energy[0].timestamp).format("DD-MM-YYYY");
    const worksheet = XLSX.utils.json_to_sheet(excelRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    XLSX.writeFile(
      workbook,
      `${t("energy.report.energy-data")} ${sheetName}.xlsx`,
    );
  }, [page, t]);

  const inAFewDays = useMemo(
    () => dayjs().endOf("d").add(5, "d").valueOf(),
    [],
  );

  const colors = useMemo(
    () =>
      [
        themeContext.accent2,
        themeContext.accent3,
        themeContext.accent4,
        themeContext.accent5,
        themeContext.accent6,
        themeContext.accent7,
        themeContext.accent8,
        themeContext.accent9,
        themeContext.accent10,
      ].reverse(),
    [themeContext],
  );

  const notesIdSet = useMemo(() => {
    return new Set(page.selectedIds);
  }, [page.selectedIds]);

  const notesBuildings = useMemo(() => {
    return [
      {
        id: currentBuilding.dataSetId,
        name: currentBuilding.name,
      } as NoteBuilding,
    ];
  }, [currentBuilding.dataSetId, currentBuilding.name]);

  const colorsForAssets: Record<string, string> = useMemo(() => {
    return getColorsForAssets(colors, page.selectedIds);
  }, [colors, page.selectedIds]);

  const { notes } = useNotes();

  const notesDataPeriods: NoteOperationalPeriods[] = useMemo(() => {
    return getNotePeriods({
      granularity: page.granularity,
      startDate: bands[0],
      endDate: new Date(
        dayjs(bands[bands.length - 1])
          .add(1, page.granularity)
          .valueOf(),
      ),
      notes,
    });
  }, [bands, notes, page.granularity]);

  const hasNoteByData = useCallback(
    (timestamp: number) => {
      return notesDataPeriods.some(
        (period) => period.start <= timestamp && period.end >= timestamp,
      );
    },
    [notesDataPeriods],
  );

  const [selectedPoints, setSelectedPoints] =
    useState<SelectedEnergyPoints>(null);

  const getTitle = () => {
    const title = t(`energy.energy-consumption-titles.${page.granularity}`);
    if (page.type === "generated") {
      return `${title} (${t("energy.meter-types.temperature-corrected")})`;
    }
    return `${title}`;
  };

  const ETChartData = useMemo(
    () => Promise.all([page.data, page.latestDatapointDate, page.weather]),
    [page.data, page.latestDatapointDate, page.weather],
  );

  const ConsumptionGraphData = useMemo(
    () => Promise.all([page.data, page.hasEPred, page.legend]),
    [page.data, page.hasEPred, page.legend],
  );

  const timespanComponent = useMemo(
    () => (
      <TimeSpanSelection
        key="timeSpan"
        granularity={page.granularity}
        onChange={([start, end]) => {
          navigate(
            `../energyConsumption/${getTypePath(page.type)}/${
              page.granularity
            }/s/${start}/e/${end}${table !== undefined ? "/table" : ""}?root=${
              page.tenantRootAssetId
            }&${page.selectedIds.map((id) => "id=" + id).join("&")}`,
          );
        }}
        value={[page.start.valueOf(), page.end.valueOf()]}
        notAfter={inAFewDays}
        allowClear={false}
        withTime={false}
      />
    ),
    [
      page.granularity,
      page.start,
      page.end,
      page.type,
      page.tenantRootAssetId,
      page.selectedIds,
      inAFewDays,
      navigate,
      table,
    ],
  );

  return (
    <>
      <PageHeader
        title={t("energy.energy-consumption")}
        extra={
          <Space>
            {[
              timespanComponent,
              <SelectGranularity
                key="granularity"
                granularity={page.granularity}
                setGranularity={(granularity) => {
                  navigate(
                    `../energyConsumption/${getTypePath(
                      page.type,
                    )}/${granularity}/s/${page.start.valueOf()}/e/${page.end.valueOf()}?root=${
                      page.tenantRootAssetId
                    }&${page.selectedIds.map((id) => "id=" + id).join("&")}`,
                  );
                }}
              />,
              <Button
                onClick={() => {
                  setShowActiveMeter(true);
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                  }
                }}
                type="default"
                key="meter"
                style={{
                  maxWidth: 250,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {t("energy.selected-meter", {
                  meter: t(getTypeNameKey(page.type)),
                })}
              </Button>,
              <Button
                className="hint-Administrer-trend-linje"
                disabled={user.isViewer}
                onClick={() => {
                  setShowTrendLineDialog(true);
                }}
                key="trend"
              >
                {t("energy.manage-trend-line")}
              </Button>,
              <NoteCreationModeButton
                key="note-creation"
                setSelectedPoints={setSelectedPoints}
                selectedPoints={selectedPoints}
                assetListIds={assetListIds}
                granularity={page.granularity}
                startDate={page.start}
                endDate={page.end}
                DefaultCustomContent={EnergyNoteCreationModeCustomContent}
              />,
              <ToggleSidebarButton
                key="notes"
                hiddenWhenSidebarVisible
                sidebarHiddenContent={t("notes.show-notes-button")}
              />,
            ]}
          </Space>
        }
      />
      <div
        style={{
          flex: "auto",
          paddingBottom: 30,
          paddingRight: 16,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            background: themeContext.background2,
            padding: 24,
            borderRadius: 16,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Row data-testid="ET-chart">
            <Col flex="auto">
              <GraphTitle
                title={t(`energy.et-chart-titles.${page.granularity}`)}
              />
            </Col>
            <Col flex="none">
              <Space>
                {Boolean(page.usableFloorArea) && (
                  <Switch
                    size="small"
                    checked={showEnergyPerArea}
                    onChange={(checked) => setShowEnergyPerArea(checked)}
                    unCheckedChildren="kWh/m²"
                    checkedChildren="kWh/m²"
                  />
                )}
              </Space>
            </Col>
          </Row>
          <div style={{ flexGrow: 1 }}>
            <AutoSizer>
              {({ width, height }: Size) => {
                if (width === window.innerWidth - 48 - 16) {
                  return <></>;
                }
                return (
                  <Suspense
                    fallback={
                      <div
                        style={{
                          height,
                          width,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <SpinnerWithDelay isLoading>
                          <div />
                        </SpinnerWithDelay>
                      </div>
                    }
                  >
                    <Await
                      resolve={ETChartData}
                      errorElement={
                        <LoadingArray height={height} width={width} />
                      }
                    >
                      {([data, latestDatapointDate, weather]) => {
                        const [min, max] = extent(
                          data,
                          (d: { total: number }) => d.total,
                        );
                        return (
                          <ETChart
                            width={width}
                            height={height}
                            min={min!}
                            max={max!}
                            maxTemperature={maxTemperature(weather)}
                            minTemperature={minTemperature(weather)}
                            usableFloorArea={page.usableFloorArea}
                            graphPoints={data}
                            showEnergyPerArea={showEnergyPerArea}
                            onShowTooltip={showTooltip}
                            tooltipOpen={tooltipOpen}
                            tooltipData={tooltipData}
                            onHideTooltip={hideTooltip}
                            trendLine={
                              trendLines?.[page.granularity]
                                ? trendLines[page.granularity]!
                                : []
                            }
                            latestDatapointDate={latestDatapointDate}
                            granularity={page.granularity}
                            showNotes={showNotes}
                            setShowNotes={setShowNotes}
                            noteInfo={{
                              isNoteModeOn:
                                viewState ===
                                NotesSidebarViewState.customContent,
                              setSelectedPoints,
                              notesDataPeriods,
                              selectedPoints,
                            }}
                            assetListIds={assetListIds}
                          />
                        );
                      }}
                    </Await>
                  </Suspense>
                );
              }}
            </AutoSizer>
          </div>
        </div>
        <div style={{ width: "100%", height: "24px" }} />
        <div
          style={{
            flexGrow: 1,
            padding: 24,
            borderRadius: 16,
            background: themeContext.background2,
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <Row data-testid="energy-use-graph">
            <Col flex="auto">
              <GraphTitle title={getTitle()} />
            </Col>
            <Col flex="175px" style={{ textAlign: "right" }}>
              <Space>
                {table ? (
                  <Button
                    onClick={() => {
                      navigate(
                        `..?root=${page.tenantRootAssetId}&${page.selectedIds
                          .map((id) => "id=" + id)
                          .join("&")}`,
                        {
                          relative: "path",
                        },
                      );
                    }}
                    icon={<LineChartOutlined />}
                  >
                    {t("energy.show-as-graph")}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      navigate(
                        `table?root=${page.tenantRootAssetId}&${page.selectedIds
                          .map((id) => "id=" + id)
                          .join("&")}`,
                      );
                    }}
                    icon={<TableOutlined />}
                  >
                    {t("energy.show-as-table")}
                  </Button>
                )}
                <Button icon={<DownloadOutlined />} onClick={downloadReport}>
                  {t("energy.download-as-excel-file")}
                </Button>
              </Space>
            </Col>
          </Row>
          <div style={{ flexGrow: 1 }}>
            <AutoSizer>
              {({ width, height }: Size) => {
                if (width === window.innerWidth - 48 - 16) {
                  return <></>;
                }
                return (
                  <Suspense
                    fallback={
                      <div
                        style={{
                          height,
                          width,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <SpinnerWithDelay isLoading>
                          <div />
                        </SpinnerWithDelay>
                      </div>
                    }
                  >
                    <Await
                      resolve={ConsumptionGraphData}
                      errorElement={
                        <LoadingArray height={height} width={width} />
                      }
                    >
                      {([data, epredFound, legendData]: [
                        { [key: string]: number | string }[],
                        boolean,
                        Legend,
                      ]) => {
                        const maxEnergy = max(data, (d: any) =>
                          Math.max(
                            (d.total || 0) as number,
                            (d.ePredMax || 0) as number,
                          ),
                        )!;

                        const legendWithTotal = legendData.map((legend) => {
                          const total = data.reduce<number>(
                            (prev, current) =>
                              prev + ((current[legend.name] || 0) as number),
                            0,
                          );
                          return {
                            ...legend,
                            total,
                          };
                        });

                        return (
                          <>
                            {table ? (
                              <EnergyTable
                                height={height}
                                width={width}
                                graphPoints={data as Record<string, number>[]}
                                granularity={page.granularity}
                                legend={legendData}
                                hasNoteByData={hasNoteByData}
                                onShowTooltip={showTooltip}
                                noteInfo={{
                                  notesDataPeriods,
                                }}
                              />
                            ) : (
                              <EnergyGraphWithLegend
                                width={width}
                                height={height}
                                bands={bands}
                                max={maxEnergy}
                                nonOperationalPeriods={
                                  page.nonOperationalPeriods
                                }
                                holidayPeriods={page.holidayPeriods}
                                tickValues={tickValues}
                                data={data as Record<string, number>[]}
                                onShowTooltip={showTooltip}
                                tooltipOpen={tooltipOpen}
                                tooltipData={tooltipData}
                                onHideTooltip={hideTooltip}
                                legend={legendWithTotal}
                                hasEPred={epredFound}
                                colors={colors}
                                showNotes={showNotes}
                                setShowNotes={setShowNotes}
                                noteInfo={{
                                  isNoteModeOn:
                                    viewState ===
                                    NotesSidebarViewState.customContent,
                                  setSelectedPoints,
                                  notesDataPeriods,
                                  selectedPoints,
                                }}
                                granularity={page.granularity}
                              />
                            )}
                          </>
                        );
                      }}
                    </Await>
                  </Suspense>
                );
              }}
            </AutoSizer>
          </div>
        </div>
        <SelectEnergy
          root={page.tenantRootAssetId}
          showActiveMeter={showActiveMeter}
          setShowActiveMeter={setShowActiveMeter}
          defaultSelectedHierarchyTimeseries={
            page.type === "hierarchy" ? page.selectedIds : []
          }
          defaultSelectedMeterTimeseries={
            page.type === "energyMeter" ? page.selectedIds : []
          }
          defaultCorrectedMeterTimeseries={
            page.type === "generated" ? page.selectedIds : []
          }
          defaultSelectedEnergySources={
            page.type === "sources" ? page.selectedIds : []
          }
          timeseriesType={page.type}
          selectTimeseries={({ type, timeseries, root }) => {
            navigate(
              `../energyConsumption/${getTypePath(type)}/${
                page.granularity
              }/s/${page.start.valueOf()}/e/${page.end.valueOf()}?root=${root}&${timeseries
                .map((id) => `id=${id}`)
                .join("&")}`,
            );
          }}
        />
        <TrendLineModal
          granularity={page.granularity}
          open={showTrendLineDialog}
          setOpen={() => setShowTrendLineDialog(false)}
          trendLines={
            trendLines?.[page.granularity] ? trendLines[page.granularity]! : []
          }
          assetId={page.assetId}
        />
        {currentBuilding.dataSetId && (
          <NotesSidebar
            noteSource={NoteSource.WEB_ENERGY}
            buildings={notesBuildings}
            assetFilterMode={NotesAssetFilterMode.TimeseriesList}
            idSet={notesIdSet}
            colorOverrides={colorsForAssets}
          />
        )}
      </div>
    </>
  );
};
