import { useContext, useMemo } from "react";
import { Table, TableColumnsType } from "antd";
import { formatMeasurement } from "@properate/common";
import { ThemeContext } from "styled-components";
import { useTranslations } from "@properate/translations";
import {
  calculateAverage,
  EnergyNoteInfo,
  getSum,
  getSumCount,
  Legend,
  TooltipData,
} from "@/features/energy";
import {
  formatDateYearAccordingToGranularityShort,
  Granularity,
} from "@/utils/helpers";
import { useGetColorForEnergyGraphPoint } from "@/hooks/useGetColorForEnergyGraphPoint";

const ESTIMATED_HEADER_HEIGHT = 124;

interface Props {
  width: number;
  height: number;
  graphPoints: Record<string, number>[];
  granularity: Granularity;
  legend: Legend;
  noteInfo?: EnergyNoteInfo;
  hasNoteByData: (timestamp: number) => boolean;
  onShowTooltip: ({ tooltipData }: { tooltipData: TooltipData }) => void;
}

export function EnergyTable({
  width,
  height,
  graphPoints,
  granularity,
  legend,
  noteInfo: { notesDataPeriods = [] } = {},
  hasNoteByData,
  onShowTooltip,
}: Props) {
  const t = useTranslations();
  const themeContext = useContext(ThemeContext);

  const { colorScale } = useGetColorForEnergyGraphPoint();

  const summary = useMemo(() => {
    const averageTemperature = calculateAverage(
      getSumCount(graphPoints, "temperature"),
    );

    const total = getSum(graphPoints, "total");

    return {
      total,
      ...legend.reduce<Record<string, number>>(
        (prev, legend) => ({
          ...prev,
          [legend.name]: getSum(graphPoints, legend.name),
        }),
        {},
      ),
      temperature: averageTemperature,
    };
  }, [graphPoints, legend]);

  const NOTE_STATUS_COLUMN_WIDTH = 50;

  const DATE_COLUMN_WIDTH = 120;

  const TEMPERATURE_COLUMN_WIDTH = 150;

  const DEFAULT_COLUMN_WIDTH = 150;

  const TOTAL_COLUMN_WIDTH = 120;

  const tableWidth =
    NOTE_STATUS_COLUMN_WIDTH +
    DATE_COLUMN_WIDTH +
    TEMPERATURE_COLUMN_WIDTH +
    DEFAULT_COLUMN_WIDTH * legend.length +
    TOTAL_COLUMN_WIDTH;

  const actualWidth = Math.max(width, tableWidth);

  const actualDateColumnWidth =
    actualWidth > tableWidth
      ? DATE_COLUMN_WIDTH + actualWidth - tableWidth
      : DATE_COLUMN_WIDTH;

  const columns: TableColumnsType<Record<string, number>> = [
    {
      title: "",
      key: "action",
      fixed: "left",
      width: NOTE_STATUS_COLUMN_WIDTH,
      render: (_, { timestamp, operational }) => (
        <svg width={14} height={14}>
          <circle
            r={6}
            cx={7}
            cy={7}
            fillOpacity={0.8}
            strokeWidth={2}
            fill={colorScale(String(operational))}
            stroke={
              hasNoteByData(timestamp)
                ? themeContext.accent12
                : themeContext.neutral4
            }
          />
        </svg>
      ),
    },
    {
      title: t("energy.table.date"),
      key: "timestamp",
      fixed: "left",
      width: actualDateColumnWidth,
      render: (_, { timestamp }) =>
        formatDateYearAccordingToGranularityShort[granularity!](timestamp),
    },
    {
      title: t("energy.table.temperature"),
      key: "temperature",
      align: "right",
      width: TEMPERATURE_COLUMN_WIDTH,
      render: (_, { temperature }) =>
        formatMeasurement({ value: temperature, unit: "" }),
    },
    ...legend.map((legend) => ({
      title: legend.name + " (kWh)",
      key: legend.name,
      align: "right" as const,
      width: DEFAULT_COLUMN_WIDTH,
      render: (_: string, rowData: Record<string, number>) => {
        return formatMeasurement({ value: rowData[legend.name], unit: "" });
      },
    })),
    {
      title: t("energy.table.total"),
      key: "total",
      fixed: "right",
      align: "right",
      width: TOTAL_COLUMN_WIDTH,
      render: (_, { total }) => formatMeasurement({ value: total, unit: "" }),
    },
  ];

  return (
    <Table
      virtual
      pagination={false}
      style={{ maxWidth: width, width }}
      scroll={{ y: height - ESTIMATED_HEADER_HEIGHT, x: actualWidth }}
      columns={columns}
      dataSource={graphPoints}
      rowKey="timestamp"
      size="small"
      onRow={(record) => {
        return {
          onMouseOver: () => {
            onShowTooltip({
              tooltipData: {
                timestamp: record.timestamp as number,
                energy: record.total as number,
                temperature: record.temperature as number,
                operational: String(record.operational),
                note: {
                  title: notesDataPeriods?.[0]?.noteTitle || "",
                  count: 1,
                },
              },
            });
          },
        };
      }}
      summary={() => (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}></Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              {t("energy.table.summary-total")}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="right">
              {summary.temperature !== undefined
                ? formatMeasurement({ value: summary.temperature })
                : "--"}
            </Table.Summary.Cell>
            {...legend.map(({ name: legendName }, i) => (
              <Table.Summary.Cell key={legendName} index={3 + i} align="right">
                {formatMeasurement({
                  value: summary[legendName as keyof typeof summary],
                })}
              </Table.Summary.Cell>
            ))}
            <Table.Summary.Cell index={3 + legend.length} align="right">
              {formatMeasurement({ value: summary.total })}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
    />
  );
}
