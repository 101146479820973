import useSWRMutation from "swr/mutation";
import { collection, doc, updateDoc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { useUser } from "@properate/auth";
import { mutate } from "swr";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useFloorPlan } from "../FloorPlanContext";

export function useFloorPlanDelete() {
  const user = useUser();
  const floorPlan = useFloorPlan();
  const buildingId = useCurrentBuildingId();

  return useSWRMutation(
    [buildingId, floorPlan.snapshotId],
    async ([buildingId, snapshotId]) => {
      const collectionRef = collection(browserFirestore, "floor-plans");

      await updateDoc(doc(collectionRef, snapshotId), {
        softDeleted: {
          deletedAt: new Date(),
          deletedBy: user.email,
        },
      });

      await mutate(["floor-plans", buildingId]);
    },
  );
}
