import { useVT } from "virtualizedtableforantd4";
import {
  TableWithoutDefaultSort,
  TableWithoutDefaultSortProps,
} from "../TableWithoutDefaultSort/TableWithoutDefaultSort";

interface Props<RecordType>
  extends Exclude<
    TableWithoutDefaultSortProps<RecordType>,
    "pagination" | "components"
  > {
  height: number;
  onReachedEndOfPage: () => unknown;
}

export function TableInfiniteScroll<RecordType extends object = any>({
  height,
  onReachedEndOfPage,
  ...rest
}: Props<RecordType>) {
  const [vt] = useVT(
    () => ({
      scroll: {
        y: height,
      },
      onScroll: ({ isEnd }) => {
        if (isEnd) {
          onReachedEndOfPage();
        }
      },
      offset: 100,
    }),
    [onReachedEndOfPage],
  );

  return (
    <TableWithoutDefaultSort
      components={vt}
      pagination={false}
      scroll={{
        y: height,
        ...rest.scroll,
      }}
      {...rest}
    />
  );
}
