import { PageHeader } from "@ant-design/pro-layout";
import { Space, Popconfirm, Button } from "antd";
import { RightOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { ShareButtonWithDialog } from "@/components/ShareButtonWithDialog/ShareButtonWithDialog";

interface Props {
  name: string;
  onConfirmDeletion: () => unknown;
}

export function GaugeHeader({ name, onConfirmDeletion }: Props) {
  const t = useTranslations();

  const currentBuildingId = useCurrentBuildingId();
  const location = useLocation();
  const shareLink = `${window.location.origin}${location.pathname}/import`;

  const title =
    name !== undefined ? (
      <Space>
        <Link to={`/asset/${currentBuildingId}/analysis/gauge`}>
          {t("analysis.gauge.name")}
        </Link>
        <RightOutlined />
      </Space>
    ) : (
      t("analysis.gauge.name")
    );

  return (
    <PageHeader
      title={title}
      subTitle={name}
      extra={
        <Space>
          <ShareButtonWithDialog title="Del måler" shareLink={shareLink}>
            {t("analysis.share-text.gauge")}
          </ShareButtonWithDialog>
          <Popconfirm
            title={t("analysis.gauge.delete-confirmation")}
            onConfirm={onConfirmDeletion}
            okText={t("analysis.delete")}
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
          <ToggleSidebarButton
            key="notes"
            hiddenWhenSidebarVisible
            sidebarHiddenContent={t("notes.show-notes-button")}
          />
        </Space>
      }
    />
  );
}
