import * as React from "react";
import { Button, Col, Row, Select, Space, Spin, Tooltip } from "antd";
import {
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";

type Props = {
  mode?: "year" | "month" | "week" | "list";
  date: dayjs.Dayjs;
  setDate: (date: dayjs.Dayjs) => void;
  setMode: (mode: "year" | "month" | "week" | "list") => void;
  addEvent: () => void;
  disabled?: boolean;
  loading?: boolean;
  syncing?: boolean;
  onClickSyncButton: () => void;
};

export const Header = ({
  mode,
  setMode,
  date,
  setDate,
  addEvent,
  disabled,
  loading,
  syncing,
  onClickSyncButton,
}: Props) => {
  const t = useTranslations();

  const next = () => {
    if (mode === "year" || mode === "list") {
      setDate(date.add(1, "year"));
    }
    if (mode === "month") {
      setDate(date.add(1, "month"));
    }
    if (mode === "week") {
      setDate(date.add(1, "week"));
    }
  };

  const previous = () => {
    if (mode === "year" || mode === "list") {
      setDate(date.subtract(1, "year"));
    }
    if (mode === "month") {
      setDate(date.subtract(1, "month"));
    }
    if (mode === "week") {
      setDate(date.subtract(1, "week"));
    }
  };
  return (
    <Row
      style={{
        height: 48,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Col flex="auto">
        <Space>
          <Button
            type="primary"
            onClick={() => setDate(dayjs().tz("Europe/Oslo"))}
          >
            {t("calendar.today")}
          </Button>
          <Button
            type="link"
            icon={<LeftOutlined />}
            onClick={previous}
            disabled={!mode}
          />
          <Button
            type="link"
            icon={<RightOutlined />}
            onClick={next}
            disabled={!mode}
          />
          {(mode === "year" || mode === "list") && (
            <h1 style={{ margin: 0, fontSize: 22 }}>{date.year()}</h1>
          )}
          {mode === "month" && (
            <h1 style={{ margin: 0, fontSize: 22 }}>
              {date.format("MMMM YYYY")}
            </h1>
          )}
          {mode === "week" && (
            <h1 style={{ margin: 0, fontSize: 22 }}>
              {date.startOf("week").month() === date.endOf("week").month()
                ? date.format("MMMM YYYY")
                : `${date.startOf("week").format("MMMM YYYY")} - ${date
                    .endOf("week")
                    .format("MMMM YYYY")}`}
            </h1>
          )}
          {loading && <Spin />}
        </Space>
      </Col>
      <Col flex="none">
        <Space>
          <Tooltip
            title={
              disabled
                ? t("calendar.no-building-calendars")
                : t("calendar.add-calendar")
            }
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={addEvent}
              disabled={disabled}
            >
              {t("calendar.add")}
            </Button>
          </Tooltip>
          <Select
            value={mode}
            style={{ width: 100 }}
            onSelect={(mode: "year" | "month" | "week" | "list") =>
              setMode(mode)
            }
            aria-label={t("calendar.select-period")}
          >
            <Select.Option value="list">{t("calendar.plan")}</Select.Option>
            <Select.Option value="month">{t("calendar.month")}</Select.Option>
            <Select.Option value="week">{t("calendar.week")}</Select.Option>
          </Select>
          <Tooltip title={t("calendar.sync-calendar-tooltip")}>
            <Button
              danger
              onClick={onClickSyncButton}
              icon={<SyncOutlined />}
              loading={syncing}
            >
              {t("calendar.run-sync")}
            </Button>
          </Tooltip>
        </Space>
      </Col>
    </Row>
  );
};
