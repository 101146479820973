import { PlusOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { Form, Input, message, Modal, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { useFloorPlanAssets } from "./hooks/useFloorPlanAssets";
import { useFloorPlanCreate } from "./hooks/useFloorPlanCreate";
import { FloorPlanFloorSelect } from "./FloorPlanFloorSelect";

type Props = {
  open: boolean;
  onHide: () => void;
};

type FieldType = {
  name: string;
  floor: string;
  background: {
    file: RcFile;
  };
};

export function FloorPlanListCreationModal(props: Props) {
  const t = useTranslations();
  const assets = useFloorPlanAssets();
  const [form] = Form.useForm<FieldType>();
  const floorPlanCreate = useFloorPlanCreate();

  function handleClose() {
    form.resetFields();
    props.onHide();
  }

  return (
    <Modal
      open={props.open}
      onCancel={handleClose}
      title={t("floor-plan-v2.creation-modal-title")}
      onOk={() => form.submit()}
      confirmLoading={floorPlanCreate.isMutating}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={(fields) =>
          floorPlanCreate.trigger(fields, {
            onSuccess() {
              message.success(t("floor-plan-v2.messages.created"));
              handleClose();
            },
            onError() {
              message.error(t("floor-plan-v2.messages.created-failed"));
            },
          })
        }
      >
        <Form.Item<FieldType>
          label={t("floor-plan-v2.creation-modal-floor")}
          name="floor"
        >
          <FloorPlanFloorSelect
            loading={assets.isLoading}
            defaultValue=""
            showSearch
          />
        </Form.Item>
        <Form.Item<FieldType>
          label={t("floor-plan-v2.creation-modal-name")}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<FieldType>
          label={t("floor-plan-v2.creation-modal-background")}
          name="background"
          rules={[{ required: true }]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            beforeUpload={() => false}
            maxCount={1}
          >
            <button className="bg-transparent border-0 cursor-pointer">
              <PlusOutlined />
              <div className="mt-2">{t("files.upload")}</div>
            </button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}
