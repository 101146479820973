import { Button, message, Tooltip } from "antd";
import { useUser } from "@properate/auth";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { AccessModules } from "@properate/common";
import { CSSProperties, useCallback, useState } from "react";
import { useBuildingModulesAccess } from "@/services/featureAccess/useAccessModuleFeatureAccess";
import { createAnalysis } from "@/eepApi";
import { isErrorWithMessage } from "@/utils/api";
import { SettingsTimeseriesSimple } from "@/features/analysis";

export type IProps = {
  buildingId: number;
  title: string;
  settingsTimeseriesList: SettingsTimeseriesSimple[];
  userEmail: string;
  yAxisSettings?: {
    min?: number;
    max?: number;
  } | null;
  style?: CSSProperties;
};

export function AnalysisCreateRedirectButton({
  buildingId,
  title,
  settingsTimeseriesList,
  style,
}: IProps) {
  const user = useUser();
  const navigate = useNavigate();
  const { accessModules, isLoading } = useBuildingModulesAccess();
  const [isCreateAnalysisLoading, setIsCreateAnalysisLoading] = useState(false);
  const t = useTranslations();

  const redirectToAnalysis = useCallback(async () => {
    setIsCreateAnalysisLoading(true);

    try {
      const data = await createAnalysis({
        title,
        buildingId,
        owner: user.email,
        settingsTimeseriesList,
        created: Date.now(),
        mergeUnits: true,
      });
      navigate(`../analysis/simple/${data.id}`);
    } catch (error) {
      message.error({
        key: "AnalysisCreateRedirectButton",
        content: isErrorWithMessage(error)
          ? t("analysis.error.create-analysis", { error: error.message })
          : t("analysis.error.create-analysis-no-message"),
        duration: 2,
      });
    } finally {
      setIsCreateAnalysisLoading(false);
    }
  }, [buildingId, user.email, settingsTimeseriesList, navigate, t, title]);

  const hasUserAccessToAnalysis = accessModules.includes(
    AccessModules.enum.analysis,
  );

  return isLoading
    ? null
    : hasUserAccessToAnalysis && (
        <Tooltip title={t("energy-flexing.create-analysis")}>
          <Button
            onClick={() => redirectToAnalysis()}
            disabled={isCreateAnalysisLoading}
            loading={isCreateAnalysisLoading}
            style={style}
          >
            {t("energy-flexing.create-analysis")}
          </Button>
        </Tooltip>
      );
}
