import { Avatar } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import * as React from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import useSWR from "swr";
import { useTheme } from "styled-components";
import {
  AlarmRuleTypeName,
  getSubCategoryFromExternalId,
} from "@properate/common";
import { CenteredSpinner } from "@properate/ui";
import { AlarmUpdateModal } from "@/features/alarms";
import { validateIncidentsInAlarms } from "@/eepApi";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  useFormDefaultValue,
  useFormEntry,
  useFormValue,
} from "@/pages/alarms/details/FormContext";
import { AlarmNotificationFormFields } from "@/pages/alarms/details/AlarmNotificationsPage/types";
import { AlarmSettingsFormFields } from "@/pages/alarms/details/AlarmSettingsPage/types";
import { useAlarmTypeSpecificTimeseries } from "@/pages/alarms/details/components/AlarmDetailsNotesSidebar";
import { useTimeseries } from "@/pages/alarms/details/hooks";
import {
  NEW_ALARM_VIEW_STATE_ENTRY,
  NewAlarmViewState,
} from "@/pages/alarms/details/hooks/createNewAlarmHooks";

const AlarmDetailsWarningValidations = (props: {
  showConfirmDelete: boolean;
}) => {
  const { alarmId } = useParams() as { alarmId: string };
  const currentBuilding = useCurrentBuilding();
  const theme = useTheme();
  const isNewAlarm = alarmId === "new";

  const [viewState] = useFormValue(NEW_ALARM_VIEW_STATE_ENTRY);

  const levelEntry = useFormEntry(AlarmNotificationFormFields.Level);
  const isSeverityChanged = levelEntry?.defaultValue !== levelEntry?.value;

  const [type] = useFormValue<AlarmRuleTypeName>(AlarmSettingsFormFields.Type);
  const timeseriesId = useAlarmTypeSpecificTimeseries(type || null);
  const { timeseries } = useTimeseries({ timeseriesId: timeseriesId[0] });
  const subCategory = getSubCategoryFromExternalId(timeseries);
  const defaultSubCategory = useFormDefaultValue(
    AlarmNotificationFormFields.SubCategory,
  );
  const isSubCategoryChanged = subCategory !== defaultSubCategory;

  const nameEntry = useFormEntry(AlarmSettingsFormFields.Name);
  const isNameChanged = nameEntry?.defaultValue !== nameEntry?.value;

  const [isIncidentValidationModalOpen, setIsIncidentValidationModalOpen] =
    useState<boolean>(false);

  const { data, isLoading: isLoadingValidation } = useSWR(
    ["incidentsValidation", alarmId],
    () => {
      return validateIncidentsInAlarms({
        alarmId,
        building_external_id: currentBuilding.externalId!,
      });
    },
  );

  const showIncidentsWarning = !!data?.alarmIncidents.length && isNameChanged;

  const showAlertConfigurationWarning =
    data?.hasAlertConfigurations && (isSeverityChanged || isSubCategoryChanged);

  const showIncidentsValidationModal = () => {
    if (isNewAlarm) {
      return (
        viewState === NewAlarmViewState.Notifications &&
        showAlertConfigurationWarning
      );
    }

    if (props.showConfirmDelete) {
      return data?.hasAlertConfigurations;
    }

    return showAlertConfigurationWarning || showIncidentsWarning;
  };

  return (
    <>
      {showIncidentsValidationModal() &&
        (isLoadingValidation ? (
          <div>
            <CenteredSpinner />
          </div>
        ) : (
          <Avatar
            onClick={() => setIsIncidentValidationModalOpen(true)}
            shape="square"
            style={{
              backgroundColor: theme.warningBg,
              color: theme.warningFg,
            }}
            size="default"
            icon={<WarningOutlined />}
          />
        ))}
      <AlarmUpdateModal
        isOpen={isIncidentValidationModalOpen}
        data={data}
        onClose={() => setIsIncidentValidationModalOpen(false)}
        isSeverityOrCategoryChanged={showAlertConfigurationWarning}
        isAlarmNameChanged={showIncidentsWarning}
        categoryCode={
          subCategory && subCategory.length > 0
            ? subCategory.substring(0, 1)
            : undefined
        }
        subCategoryCode={subCategory}
        isRemoveModal={props.showConfirmDelete}
      />
    </>
  );
};

export default AlarmDetailsWarningValidations;
