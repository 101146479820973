import { useState } from "react";
import { Breadcrumb, Button, Layout, Space } from "antd";
import Icon, {
  MessageOutlined,
  QuestionOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { IoMapOutline } from "@react-icons/all-files/io5/IoMapOutline";
import { ToggleSidebarButton } from "@properate/ui";
import { AccessModules } from "@properate/common";
import {
  FilterBuildings,
  useBuildingsSearch,
} from "@/context/BuildingsSearchContext";
import { useOrganizationModulesAccess } from "@/services/featureAccess/useAccessModuleFeatureAccess";
import { useUserSettings } from "@/services/userSettings";
import { SystemMessages } from "@/components/SystemMessages";
import { SearchInput } from "../components/SearchIndex";
import { ReactComponent as Logo } from "./logo.svg";
import { Help } from "./Help";
import { ReactComponent as AlarmsSvg } from "./icons/notification_important.svg";
import { ReactComponent as ChecklistIcon } from "./icons/playlist_add_check.svg";
import {
  ActiveBreadcrumb,
  HeaderLeft,
  HeaderRight,
  LogoContainer,
  StyledHeader,
  StyledLayout,
} from "./elements";

interface LayoutProps {
  id?: string;
  children: React.ReactNode;
  pageName?: string;
  headerRight?: React.ReactNode;
  showSettings?: boolean;
  hideSearchInput?: boolean;
  onToggleSettings?: (value: boolean) => unknown;
  onPressEnter?: () => void;
  showNotesButton?: boolean;
}

const FullLayout = ({
  children,
  headerRight,
  pageName,
  onToggleSettings,
  showSettings,
  onPressEnter,
  hideSearchInput,
  showNotesButton,
}: LayoutProps) => {
  const [showHelp, setShowHelp] = useState(false);
  const { search, setSearch } = useBuildingsSearch();
  const t = useTranslations();
  const { data: userSettings } = useUserSettings();

  const { accessModules } = useOrganizationModulesAccess();

  const hasAccessToTasks = !!accessModules?.includes(
    AccessModules.enum.taskManagement,
  );

  const hasAccessToIncidents =
    userSettings?.featureAccess?.["canAccessAlarmsMenu"] &&
    !!accessModules?.includes(AccessModules.enum.incidents);
  const hasAccessToAlarms = !!accessModules?.includes(
    AccessModules.enum.alarms,
  );

  return (
    <StyledLayout>
      <Layout>
        <StyledHeader>
          <HeaderLeft>
            <LogoContainer>
              <Link to="/" aria-label="Properate">
                <Logo />
              </Link>
            </LogoContainer>
            {pageName && (
              <Breadcrumb>
                <ActiveBreadcrumb>{pageName}</ActiveBreadcrumb>
              </Breadcrumb>
            )}
          </HeaderLeft>
          <div className="mr-4 w-full">
            <SystemMessages />
          </div>
          <HeaderRight>
            <Space direction="horizontal">
              {headerRight}
              {!hideSearchInput && (
                <SearchInput
                  style={{ verticalAlign: "middle", width: 400 }}
                  allowClear
                  placeholder={t("portfolio-view.placeholder.search")}
                  value={search}
                  onChange={(event) =>
                    setSearch({
                      type: FilterBuildings.filter_buildings,
                      payload: event.target.value,
                    })
                  }
                  onPressEnter={onPressEnter}
                />
              )}
              {onToggleSettings && (
                <Button
                  icon={<SettingOutlined aria-hidden />}
                  shape="circle"
                  onClick={() => onToggleSettings(!showSettings)}
                  aria-label={t("portfolio-view.toolbar.settings")}
                />
              )}
              <Link to="/" aria-label={t("portfolio-view.title")}>
                <Button
                  icon={<UnorderedListOutlined aria-hidden />}
                  shape="circle"
                  aria-label={t("portfolio-view.title")}
                />
              </Link>
              {hasAccessToTasks && (
                <Link
                  to="/org/tasks"
                  aria-label={t("portfolio-view.toolbar.tasks")}
                >
                  <Button
                    icon={<Icon component={ChecklistIcon} aria-hidden />}
                    shape="circle"
                    aria-label={t("portfolio-view.toolbar.tasks")}
                  />
                </Link>
              )}
              <Link to="/map" aria-label={t("portfolio-view.toolbar.map")}>
                <Button
                  icon={<IoMapOutline aria-hidden />}
                  shape="circle"
                  aria-label={t("portfolio-view.toolbar.map")}
                />
              </Link>
              {hasAccessToAlarms && (
                <Link
                  to="/org/alarms"
                  aria-label={t("portfolio-view.toolbar.org-events")}
                >
                  <Button
                    icon={<Icon component={AlarmsSvg} aria-hidden />}
                    shape="circle"
                    aria-label={t("portfolio-view.toolbar.org-events")}
                  />
                </Link>
              )}
              {hasAccessToIncidents && (
                <Link
                  to="/org/incidents"
                  aria-label={t("portfolio-view.toolbar.org-events")}
                >
                  <Button
                    icon={<Icon component={AlarmsSvg} aria-hidden />}
                    shape="circle"
                    aria-label={t("portfolio-view.toolbar.org-events")}
                  />
                </Link>
              )}
              <Button
                className="hint-help-question"
                icon={<QuestionOutlined aria-hidden />}
                shape="circle"
                onClick={() => setShowHelp(true)}
                aria-label={t("portfolio-view.toolbar.help-button")}
              />
              {showNotesButton && (
                <ToggleSidebarButton
                  hiddenWhenSidebarVisible
                  icon={<MessageOutlined aria-hidden />}
                  shape="circle"
                />
              )}
            </Space>
          </HeaderRight>
        </StyledHeader>
        <Layout.Content>{children}</Layout.Content>
      </Layout>
      <Help setShowHelp={setShowHelp} showHelp={showHelp} />
    </StyledLayout>
  );
};

export default FullLayout;
