import { Checkbox, DatePicker, Form } from "antd";
import { ComponentProps, useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { isAllDay } from "../utils";
import { FormFieldWithFormProps } from "./types";

const { RangePicker } = DatePicker;

type RangePickerProps = ComponentProps<typeof RangePicker>;
type RangeValue = RangePickerProps["value"];

export default function DateTimeRangePicker({
  disabled,
  form,
}: FormFieldWithFormProps) {
  const fieldName = "dateRange";
  const [allDay, setAllDay] = useState<boolean>(() => {
    const dateRange = form.getFieldValue(fieldName);
    return isAllDay({
      startTime: dateRange?.length >= 1 ? dateRange[0] : null,
      endTime: dateRange?.length >= 2 ? dateRange[1] : null,
    });
  });
  const t = useTranslations();

  function setAllDayDateRangeValues(dateRange: RangeValue) {
    if (!dateRange) return;

    const chosenDate = dateRange[0] || dateRange[1];
    if (!chosenDate) return;

    const startTime = dayjs(chosenDate).startOf("day");
    const endTime = dayjs(chosenDate).endOf("day");

    form.setFieldValue(fieldName, [startTime, endTime]);
  }
  function handleAllDayChange(event: CheckboxChangeEvent) {
    const newAllDay = event.target.checked;
    setAllDay(newAllDay);
    if (newAllDay) {
      const dateRange = form.getFieldValue(fieldName);
      setAllDayDateRangeValues(dateRange);
    }
  }

  function handleRangePickerChange(value: RangeValue) {
    if (!value) return;

    if (allDay) {
      return setAllDayDateRangeValues(value);
    }

    const [startTime, endTime] = value;
    if (startTime && endTime && startTime > endTime) return;
    form.setFieldValue(fieldName, [startTime, endTime]);
  }

  function renderAllDayCheckbox() {
    return (
      <Checkbox
        onChange={handleAllDayChange}
        checked={allDay}
        className={"mb-2"}
      >
        {t("notes.form.date-time.all-day")}
      </Checkbox>
    );
  }

  const format = allDay ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";
  const showTime = allDay ? false : { format: "HH:mm" };

  return (
    <Form.Item
      name={fieldName}
      label={
        <div className={"flex flex-row w-full justify-between"}>
          {t("notes.form.date-time.label")}
          {renderAllDayCheckbox()}
        </div>
      }
      className="[&>div>div>label]:w-full"
      rules={[
        () => ({
          validator(_, value) {
            if (!value || value.length !== 2 || !value[0] || !value[1]) {
              return Promise.reject(
                new Error(t("notes.form.date-time.message")),
              );
            }
            return Promise.resolve();
          },
        }),
      ]}
      required={false}
    >
      <RangePicker
        disabled={disabled}
        className="w-full"
        showTime={showTime}
        format={format}
        onCalendarChange={handleRangePickerChange}
      />
    </Form.Item>
  );
}
