import { PageHeader } from "@ant-design/pro-layout";
import { type ColumnsType } from "antd/lib/table";
import AutoSizer from "react-virtualized-auto-sizer";
import { Button, Input, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslations, MessageKey } from "@properate/translations";
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import {
  AlertRule,
  AlertRuleClientSide,
  getCurrentResponsibleGroup,
} from "@properate/common";
import { TableProps } from "antd/es/table/InternalTable";
import { CenteredSpinner } from "@properate/ui";
import { useUser } from "@properate/auth";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { CompactContent } from "@/components/CompactContent";
import Highlight from "@/components/Highlight";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { Sort } from "@/pages/fileType/types";
import { AlertConfigurationsLoader } from "@/features/alarms";
import { AlertsAlarmStatistics } from "./AlertsAlarmStatistics";
import { useAlertGroups, useAlertRulesData, getUrl } from "./utils";
import { BellIcon } from "./icons/BellIcon";
import { BellOffIcon } from "./icons/BellOffIcon";
import type { SorterResult } from "antd/es/table/interface";

type LoaderData = {
  search: string;
};

export function AlertRules() {
  const t = useTranslations();
  const buildingId = useCurrentBuildingId();
  const pageData = useLoaderData() as AlertConfigurationsLoader;
  const navigate = useNavigate();
  const { configs, onLoadMore, isLoading, onDelete } = useAlertRulesData();
  const groupsByIds = useAlertGroups();
  const user = useUser();

  const onEdit = (id: string, openInNewTab: boolean) => {
    if (openInNewTab) {
      window.open(`/asset/${buildingId}/alertConfiguration/${id}`);
      return;
    }
    navigate(`/asset/${buildingId}/alertConfiguration/${id}`);
  };

  const doSearch = (search: string) => {
    navigate(getUrl(pageData.id, search, pageData.sort));
  };

  const doSort = (sort: Sort) => {
    navigate(
      getUrl(pageData.id, pageData.search, {
        property: sort.property,
        order: sort.order,
      }),
    );
  };

  const onChangeTable = (
    _: any,
    __: any,
    sorter: SorterResult<AlertRuleClientSide>,
  ) => {
    if (!sorter.columnKey || !sorter.order) return;

    doSort({
      property: sorter.columnKey as string,
      order: sorter.order === "ascend" ? "asc" : "desc",
    });
  };

  const columns: ColumnsType<AlertRuleClientSide> = [
    {
      title: t("alert-rule.field-name"),
      key: "name",
      sorter: true,
      defaultSortOrder: "ascend",
      render: ({ name, highlight }) =>
        highlight?.name ? (
          <Highlight dangerouslySetInnerHTML={{ __html: highlight?.name }} />
        ) : (
          name
        ),
    },
    {
      dataIndex: "alarm_rule_selector",
      title: t("alert-rule.field-severity"),
      key: "alarm_rule_selector.severity",
      render: (alarm_rule_selector: AlertRule["alarm_rule_selector"]) =>
        !!alarm_rule_selector?.severity?.length &&
        alarm_rule_selector?.severity
          .map((severity) => t(`alarms.severity.${severity}` as MessageKey))
          .join(", "),
    },
    {
      dataIndex: "alarm_rule_selector",
      title: t("alert-rule.field-category"),
      key: "alarm_rule_selector.category",
      render: (alarm_rule_selector: AlertRule["alarm_rule_selector"]) =>
        !!alarm_rule_selector?.category?.length &&
        alarm_rule_selector?.category
          .map((category) =>
            t(`tfm.system.buildingPartNumber.${category}` as MessageKey),
          )
          .join(", "),
    },
    {
      title: t("alert-rule.field-mute"),
      key: "muted",
      render: (rule) => (rule.muted ? <BellOffIcon /> : <BellIcon />),
    },
    {
      title: t("alert-rule.column-currently-responsible-group"),
      key: "currentResponsibleGroup",
      align: "center",
      render: (alertConfiguration: AlertRuleClientSide) => {
        if (groupsByIds.isLoadingGroups) {
          return <CenteredSpinner />;
        }
        const currentGroupId =
          getCurrentResponsibleGroup(alertConfiguration)?.group_id;

        return currentGroupId && groupsByIds.groups[currentGroupId]
          ? groupsByIds.groups[currentGroupId].name
          : "";
      },
    },
    ...(user.isAdmin
      ? [
          {
            key: "id",
            width: 100,
            render: (row: AlertRuleClientSide) => (
              <div className="flex gap-2 items-center justify-center">
                <Popconfirm
                  title={t("alert-rule.delete-confirm")}
                  onConfirm={(event) => {
                    event?.stopPropagation();
                    onDelete(row.id);
                  }}
                >
                  <Button
                    onClick={(event) => event.stopPropagation()}
                    aria-label={t("alert-groups.table.delete")}
                    icon={<DeleteOutlined />}
                    danger
                  />
                </Popconfirm>
                <Button
                  onClick={(event) =>
                    onEdit(row.id, event.ctrlKey || event.metaKey)
                  }
                  icon={<EditOutlined />}
                  aria-label={t("alert-groups.table.edit")}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className="h-full w-full flex flex-col">
      <PageHeader
        title={t("alert-rule.title")}
        extra={[
          <Input.Search
            key="search"
            defaultValue={pageData.search}
            allowClear
            style={{ width: 300 }}
            onChange={(event) => doSearch(event.target.value)}
            aria-label={t("ui.search")}
            placeholder={t("ui.search")}
          />,
          ...(user.isAdmin
            ? [
                <Link
                  key="new"
                  to={`/asset/${buildingId}/alertConfiguration/create`}
                >
                  <Button>{t("ui.create")}</Button>
                </Link>,
              ]
            : []),
        ]}
      />
      <CompactContent className="h-full flex-1 mt-4">
        <AlertsAlarmStatistics />
        <div className="h-full flex">
          <AutoSizer className="flex-1">
            {({ height }) => (
              <TableInfiniteScroll<AlertRuleClientSide>
                columns={columns}
                dataSource={configs}
                height={height - 64}
                onReachedEndOfPage={onLoadMore}
                rowKey="id"
                loading={isLoading}
                onChange={
                  onChangeTable as TableProps<AlertRuleClientSide>["onChange"]
                }
                onRow={(record) => ({
                  onClick: (event) =>
                    onEdit(record.id, event.ctrlKey || event.metaKey),
                })}
              />
            )}
          </AutoSizer>
        </div>
      </CompactContent>
    </div>
  );
}

AlertRules.loader = function loader(args: LoaderFunctionArgs): LoaderData {
  const urlParams = new URL(args.request.url).searchParams;
  const search = urlParams.get("search") ?? "";

  return {
    search,
  };
};
