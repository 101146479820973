import {
  App,
  Button,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Tooltip,
} from "antd";
import { useTranslations } from "@properate/translations";
import { ChangeEvent, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CalculationFlow } from "@/pages/calculationFlow/types";
import {
  createOrUpdateCalculationFlow,
  createOrUpdateManualTimeseries,
} from "@/eepApi";
import { BaseVirtualSetpoint } from "@/components/MeterSelectionModal/types";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { priorityToString } from "../utils";
import { PotentialGraph } from "./PotentialGraph";

interface Props {
  flow: CalculationFlow;
  onClose: () => void;
  onChange: () => void;
}

export const EditMeasureModal = ({ flow, onClose, onChange }: Props) => {
  const t = useTranslations();
  const currentBuilding = useCurrentBuilding();
  const { notification } = App.useApp();
  const [saving, setSaving] = useState(false);
  const [updatedFlow, setUpdatedFlow] = useState<CalculationFlow>(flow);

  const updatedPotential = updatedFlow.metadata!.potential;

  const handleSave = async () => {
    setSaving(true);
    try {
      // For new flex automations, create a virtual setpoint as activation input
      if (flow.id === null && currentBuilding.externalId !== undefined) {
        const newVirtualSetpoint: BaseVirtualSetpoint = {
          category: "virtual_setpoint",
          description: "Activation input",
          max_value: 100,
          min_value: 0,
          externalId: null,
          building: currentBuilding.externalId,
          subBuilding: "Common",
          system: "000.000",
          unit: "%",
        };
        const virtualSetpoint =
          await createOrUpdateManualTimeseries(newVirtualSetpoint);
        // Add the virtual setpoint externalId to the flow
        updatedFlow.calculation_flow.nodes[1].data.externalId =
          virtualSetpoint.externalId;
        updatedFlow.metadata!.activationExternalId =
          virtualSetpoint.externalId.externalId;
      }
      // Save the updated flow
      await createOrUpdateCalculationFlow(updatedFlow);
      setSaving(false);
      onChange();
      notification.success({
        message: t("energy-flexing.measures.saved"),
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t("energy-flexing.error-while-saving-measure"),
      });
    }
  };

  function handlePriorityChange(value: string): void {
    setUpdatedFlow({
      ...updatedFlow,
      metadata: { ...updatedFlow.metadata!, priority: parseInt(value) },
    });
  }

  function handleNameChange(event: ChangeEvent<HTMLInputElement>): void {
    setUpdatedFlow({ ...updatedFlow, name: event.target.value });
  }

  function handlePotentialChange(maxKW: number | null): void {
    if (maxKW != null && maxKW > 0)
      setUpdatedFlow({
        ...updatedFlow,
        metadata: {
          ...updatedFlow.metadata!,
          potential: {
            ...updatedPotential,
            maxKW: Math.sign(updatedPotential.maxKW) * Math.abs(maxKW),
          },
        },
      });
  }

  function handleRampUpChange(rampUpSec: number | null): void {
    if (rampUpSec != null && rampUpSec >= 0)
      setUpdatedFlow({
        ...updatedFlow,
        metadata: {
          ...updatedFlow.metadata!,
          potential: { ...updatedPotential, rampUpSec },
        },
      });
  }

  function handleBufferChange(bufferSec: number | null): void {
    if (bufferSec != null && bufferSec >= 0)
      setUpdatedFlow({
        ...updatedFlow,
        metadata: {
          ...updatedFlow.metadata!,
          potential: { ...updatedPotential, bufferSec },
        },
      });
  }

  function handleMaxDurationChange(maxDurationSec: number | null): void {
    if (maxDurationSec != null && maxDurationSec > 0)
      setUpdatedFlow({
        ...updatedFlow,
        metadata: {
          ...updatedFlow.metadata!,
          potential: { ...updatedPotential, maxDurationSec },
        },
      });
  }

  function handleRampDownChange(rampDownSec: number | null): void {
    if (rampDownSec !== null && rampDownSec >= 0)
      setUpdatedFlow({
        ...updatedFlow,
        metadata: {
          ...updatedFlow.metadata!,
          potential: { ...updatedPotential, rampDownSec },
        },
      });
  }

  function handleRegulationDirectionChange(): void {
    setUpdatedFlow({
      ...updatedFlow,
      metadata: {
        ...updatedFlow.metadata!,
        potential: { ...updatedPotential, maxKW: -updatedPotential.maxKW },
      },
    });
  }

  return (
    <Modal
      title={
        flow.id
          ? t("calculation-flow.update-flex-automation")
          : t("calculation-flow.new-flex-automation")
      }
      open
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t("calculation-flow.table.cancel")}
        </Button>,
        <Button key="add" type="primary" loading={saving} onClick={handleSave}>
          {t("energy-flexing.measures.save")}
        </Button>,
      ]}
    >
      <Space direction="vertical">
        <div>
          {"Name: "}
          <Input
            value={updatedFlow.name}
            style={{ width: 240 }}
            onChange={handleNameChange}
          />
        </div>
        <div>
          {`${t("energy-flexing.measures.severity")}: `}
          <Select
            defaultValue="50"
            onChange={handlePriorityChange}
            options={[...Array(100).keys()].map((i) => ({
              value: (i + 1).toString(),
              label: `${(i + 1).toString()} (${priorityToString(i + 1, t)})`,
            }))} // 1-100
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {`${t("energy-flexing.measures.flexingPotentialKw")}: `}
          <InputNumber
            value={updatedPotential.maxKW}
            onChange={handlePotentialChange}
            formatter={(value) => `${Math.abs(value || 0)} kW`}
            parser={(value) => value?.slice(0, -3) as unknown as number}
            style={{ marginLeft: 8 }}
          />
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            onChange={handleRegulationDirectionChange}
            options={[
              { label: "økning", value: "up" },
              { label: "reduksjon", value: "down" },
            ]}
            defaultValue="down"
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {`${t("energy-flexing.measures.ramp-up-time")}: `}
          <InputNumber
            value={updatedPotential.rampUpSec}
            onChange={handleRampUpChange}
            formatter={(value) => `${value} s`}
            parser={(value) => value?.slice(0, -2) as unknown as number}
          />
          <Tooltip title={t("energy-flexing.measures.ramp-up-time-tooltip")}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {`${t("energy-flexing.measures.buffer-time")}: `}
          <InputNumber
            value={updatedPotential.bufferSec}
            onChange={handleBufferChange}
            formatter={(value) => `${value} s`}
            parser={(value) => value?.slice(0, -2) as unknown as number}
          />
          <Tooltip title={t("energy-flexing.measures.buffer-time-tooltip")}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {`${t("energy-flexing.measures.max-duration")}: `}
          <InputNumber
            value={updatedPotential.maxDurationSec}
            onChange={handleMaxDurationChange}
            formatter={(value) => `${value} s`}
            parser={(value) => value?.slice(0, -2) as unknown as number}
          />
          <Tooltip title={t("energy-flexing.measures.max-duration-tooltip")}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {`${t("energy-flexing.measures.recovery-time")}: `}
          <InputNumber
            value={updatedPotential.rampDownSec}
            onChange={handleRampDownChange}
            formatter={(value) => `${value} s`}
            parser={(value) => value?.slice(0, -2) as unknown as number}
          />
          <Tooltip title={t("energy-flexing.measures.recovery-time-tooltip")}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <PotentialGraph potential={updatedPotential} />
      </Space>
    </Modal>
  );
};
