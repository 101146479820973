import { useTranslations } from "@properate/translations";
import { Input, Select } from "antd";
import { AlarmRuleTypeName } from "@properate/common";
import { useEffect } from "react";
import { useIsCreateNew } from "../hooks/createNewAlarmHooks";
import {
  FormContextActionType,
  FormContextItem,
  useFormContext,
  useFormValue,
} from "../FormContext";
import { alarmRuleTypeList, alarmRuleTypes } from "../AlarmRuleTypes";
import { useAvailableAlarmRuleTypes } from "../hooks/useAvailableAlarmRuleTypes";
import { AlarmSettingsFormFields } from "./types";

const tBase = "alarm-details.settings-page.";

function TypeSelect() {
  const t = useTranslations();
  const [selectedAlarmRuleTypeName] = useFormValue<AlarmRuleTypeName>(
    AlarmSettingsFormFields.Type,
  );

  const isCreateNew = useIsCreateNew();

  const selectedAlarmRuleType = alarmRuleTypes[selectedAlarmRuleTypeName];
  const { availableAlarmRuleTypes } = useAvailableAlarmRuleTypes();

  const typeOptions = alarmRuleTypeList
    .filter(
      (alarmRuleType) =>
        selectedAlarmRuleType?.lockedType ?? !alarmRuleType.lockedType,
    )
    .map((alarmRuleType) => {
      return {
        value: alarmRuleType.name,
        label: t(alarmRuleType.labelTranslationKey),
      };
    });

  function optionDisabled(value: AlarmRuleTypeName) {
    return !availableAlarmRuleTypes[value]?.available;
  }

  return (
    <FormContextItem
      labelKey={`${tBase}type-label`}
      id={AlarmSettingsFormFields.Type}
      antdInput
      disabled={selectedAlarmRuleType?.lockedType || !isCreateNew}
    >
      <Select>
        {typeOptions.map((option) => (
          <Select.Option
            value={option.value}
            key={option.value}
            disabled={optionDisabled(option.value)}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </FormContextItem>
  );
}

export function CommonAlarmSettingsFields() {
  const { dispatch } = useFormContext();
  const [alarmRuleTypeName] = useFormValue<AlarmRuleTypeName | undefined>(
    AlarmSettingsFormFields.Type,
  );

  useEffect(
    () => {
      if (alarmRuleTypeName === undefined) {
        return;
      }
      dispatch({
        type: FormContextActionType.changeTypeAndResetAllFields,
        newType: alarmRuleTypeName,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alarmRuleTypeName],
  );

  return (
    <>
      <FormContextItem
        labelKey={`${tBase}name-label`}
        id={AlarmSettingsFormFields.Name}
      >
        <Input />
      </FormContextItem>
      <FormContextItem
        labelKey={`${tBase}instruction-label`}
        id={AlarmSettingsFormFields.Instruction}
      >
        <Input.TextArea />
      </FormContextItem>
      <TypeSelect />
    </>
  );
}
