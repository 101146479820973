import {
  AlarmRuleTypeName,
  type EffectGuardAlarmRule,
  EffectGuardTenantType,
  getSystemCodeFromExternalId,
  OffsetDirection,
  PartialAlarmRule,
} from "@properate/common";
import useSWR from "swr";
import { CenteredSpinner, RichTextFormat } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { Segmented, Select } from "antd";
import { useEffect, useState } from "react";
import {
  TimeseriesThresholdDefinition,
  TimeseriesThresholdFormFields,
} from "@/pages/alarms/details/AlarmRuleTypes/TimeseriesThreshold";
import { getSubmitValueEntry } from "@/pages/alarms/details/FormContext/utils";
import {
  getConditionOffsetAlarmRuleFields,
  getSelectOffsetFieldDefinitions,
  OffsetMode,
  SelectOffset,
  SelectOffsetType,
} from "@/pages/alarms/details/AlarmRuleTypes/common/SelectOffset";
import {
  numberValidator,
  selectValidator,
} from "@/pages/alarms/details/FormContext/validators";
import {
  FormContextItem,
  useFormValue,
} from "@/pages/alarms/details/FormContext";
import { getTimeseriesWithAssetLabels } from "@/utils/helpers";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { Box, BoxVariant } from "@/pages/alarms/details/components/InfoBox";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useTimeseries } from "@/pages/alarms/details/hooks";
import { requiredSelect, tKey } from "./common/utils";
import { AlarmRuleType, TypeDescriptionProps } from "./index";

export enum EffectGuardFormFields {
  TenantType = "TenantType",
}

function EffectGuardMessage({
  disabledFormatters,
  formatOverrides,
  hasDuration,
}: TypeDescriptionProps) {
  const t = useTranslations();
  const [tenantType] = useFormValue<string>(EffectGuardFormFields.TenantType);
  const [timeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const { id: buildingId } = useCurrentBuilding();
  const { timeseries: timeseriesList } = useTimeseriesWithAssetLabels({
    labels: ["oe_common_main_meter"],
    buildingId,
  });

  return (
    <RichTextFormat
      message={
        hasDuration
          ? "alarm-details.alarm-types.timeseries-threshold.effect-guard.type-description-with-duration"
          : "alarm-details.alarm-types.timeseries-threshold.effect-guard.type-description"
      }
      disabledFormatters={disabledFormatters}
      formatOverrides={{
        "tenant-type": () => {
          if (tenantType === EffectGuardTenantType.SingleTenant) {
            if (timeseriesList) {
              const timeseries = timeseriesList.find(
                (ts) => ts.id === timeseriesId,
              );

              if (timeseries?.metadata?.organization) {
                return timeseries.metadata.organization;
              }
            }

            return t(
              "alarm-details.alarm-types.timeseries-threshold.effect-guard.single-tenant-label",
            );
          }

          return t(
            "alarm-details.alarm-types.timeseries-threshold.effect-guard.tenant-type-choices.EntireBuilding",
          );
        },
        ...formatOverrides,
      }}
    />
  );
}

export const TimeseriesThresholdEffectGuardDefinition: AlarmRuleType = {
  ...TimeseriesThresholdDefinition,
  name: AlarmRuleTypeName.TimeseriesThresholdEffectGuard,
  TypeDescription: EffectGuardMessage,
  labelTranslationKey: tKey("timeseries-threshold.effect-guard.type-label"),
  getFormFields: (alarmRule) => {
    return {
      [EffectGuardFormFields.TenantType]: {
        defaultValue:
          (alarmRule as EffectGuardAlarmRule)?.condition?.type_specific
            ?.tenant_type ?? EffectGuardTenantType.EntireBuilding,
        getValidator: (t) =>
          selectValidator(
            t(requiredSelect, {
              fieldName: t(
                tKey(`timeseries-threshold.effect-guard.tenant-type`),
              ).toLowerCase(),
            }),
            [
              EffectGuardTenantType.EntireBuilding,
              EffectGuardTenantType.SingleTenant,
            ],
          ),
      },
      [TimeseriesThresholdFormFields.Timeseries]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.base_timeseries_id ?? undefined,
        getValidator: (t) =>
          numberValidator(
            t(requiredSelect, {
              fieldName: t(
                tKey(`timeseries-threshold.timeseries`),
              ).toLowerCase(),
            }),
          ),
      },
      ...getSelectOffsetFieldDefinitions({
        alarmRule,
        mode: OffsetMode.Threshold,
      }),
      [SelectOffsetType.Direction]: {
        defaultValue: OffsetDirection.BaseGTCompare,
      },
    };
  },
  getAlarmRuleFields: ({ entries }): PartialAlarmRule => {
    return {
      condition: {
        ...getConditionOffsetAlarmRuleFields({ entries }),
        type_specific: {
          offset: getSubmitValueEntry<number>(entries, SelectOffsetType.Value),
          direction: OffsetDirection.BaseGTCompare,
          base_timeseries_id: getSubmitValueEntry<number>(
            entries,
            TimeseriesThresholdFormFields.Timeseries,
          ),
          tenant_type: getSubmitValueEntry<EffectGuardTenantType>(
            entries,
            EffectGuardFormFields.TenantType,
          ),
        },
      },
    };
  },
  formComponent: <EffectGuardForm />,
};

function useTimeseriesWithAssetLabels({
  labels,
  buildingId,
}: {
  labels: string[];
  buildingId: number;
}) {
  const { client } = useCogniteClient();
  const { data, ...rest } = useSWR(
    [labels, buildingId],
    ([labels, buildingId]) => {
      if (buildingId === undefined || buildingId === null) {
        return null;
      }
      return getTimeseriesWithAssetLabels(client, buildingId, labels);
    },
  );

  const { assets, timeseries } = data ?? {};
  return { assets, timeseries, ...rest };
}

function EffectGuardForm() {
  const [tenantType] = useFormValue<EffectGuardTenantType>(
    EffectGuardFormFields.TenantType,
  );
  const [timeseriesId, setTimeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { timeseries: selectedTimeseries } = useTimeseries({ timeseriesId });
  const { id: buildingId } = useCurrentBuilding();
  const t = useTranslations();
  const {
    timeseries: timeseriesList,
    isLoading,
    error,
  } = useTimeseriesWithAssetLabels({
    labels: ["oe_common_main_meter"],
    buildingId,
  });

  useEffect(() => {
    if (timeseriesId) {
      return;
    }
    const entireBuildingTimeseries = (timeseriesList ?? []).find(
      (ts) =>
        ts.externalId &&
        getSystemCodeFromExternalId(ts.externalId) === "100.001",
    );
    if (entireBuildingTimeseries) {
      setTimeseriesId(entireBuildingTimeseries.id);
    }
  }, [timeseriesList, timeseriesId, setTimeseriesId]);

  if (error) {
    return (
      <Box variant={BoxVariant.Error}>
        <p>{t("alarm-details.common.error-messages.timeseries-loading")}</p>
      </Box>
    );
  }

  if (errorMessage) {
    return <Box variant={BoxVariant.Error}>{errorMessage}</Box>;
  }

  if (isLoading) {
    return <CenteredSpinner />;
  }

  function setTimeseriesIdIfEntireBuildingSelected(newTenantType: unknown) {
    if (newTenantType !== EffectGuardTenantType.EntireBuilding) {
      return newTenantType;
    }
    const entireBuildingTimeseries = (timeseriesList ?? []).find(
      (ts) =>
        ts.externalId &&
        getSystemCodeFromExternalId(ts.externalId) === "100.001",
    );
    if (!entireBuildingTimeseries) {
      setErrorMessage(
        t(
          tKey(
            `timeseries-threshold.effect-guard.error.missing-entire-building`,
          ),
        ),
      );
      return newTenantType;
    }
    if (timeseriesId === entireBuildingTimeseries?.id) {
      return newTenantType;
    }
    setTimeseriesId(entireBuildingTimeseries?.id);
    return newTenantType;
  }

  function renderSingleTenantToggle() {
    return (
      <FormContextItem
        labelKey={tKey(`timeseries-threshold.effect-guard.tenant-type`)}
        id={EffectGuardFormFields.TenantType}
        antdInput
        labelSrOnly
        changeCallback={setTimeseriesIdIfEntireBuildingSelected}
      >
        <Segmented
          block
          options={[
            EffectGuardTenantType.EntireBuilding,
            EffectGuardTenantType.SingleTenant,
          ].map((level) => ({
            // @ts-ignore
            label: t(
              tKey(
                `timeseries-threshold.effect-guard.tenant-type-choices.${level}`,
              ),
            ),
            value: level,
          }))}
        />
      </FormContextItem>
    );
  }

  function renderSelectTimeseries() {
    if (tenantType === EffectGuardTenantType.EntireBuilding) {
      return null;
    }

    const tenants = (timeseriesList ?? []).map((ts) => {
      return {
        name: ts.metadata?.organization ?? ts.externalId,
        id: ts.id,
      };
    });

    return (
      <FormContextItem
        id={TimeseriesThresholdFormFields.Timeseries}
        labelKey={tKey(`timeseries-threshold.effect-guard.tenant-label`)}
        antdInput
        inputProps={{
          showSearch: true,
          options: tenants,
          optionFilterProp: "name",
          fieldNames: { label: "name", value: "id" },
        }}
      >
        <Select />
      </FormContextItem>
    );
  }

  return (
    <>
      <h2 className="m-0">
        {t(tKey("timeseries-threshold.effect-guard.timeseries-header"))}
      </h2>
      {renderSingleTenantToggle()}
      {renderSelectTimeseries()}
      {selectedTimeseries && (
        <SelectOffset unit={selectedTimeseries.unit ?? ""} hideDirection />
      )}
    </>
  );
}
