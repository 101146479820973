import {
  useTranslations,
  MessageKey,
  TranslationFunction,
} from "@properate/translations";
import { ReactNode, useEffect } from "react";

type LabelOrLabelKey =
  | { label: string; labelKey?: never }
  | { label?: never; labelKey: MessageKey };

function getLabel(obj: LabelOrLabelKey, t: TranslationFunction): string {
  if (obj.labelKey) {
    // @ts-ignore
    return t(obj.labelKey);
  }
  return obj.label as string;
}

export type ResponsiveTableColumn<T extends Record<string, any>> = {
  valueLookup: keyof T;
  renderValue?: (value: T) => ReactNode;
} & LabelOrLabelKey;

export type ResponsiveTableProps<T extends Record<string, any>> = Readonly<{
  id?: string;
  tableColumns: ResponsiveTableColumn<T>[];
  keyLookup: keyof T;
  values: T[];
  onSelect?: (value: T) => void;
  selected?: T | null;
  highlighted?: T | null;
  isSelected?: (value: T) => boolean;
  scrollToSelected?: boolean;
}>;

function ResponsiveTableHeader<T extends Record<string, any>>({
  tableColumns,
}: Readonly<Pick<ResponsiveTableProps<T>, "tableColumns">>) {
  const t = useTranslations();
  return (
    <thead>
      <tr>
        {tableColumns.map((tableRow, index) => {
          return (
            <th
              className="p-1 border-0 border-b border-muted border-solid bg-muted dark:bg-background sticky top-0 [&:not(:last-child)]:border-r first:rounded-tl last:rounded-tr text-xs z-50"
              key={`th_${index}`}
            >
              {getLabel(tableRow, t)}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

type ResponsiveTableRowProps<T extends Record<string, any>> = Readonly<
  {
    value: T;
    tableColumns: ResponsiveTableColumn<T>[];
    selected?: boolean | null;
    highlighted?: boolean | null;
    index: number;
    tableId?: string;
  } & Pick<ResponsiveTableProps<T>, "onSelect" | "scrollToSelected">
>;

function ResponsiveTableRow<T extends Record<string, any>>({
  value,
  tableColumns,
  selected,
  onSelect,
  highlighted,
  index,
  tableId,
  scrollToSelected,
}: ResponsiveTableRowProps<T>) {
  const rowId = `${tableId ?? "responsivetable"}__row${index}`;

  useEffect(() => {
    if (selected && scrollToSelected) {
      document.getElementById(rowId)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderValue(tableColumn: ResponsiveTableColumn<T>) {
    if (tableColumn.renderValue) {
      return tableColumn.renderValue(value);
    }
    return value[tableColumn.valueLookup];
  }

  function handleOnClick() {
    onSelect?.(value);
  }

  return (
    <tr
      className={`${
        selected
          ? "bg-green-500/25 border-1 border-solid border-green-500/50 dark:bg-sky-950"
          : ""
      } ${
        highlighted
          ? "bg-yellow-400/25 border-y-2 border-x-0 border-yellow-400/50 border-solid dark:bg-yellow-400/25"
          : ""
      } ${
        onSelect ? "cursor-pointer hover:bg-muted dark:hover:bg-background" : ""
      }`}
      id={rowId}
      onClick={handleOnClick}
    >
      {tableColumns.map((tableColumn, index) => (
        <td
          className="p-2 border-0 border-b border-muted border-solid [&:not(:last-child)]:border-r text-xs"
          key={`td_${index}`}
        >
          {renderValue(tableColumn)}
        </td>
      ))}
    </tr>
  );
}

export function ResponsiveTable<T extends Record<string, any>>({
  tableColumns,
  values,
  keyLookup,
  onSelect,
  selected,
  highlighted,
  id,
  scrollToSelected,
}: ResponsiveTableProps<T>) {
  return (
    <table
      className={
        "table-auto border-collapse w-full h-auto max-h-full box-border"
      }
      id={id}
    >
      <ResponsiveTableHeader tableColumns={tableColumns} />
      <tbody className="max-h-full h-auto">
        {values.map((value, index) => (
          <ResponsiveTableRow
            value={value}
            key={value[keyLookup]}
            tableColumns={tableColumns}
            selected={selected && selected[keyLookup] === value[keyLookup]}
            highlighted={
              highlighted && highlighted[keyLookup] === value[keyLookup]
            }
            onSelect={onSelect}
            index={index}
            tableId={id}
            scrollToSelected={scrollToSelected}
          />
        ))}
      </tbody>
    </table>
  );
}
