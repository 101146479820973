import { Button, TableColumnsType } from "antd";
import { SettingOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { sortString } from "@properate/ui";
import { useMemo } from "react";
import {
  deleteTimeseries,
  getCurrentSensors,
  getSensors,
  setTimeseries,
} from "@/components/TechnicalSchema/utils";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { TableWithoutDefaultSort } from "../TableWithoutDefaultSort/TableWithoutDefaultSort";
import {
  KpiCommonWrapperContainer,
  KpiComponentCompactViewContainer,
  TitleContainer,
} from "./elements";
import { SortableKPIList } from "./SortableKPIList";
import type { SchemaTableProps } from "@/components/TechnicalSchema/types";

export const SchemaCompactViewTable = ({
  data,
  type,
  setShowSelectKpisModal,
  selectedBuilding,
  onChangeSelectedSensors,
  height,
  search,
}: SchemaTableProps) => {
  const navigate = useNavigate();
  const t = useTranslations();
  const schemaName =
    type === "technicalSchema"
      ? t("floor-plan.table-column-title.technical-schema-name")
      : t("floor-plan.table-column-title.floor-plan-schema-name");

  function handleSortEnd(
    snapshotId: string,
    oldIndex: number,
    newIndex: number,
  ) {
    const { selectedSensors } = data.find(
      (schema: any) => schema.snapshotId === snapshotId,
    );

    onChangeSelectedSensors(
      snapshotId,
      arrayMoveImmutable(selectedSensors, oldIndex, newIndex),
    );
  }

  const columns: TableColumnsType<any> = [
    {
      title: t("floor-plan.table-column-title.building"),
      key: "subBuilding",
      width: 90,
      sorter: (
        { subBuilding: subBuildingOne },
        { subBuilding: subBuildingTwo },
      ) => sortString(subBuildingOne, subBuildingTwo),
      render: (_, { subBuilding = "" }) => {
        return (
          <TitleContainer>
            <SearchHighlighter search={search} text={subBuilding} />
          </TitleContainer>
        );
      },
    },
    {
      title: t("floor-plan.table-column-title.system"),
      key: "system",
      width: 90,
      defaultSortOrder: "ascend",
      sorter: ({ system: systemOne }, { system: systemTwo }) =>
        sortString(systemOne, systemTwo),
      render: (_, { system = "" }) => {
        return (
          <TitleContainer>
            <SearchHighlighter search={search} text={system} />
          </TitleContainer>
        );
      },
    },
    {
      title: t("floor-plan.table-column-title.name"),
      key: "name",
      width: 300,
      sorter: ({ name: nameOne }, { name: nameTwo }) =>
        sortString(nameOne, nameTwo),
      render: (_, { name = "" }) => {
        return (
          <TitleContainer>
            <SearchHighlighter search={search} text={name} />
          </TitleContainer>
        );
      },
    },
    {
      title: t("floor-plan.table-column-title.key-figures-and-alarms"),
      key: "kpi",
      render: (_, { snapshotId, sensors, selectedSensors }) => {
        const sensorList = getSensors(sensors);
        const currentSensors = getCurrentSensors(selectedSensors, sensors);

        return (
          <KpiComponentCompactViewContainer>
            <KpiCommonWrapperContainer>
              <SortableKPIList
                sensors={currentSensors.filter((sensor) => sensor.sensor.id)}
                onChangeTimeseriesInfo={async (sensor, ts) => {
                  await setTimeseries(snapshotId, sensor.id, sensors, ts, type);
                }}
                onDeleteTimeseries={async (sensor) => {
                  await deleteTimeseries(
                    snapshotId,
                    sensors,
                    sensor.id,
                    sensor.sensor.id,
                    selectedSensors || [],
                    type,
                  );
                }}
                onSortEnd={({ oldIndex, newIndex }) =>
                  handleSortEnd(snapshotId, oldIndex, newIndex)
                }
                axis="x"
              />
            </KpiCommonWrapperContainer>
            <KpiCommonWrapperContainer>
              <SortableKPIList
                sensors={sensorList
                  .filter((sensor) => sensor.sensor.id)
                  .filter(
                    (sensor) =>
                      !currentSensors.some(
                        (currentSensor: any) =>
                          sensor.sensor.id === currentSensor.sensor.id,
                      ),
                  )}
                onChangeTimeseriesInfo={async (sensor, ts) => {
                  await setTimeseries(
                    snapshotId,
                    sensor.id,
                    sensors[sensor.id],
                    ts,
                    type,
                  );
                }}
                onDeleteTimeseries={async (sensor) => {
                  await deleteTimeseries(
                    snapshotId,
                    sensors,
                    sensor.id,
                    sensor.sensor.id,
                    selectedSensors,
                    type,
                  );
                }}
                onSortEnd={({ oldIndex, newIndex }) =>
                  handleSortEnd(snapshotId, oldIndex, newIndex)
                }
                axis="x"
                onlyAlarm
              />
            </KpiCommonWrapperContainer>
          </KpiComponentCompactViewContainer>
        );
      },
    },
    {
      key: "modalTimeseries",
      width: 48,
      render: (_, { snapshotId, sensors }) => {
        return getSensors(sensors).length > 0 ? (
          <Button
            type="link"
            title="Innstillinger"
            icon={<SettingOutlined />}
            onClick={() => {
              setShowSelectKpisModal(snapshotId);
            }}
          />
        ) : null;
      },
    },
    {
      key: "schema",
      width: 160,
      render: (_, { rootAssetId, snapshotId }) => {
        return (
          <Button
            title={schemaName}
            onClick={() =>
              navigate(`/asset/${rootAssetId}/${type}/${snapshotId}/view`)
            }
          >
            {schemaName}
            <ArrowRightOutlined />
          </Button>
        );
      },
    },
  ];

  const dataSource = useMemo(
    () =>
      data.filter(
        ({
          subBuilding,
          system,
          name,
        }: {
          subBuilding: string;
          system?: string;
          name: string;
        }) =>
          (selectedBuilding === "all" || subBuilding === selectedBuilding) &&
          (!search ||
            subBuilding?.toLowerCase().includes(search.toLowerCase()) ||
            (system && system.toLowerCase().includes(search.toLowerCase())) ||
            name?.toLowerCase().includes(search.toLowerCase())),
      ),
    [data, search, selectedBuilding],
  );

  return (
    data && (
      <TableWithoutDefaultSort
        size="small"
        columns={columns}
        pagination={false}
        dataSource={dataSource}
        scroll={{
          y: height,
        }}
        rowKey="snapshotId"
      />
    )
  );
};
