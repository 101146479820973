import { useTranslations } from "@properate/translations";
import { useCallback } from "react";

export function useTranslateSystem() {
  const t = useTranslations();

  return useCallback(
    (system: string, fallback = "") => {
      const systemNumber = Number.parseInt(
        system.substring(
          0,
          system.indexOf(".") > 0 ? system.indexOf(".") : system.length,
        ),
      );

      const key = `tfm.system.buildingPartNumber.${systemNumber}` as any;

      return t.has(key) ? t(key) : fallback;
    },
    [t],
  );
}
