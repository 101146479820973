import { useState, useEffect } from "react";
import { sortString } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { InputNumber, Spin, Switch, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Datapoints } from "@cognite/sdk";
import dayjs from "@properate/dayjs";
import { TimeSpan } from "@properate/common";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useWindowSize } from "@/hooks/useWindowSize";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { CalculationFlow } from "@/pages/calculationFlow/types";
import {
  createOrUpdateCalculationFlow,
  runCalculationFlow,
  updateSetPoint,
} from "@/eepApi";
import { MILLISECONDS_IN_DAY } from "@/pages/calculationFlow/constants";
import { CompactContent } from "../../../components/CompactContent";
import { priorityToString } from "../utils";
import { ActionsMenu } from "./ActionsMenu";
import { MeasuresTableHeader } from "./MeasuresTableHeader";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

interface IProps {
  flows: CalculationFlow[];
  activations: Datapoints[];
  loading: boolean;
  onReloadFlows: () => Promise<void>;
  onReloadActivations: () => Promise<void>;
}

export function MeasuresTable({
  flows,
  activations,
  loading,
  onReloadFlows,
  onReloadActivations,
}: IProps) {
  const t = useTranslations();

  const { height: windowHeight } = useWindowSize();
  const [searchWords, setSearchWords] = useState<string[]>([]);
  const [filteredFlow, setFilteredFlows] = useState<CalculationFlow[]>(flows);
  const [isChangingStatusFlowId, setIsChangingStatusFlowId] = useState<
    string | null
  >(null);
  const [isChangingStatusMessage, setIsChangingStatusMessage] =
    useState<string>("");

  useEffect(() => {
    if (searchWords.length === 0) setFilteredFlows(flows);
    else {
      setFilteredFlows(
        flows.filter((flow) =>
          searchWords.some((query) =>
            flow.name.toLowerCase().includes(query.toLowerCase()),
          ),
        ),
      );
    }
  }, [flows, searchWords]);

  const getActivation = (flow: CalculationFlow) => {
    const dps = activations.find(
      (activation) =>
        activation.externalId === flow.metadata?.activationExternalId,
    );
    return dps && dps.datapoints.length > 0
      ? (dps.datapoints[0].value as number)
      : 0;
  };

  const runFlexAutomation = async (flowId: string) => {
    const timeSpan: TimeSpan = [
      dayjs().valueOf() - 7 * MILLISECONDS_IN_DAY,
      dayjs().valueOf(),
    ];
    await runCalculationFlow(flowId, timeSpan, false);
  };

  const handleChangeStatusFlow = async (flow: CalculationFlow) => {
    // Set activation to 0 % before turning off and 100 % before turning on
    setIsChangingStatusFlowId(flow.id);
    const value = flow.frequency > 0 ? 0 : 100;
    setIsChangingStatusMessage(`Setting activation to ${value} %`);
    await updateSetPoint({
      external_id: flow.metadata!.activationExternalId,
      value,
    });
    await runFlexAutomation(flow.id!);
    // enable/disable flex automation
    await createOrUpdateCalculationFlow({
      ...flow,
      frequency: -flow.frequency,
    });
    onReloadFlows();
    setIsChangingStatusMessage("");
    setIsChangingStatusFlowId(null);
  };

  const handleActivationChange = async (
    flow: CalculationFlow,
    activation: number | null,
  ) => {
    if (activation === null) return;
    await updateSetPoint({
      external_id: flow.metadata!.activationExternalId,
      value: activation,
    });
    await runFlexAutomation(flow.id!);
    onReloadActivations();
  };

  return (
    <>
      <MeasuresTableHeader onSearch={setSearchWords} onChange={onReloadFlows} />
      <CompactContent>
        <TableWithoutDefaultSort
          pagination={false}
          dataSource={filteredFlow}
          rowKey="id"
          scroll={{
            y:
              windowHeight - PAGE_LAYOUT_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT,
            x: "100%",
          }}
          columns={[
            {
              dataIndex: "priority",
              title: t("energy-flexing.measures.severity"),
              width: 200,
              defaultSortOrder: "descend",
              sorter: ({ metadata: metadataOne }, { metadata: metadataTwo }) =>
                metadataTwo!.priority - metadataOne!.priority,
              render: (_, { metadata }) => (
                <>
                  {metadata!.priority} -{" "}
                  {priorityToString(metadata!.priority, t)}
                </>
              ),
            },
            {
              title: t("energy-flexing.measures.name"),
              width: 200,
              sorter: ({ name: nameOne }, { name: nameTwo }) =>
                sortString(nameOne, nameTwo),
              render: (_, { name }) => (
                <ProperateHighlighter
                  searchWords={searchWords}
                  textToHighlight={name}
                  title={name}
                />
              ),
            },
            {
              dataIndex: "flexingPotentialKw",
              title: t("energy-flexing.measures.flexingPotentialKw"),
              width: 200,
              sorter: ({ metadata: metadata1 }, { metadata: metadata2 }) =>
                metadata2!.potential.maxKW - metadata1!.potential.maxKW,
              render: (_, { metadata }) => (
                <Tooltip
                  title={`${metadata!.potential.rampUpSec}s ${t(
                    "energy-flexing.measures.ramp-up-time",
                  )},
                  ${metadata!.potential.bufferSec}s ${t(
                    "energy-flexing.measures.buffer-time",
                  )},
                  ${metadata!.potential.maxDurationSec}s ${t(
                    "energy-flexing.measures.max-duration",
                  )},
                  ${metadata!.potential.rampDownSec}s ${t(
                    "energy-flexing.measures.recovery-time",
                  )}`}
                >
                  {metadata!.potential.maxKW}
                  {"kW"} <InfoCircleOutlined />
                </Tooltip>
              ),
            },
            {
              title: t("energy-flexing.measures.status"),
              width: 50,
              sorter: ({ frequency: frequency1 }, { frequency: frequency2 }) =>
                frequency2 - frequency1,
              render: (_, flow) => {
                if (isChangingStatusFlowId === flow.id)
                  return <Spin>{isChangingStatusMessage}</Spin>;
                return (
                  <Switch
                    checked={flow.frequency > 0}
                    onChange={() => handleChangeStatusFlow(flow)}
                  />
                );
              },
            },
            {
              dataIndex: "activationPercent",
              title: t("energy-flexing.measures.activationPercent"),
              width: 200,
              sorter: (flow1, flow2) =>
                getActivation(flow2) - getActivation(flow1),
              render: (_, flow) => {
                const activation = getActivation(flow);
                if (flow.frequency > 0)
                  return (
                    <>
                      <InputNumber
                        min={0}
                        max={100}
                        value={activation}
                        onChange={(value: number | null) =>
                          handleActivationChange(flow, value)
                        }
                        formatter={(value) => `${value}%`}
                      />
                      {`(${
                        (flow.metadata!.potential.maxKW * activation) / 100
                      } kW)`}
                    </>
                  );
              },
            },
            {
              key: "actions",
              title: t("energy-flexing.measures.actions"),
              width: 200,
              render: (_, flow) => (
                <ActionsMenu flow={flow} onChange={onReloadFlows} />
              ),
            },
          ]}
          loading={loading}
        />
      </CompactContent>
    </>
  );
}
