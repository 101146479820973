import { AlarmRuleTypeName } from "@properate/common";
import { Button } from "antd";
import { useTranslations } from "@properate/translations";
import { useMemo, useState } from "react";
import { CenteredSpinner } from "@properate/ui";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { AlarmSettingsFormFields } from "@/pages/alarms/details/AlarmSettingsPage/types";
import { useAlarmDeviations } from "@/pages/alarms/details/hooks/useCogniteAlarmEvents";
import { alarmRuleTypes } from "@/pages/alarms/details/AlarmRuleTypes";

type IncidentAlarmInfoProps = {
  alarmRuleId: string;
  deviationId: number;
};

export const IncidentAlarmInfo = ({
  alarmRuleId,
  deviationId,
}: IncidentAlarmInfoProps) => {
  const t = useTranslations();
  const [type] = useFormValue<AlarmRuleTypeName>(AlarmSettingsFormFields.Type);
  const {
    deviations,
    isLoading: isLoadingTimeseries,
    error,
  } = useAlarmDeviations(alarmRuleId);
  const [showAllDeviations, setShowAllDeviations] = useState<boolean>(false);

  const mappedDeviations = useMemo(
    () =>
      deviations
        ?.filter(
          (deviation) => showAllDeviations || deviationId === deviation.id,
        )
        ?.map((deviation) => ({
          ...deviation,
          notes: [],
        })) || [],
    [deviations, showAllDeviations, deviationId],
  );

  const highlightedDeviation = useMemo(
    () => mappedDeviations.find((item) => item.id === deviationId),
    [mappedDeviations, deviationId],
  );

  const table = alarmRuleTypes[type]?.getDeviationsTable({
    deviations: mappedDeviations,
    highlighted: highlightedDeviation,
    isLoading: isLoadingTimeseries,
    error,
  });

  const onChangeListView = () => {
    setShowAllDeviations(!showAllDeviations);
  };

  return (
    <div className="flex-1 flex gap-1 flex-col">
      <div className="flex justify-between items-center">
        <div className="flex gap-4">
          <h2 className="mb-2">{t("incident.alarm-deviations")}</h2>
          <Button onClick={onChangeListView}>
            {showAllDeviations
              ? t("incident.show-only-incident-deviation")
              : t("incident.show-all-alarm-deviations")}
          </Button>
        </div>
        <div className="flex gap-2 items-center">
          <div className="bg-yellow-400/25 border-2 border-yellow-400/50 border-solid w-3 h-3" />
          <div>{t("incident.this-deviation")}</div>
        </div>
      </div>
      {isLoadingTimeseries ? (
        <CenteredSpinner />
      ) : (
        <div className="min-h-[20vh]">
          <div className="w-full h-full flex flex-col flex-nowrap gap-3 min-h-[20vh]">
            {table}
          </div>
        </div>
      )}
    </div>
  );
};
