import { LinePath, Circle } from "@visx/shape";
import { scaleLinear } from "@visx/scale";
import { AxisBottom, AxisLeft, AxisRight } from "@visx/axis";
import { Text } from "@visx/text";
import { Group } from "@visx/group";
import { useTranslations } from "@properate/translations";
import { FlexAutomationPotential } from "@/pages/calculationFlow/types";

const width = 450;
const height = 350;
const margin = { top: 20, right: 60, bottom: 100, left: 60 };

interface IProps {
  potential: FlexAutomationPotential;
}

export const PotentialGraph = ({ potential }: IProps) => {
  const t = useTranslations();
  const xDomainMax =
    (potential.rampUpSec + potential.bufferSec + potential.rampDownSec) * 1.5;
  const xScale = scaleLinear({
    domain: [0, xDomainMax],
    range: [margin.left, width - margin.right],
  });

  const yScale = scaleLinear({
    domain: [0, 100],
    range: [height - margin.bottom, margin.top],
  });

  const yRegulationScale = scaleLinear({
    domain: [-potential.maxKW * 1.5, 0].sort(),
    range: [height - margin.bottom, margin.top],
  });

  const activationData = [
    { x: 0, y: 0 },
    { x: potential.rampUpSec, y: 100 },
    { x: potential.rampUpSec + potential.bufferSec, y: 100 },
    { x: xDomainMax - potential.rampDownSec, y: 100 },
    { x: xDomainMax, y: 0, label: "" },
  ];

  const regulationData = [
    { x: 0, y: 0, label: "" },
    { x: potential.rampUpSec, y: -potential.maxKW, label: "" },
    { x: xDomainMax - potential.rampDownSec, y: -potential.maxKW, label: "" },
    { x: xDomainMax, y: 0, label: "" },
  ];

  const xLabels = [
    {
      x: 0,
      width: potential.rampUpSec,
      label: potential.rampUpSec > 0 ? "Oppstart" : "",
    },
    {
      x: potential.rampUpSec,
      width: potential.bufferSec,
      label:
        potential.bufferSec > 0 ? t("energy-flexing.measures.buffer-time") : "",
    },
    {
      x: potential.rampUpSec + potential.bufferSec,
      width:
        xDomainMax -
        potential.rampUpSec -
        potential.bufferSec -
        potential.rampDownSec,
      label: "Aktivering",
    },
    {
      x: xDomainMax - potential.rampDownSec,
      width: potential.rampDownSec,
      label: potential.rampDownSec > 0 ? "Gjenoppretting" : "",
    },
  ];

  return (
    <svg width={width} height={height}>
      <AxisBottom scale={xScale} top={height - margin.bottom} label="Tid (s)" />

      <AxisLeft
        scale={yScale}
        left={margin.left}
        label="Aktivering (%)"
        stroke="blue"
        tickStroke="blue"
        tickLabelProps={{
          fill: "blue",
          textAnchor: "end",
        }}
        labelProps={{ fill: "blue", fontSize: 10, textAnchor: "middle" }}
      />

      <AxisRight
        scale={yRegulationScale}
        left={width - margin.right}
        label="Effektendring (kW)"
        stroke="green"
        tickStroke="green"
        tickLabelProps={{
          fill: "green",
        }}
        labelProps={{ fill: "green", fontSize: 10, textAnchor: "middle" }}
      />

      <LinePath
        data={activationData}
        x={(d) => xScale(d.x)}
        y={(d) => yScale(d.y)}
        stroke="blue"
        strokeWidth={2}
      />

      {activationData.map((d, i) => (
        <g key={i}>
          <Circle cx={xScale(d.x)} cy={yScale(d.y)} r={5} fill="blue" />
        </g>
      ))}

      <LinePath
        data={regulationData}
        x={(d) => xScale(d.x)}
        y={(d) => yRegulationScale(d.y)}
        stroke="green"
        strokeWidth={2}
      />

      {regulationData.map((d, i) => (
        <g key={i}>
          <Circle
            cx={xScale(d.x)}
            cy={yRegulationScale(d.y)}
            r={5}
            fill="green"
          />
        </g>
      ))}

      {/* x labels */}
      {xLabels.map((d, index) => (
        <Group key={index}>
          <rect
            key={`bar-${index}`}
            x={xScale(d.x)}
            y={height - margin.bottom + 50}
            width={xScale(d.width) - xScale(0)}
            height={20}
            fill="lightgrey"
            rx={5} // Rounded corners
          />
          <Text
            key={index}
            x={xScale(d.x + d.width / 2)}
            y={height - margin.bottom + 60}
            fontSize={12}
            fill="black"
            textAnchor="middle"
            verticalAnchor="middle"
          >
            {d.label}
          </Text>
        </Group>
      ))}
    </svg>
  );
};
