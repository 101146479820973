import { useTranslations } from "@properate/translations";
import { Datapoints, Timeseries } from "@cognite/sdk";
import { useUser } from "@properate/auth";
import { useCallback, useEffect, useState } from "react";
import { CalculationFlow } from "@/pages/calculationFlow/types";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { AnalysisCreateRedirectButton } from "./AnalysisCreateRedirectButton";

interface IProps {
  flows: CalculationFlow[];
  activations: Datapoints[];
}

export function MeasureSummary({ flows, activations }: IProps) {
  const t = useTranslations();
  const user = useUser();
  const building = useCurrentBuilding();
  const client = useCogniteClient();
  const [activationTimeseries, setActivationTimeseries] = useState<
    Timeseries[]
  >([]);
  const [baselineTimeseries, setBaselineTimeseries] = useState<Timeseries>();

  const getActivation = (flow: CalculationFlow) => {
    const dps = activations.find((dps) => {
      dps.externalId === flow.metadata!.activationExternalId;
    });
    return dps && dps.datapoints.length > 0
      ? (dps.datapoints[0].value as number)
      : 0;
  };

  const fetchBaselineTimeseries = useCallback(async () => {
    if (!building.externalId) return;
    const baselineExternalId = {
      externalId: `TS_${building.externalId.slice(3)}+Common=100.001-OE001`,
    };
    setBaselineTimeseries(
      (
        await client.client.timeseries.retrieve([baselineExternalId], {
          ignoreUnknownIds: true,
        })
      )[0],
    );
  }, [building.externalId, client.client.timeseries]);

  const fetchActivationTimeseries = useCallback(async () => {
    const activationExternalIds = flows.map((flow) => {
      return { externalId: flow.metadata!.activationExternalId };
    });
    if (activationExternalIds.length === 0) setActivationTimeseries([]);
    else
      setActivationTimeseries(
        await client.client.timeseries.retrieve(activationExternalIds, {
          ignoreUnknownIds: true,
        }),
      );
  }, [flows, client]);

  useEffect(() => {
    fetchBaselineTimeseries();
  }, [client, fetchBaselineTimeseries]);

  useEffect(() => {
    fetchActivationTimeseries();
  }, [flows, client, fetchActivationTimeseries]);

  const totalPotential = flows.reduce(
    (acc, flow) => acc + flow.metadata!.potential.maxKW,
    0,
  );
  const activatedPotential = flows.reduce(
    (acc, flow) =>
      acc + (getActivation(flow) / 100) * flow.metadata!.potential.maxKW,
    0,
  );

  return (
    <>
      <h3>
        {`${t(
          "energy-flexing.measures.activated-kw",
        )}: ${activatedPotential} kW`}
      </h3>
      <h3>
        {`${t(
          "energy-flexing.measures.potential-total-kw",
        )}: ${totalPotential} kW`}
      </h3>
      {baselineTimeseries && (
        <div>
          <AnalysisCreateRedirectButton
            title={t("energy-flexing.create-analysis")}
            buildingId={building.id}
            settingsTimeseriesList={[
              // Baseline
              {
                id: baselineTimeseries.id,
                hidden: false,
                color: "red",
                unitSelected: baselineTimeseries.unit || "",
              },
              // FIXME: This is not working yet and analysis does not support using the same timeseries multiple times
              // Baseline - activated flex
              // {
              //   id: baselineTimeseries.id,
              //   hidden: false,
              //   color: "green",
              //   unitSelected: baselineTimeseries.unit || "",
              //   mathExpression: `DP - ${activatedPotential}`,
              // },
              // // Activations in percentage
              ...activationTimeseries.map((aTs, i) => {
                return {
                  id: aTs.id,
                  hidden: false,
                  color: Object.values(DEFAULT_GLOBAL_THEME.color)[i],
                  unitSelected: aTs.unit || "",
                };
              }),
            ]}
            userEmail={user.email}
          />
        </div>
      )}
    </>
  );
}
