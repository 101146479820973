import { message } from "antd";
import axios, { AxiosError } from "axios";
import { useTranslations, MessageKey } from "@properate/translations";
import { useCallback } from "react";

function isClientError(error: any) {
  if ("status" in error && typeof error.status === "number") {
    return error.status >= 400 && error.status < 500;
  }
  if ("code" in error && typeof error.code === "number") {
    return error.code >= 400 && error.code < 500;
  }
  return false;
}

let isErrorVisible = false;

export const useHandleApiError = () => {
  const t = useTranslations();

  return useCallback(
    async function handleAPIError(error: any, messageTimeoutInSeconds = 3) {
      if (!isErrorVisible) {
        isErrorVisible = true;
        setTimeout(
          () => (isErrorVisible = false),
          messageTimeoutInSeconds * 1000,
        );
        console.error(error);
        if (isClientError(error)) {
          return message.error(
            t("common.error.something-failed-external-code-status", {
              code: error.code,
              status: error.status,
            }),
            messageTimeoutInSeconds,
          );
        }
        if (isErrorWithTranslationKey(error)) {
          return message.error(
            t("common.error.something-failed-external-message", {
              message: t(error.response.data.errorTranslationKey as MessageKey),
            }),
            messageTimeoutInSeconds,
          );
        }
        if (isErrorWithMessage(error)) {
          return message.error(
            t("common.error.something-failed-external-message", {
              message: error.message,
            }),
            messageTimeoutInSeconds,
          );
        }
        return message.error(
          t("common.error.something-failed-external"),
          messageTimeoutInSeconds,
        );
      }
    },
    [t],
  );
};

export function isErrorWithMessage(
  error: unknown,
): error is { message: string } {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof error.message === "string"
  );
}

export function isErrorWithTranslationKey(error: unknown): error is {
  response: {
    data: {
      errorTranslationKey: string;
    };
  };
} {
  return (
    typeof error === "object" &&
    error !== null &&
    "response" in error &&
    typeof (error as any).response === "object" &&
    "data" in (error as any).response &&
    typeof (error as any).response.data === "object" &&
    "errorTranslationKey" in (error as any).response.data &&
    typeof (error as any).response.data.errorTranslationKey === "string"
  );
}

export function isAxiosError<ResponseType>(
  error: unknown,
): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error);
}
