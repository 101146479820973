import { RcFile } from "antd/es/upload";
import useSWRMutation from "swr/mutation";
import { collection, doc, setDoc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { FileUploadResponse } from "@cognite/sdk";
import axios from "axios";
import { mutate } from "swr";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { FloorPlan } from "../types";
import {
  getImageDimensions,
  useImageOptimizer,
} from "./useFloorPlanBackgroundUpdate";

export function useFloorPlanCreate() {
  const building = useCurrentBuilding();
  const { client: cogniteClient } = useCogniteClient();
  const { optimizeImage } = useImageOptimizer();

  type Extra = {
    arg: {
      name: string;
      floor: string;
      background: {
        file: RcFile;
      };
    };
  };

  return useSWRMutation(
    ["floor-plan", building.id, building.dataSetId],
    async ([_, buildingId, dataSetId], extra: Extra) => {
      async function uploadBackgroundToCognite() {
        const dimensions = await getImageDimensions(extra.arg.background.file);
        const optimizedFile = await optimizeImage(extra.arg.background.file);

        const upload = (await cogniteClient.files.upload({
          name: extra.arg.background.file.name,
          assetIds: [buildingId],
          dataSetId,
          mimeType: extra.arg.background.file.type,
          source: "properate",
          labels: [
            {
              externalId: "internal_schema_background",
            },
          ],
          metadata: {
            width: String(dimensions.width),
            height: String(dimensions.height),
          },
        })) as FileUploadResponse;

        await axios.put(upload.uploadUrl, optimizedFile);

        return {
          id: upload.id,
          width: dimensions.width,
          height: dimensions.height,
        };
      }

      const cogniteFile = await Promise.race([
        uploadBackgroundToCognite(),
        new Promise<never>((_, reject) =>
          setTimeout(
            () => reject(new Error("Floor plan background upload timed-out")),
            10_000,
          ),
        ),
      ]);

      await setDoc(doc(collection(browserFirestore, "floor-plans")), {
        buildingId,
        name: extra.arg.name,
        floor: extra.arg.floor,
        background: {
          cogniteFileId: cogniteFile.id,
          mimeType: extra.arg.background.file.type,
          width: cogniteFile.width,
          height: cogniteFile.height,
        },
        pins: [],
        selectedSensors: [],
      } as Omit<FloorPlan, "snapshotId">);

      await mutate(["floor-plans", buildingId]);
    },
  );
}
