import { Asset } from "@cognite/sdk";
import { useTranslations } from "@properate/translations/src";
import { useCallback } from "react";
import {
  CalculationFlowMetaData,
  CalculationFlowGraph,
  CalculationFlow,
} from "./types";
import { getNodeId } from "./nodes/helpers/Utils";
import { MILLISECONDS_IN_HOUR } from "./constants";
import {
  getEmptyConstantInputNode,
  getEmptyTimeSeriesOutputNode,
} from "./nodes";

/**
 * @returns initial state for a virtual sensor meta data object
 */
const initialVirtualSensorMetaData = (
  buildingId: number,
): Omit<CalculationFlowMetaData, "name"> => {
  return {
    id: null,
    description: "",
    frequency: -MILLISECONDS_IN_HOUR,
    plan: [],
    exceptions: [],
    type: "virtualSensor",
    user: null,
    building_id: buildingId,
    notifications: [],
    last_run: null,
  };
};

/**
 * @returns initial state for a cloud automation meta data object
 */
const initialCloudAutomationMetaData = (
  buildingId: number,
): Omit<CalculationFlowMetaData, "name"> => {
  return {
    id: null,
    description: "",
    frequency: -MILLISECONDS_IN_HOUR,
    plan: [],
    exceptions: [],
    type: "cloudAutomation",
    user: null,
    building_id: buildingId,
    notifications: [],
    last_run: null,
  };
};

/**
 * @returns initial state for a flex automation meta data object
 */
const initialFlexAutomationMetaData = (
  buildingId: number,
): Omit<CalculationFlowMetaData, "name"> => {
  return {
    id: null,
    description: "",
    frequency: (-MILLISECONDS_IN_HOUR / 60) * 5,
    plan: [],
    exceptions: [],
    type: "flexAutomation",
    user: null,
    building_id: buildingId,
    notifications: [],
    last_run: null,
    metadata: {
      activationExternalId: "",
      priority: 50,
      potential: {
        maxKW: 0,
        rampUpSec: 60,
        bufferSec: 60,
        maxDurationSec: 60,
        rampDownSec: 60,
      },
      meterIds: [],
    },
  };
};

/**
 * Initial state for virtual sensor graph. The graph
 * writes a constant value to a time series output:
 * ConstantNode -> TimeSeiresOutputNode
 */
const initialVirtualSensorGraph = (
  buildingExternalId: string,
): CalculationFlowGraph => {
  const constantNode = getEmptyConstantInputNode();
  const timeseriesOutputNode = getEmptyTimeSeriesOutputNode(buildingExternalId);

  return {
    edges: [
      {
        source: constantNode.id,
        sourceHandle: null,
        target: timeseriesOutputNode.id,
        targetHandle: "a",
        id: `reactflow__edge-node_${constantNode.id}-node_${timeseriesOutputNode.id}`,
      },
    ],
    nodes: [
      {
        ...timeseriesOutputNode,
        selected: false,
        positionAbsolute: {
          x: 0,
          y: 0,
        },
        dragging: false,
      },
      {
        ...constantNode,
        position: {
          x: -200,
          y: 0,
        },
        selected: false,
        positionAbsolute: {
          x: -200,
          y: 0,
        },
        dragging: false,
      },
    ],
    viewport: {
      x: 374,
      y: 79,
      zoom: 1.6,
    },
  };
};

/**
 * Initial state for cloud automation graph.
 */
const initialCloudAutomationGraph = (): CalculationFlowGraph => {
  const constantNodeId = getNodeId("constant");
  const setpointOutput = getNodeId("setpointOutput");

  return {
    edges: [
      {
        source: constantNodeId,
        sourceHandle: null,
        target: setpointOutput,
        targetHandle: "a",
        id: `reactflow__edge-node_${constantNodeId}-node_${setpointOutput}`,
      },
    ],
    nodes: [
      {
        width: 500,
        height: 258,
        id: setpointOutput,
        type: "setpointOutput",
        data: {
          externalId: null,
          priority: 16,
          operationId: "write_setpoint",
        },
        position: {
          x: 0,
          y: 0,
        },
        selected: false,
        positionAbsolute: {
          x: 0,
          y: 0,
        },
        dragging: false,
      },
      {
        width: 100,
        height: 82,
        id: constantNodeId,
        type: "constant",
        data: {
          operationId: "constant",
          value: 1,
        },
        position: {
          x: -200,
          y: 0,
        },
        selected: false,
        positionAbsolute: {
          x: -200,
          y: 0,
        },
        dragging: false,
      },
    ],
    viewport: {
      x: 374,
      y: 79,
      zoom: 1.6,
    },
  };
};

/**
 * Initial state for cloud automation graph.
 */
const initialFlexAutomationGraph = (): CalculationFlowGraph => {
  const timeseriesInputNodeId = getNodeId("timeseriesInput");
  const setpointOutput = getNodeId("setpointOutput");

  return {
    edges: [
      {
        source: timeseriesInputNodeId,
        sourceHandle: null,
        target: setpointOutput,
        targetHandle: "a",
        id: `reactflow__edge-node_${timeseriesInputNodeId}-node_${setpointOutput}`,
      },
    ],
    nodes: [
      {
        width: 500,
        height: 258,
        id: setpointOutput,
        type: "setpointOutput",
        data: {
          externalId: null,
          priority: 16,
          operationId: "write_setpoint",
        },
        position: {
          x: 0,
          y: 0,
        },
        selected: false,
        positionAbsolute: {
          x: 0,
          y: 0,
        },
        dragging: false,
      },
      {
        width: 100,
        height: 82,
        id: timeseriesInputNodeId,
        type: "timeseriesInput",
        data: {
          operationId: "read_timeseries",
          durationBeforeFallback: -900000,
          fallbackValue: 0,
          maxDataValidity: 1800000,
          externalId: null,
          readOnlyExternalId: true,
        },
        position: {
          x: -700,
          y: 0,
        },
        selected: false,
        positionAbsolute: {
          x: -700,
          y: 0,
        },
        dragging: false,
        deletable: false,
      },
    ],
    viewport: {
      x: 374,
      y: 79,
      zoom: 1.6,
    },
  };
};

export const useInitialCalculationFlow = () => {
  const t = useTranslations();
  return useCallback(
    (building: Asset, flowType: string): CalculationFlow => {
      if (flowType === "virtualSensor")
        return {
          calculation_flow: initialVirtualSensorGraph(
            building.externalId || "",
          ),
          name: t("calculation-flow.new-virtual-sensor"),
          ...initialVirtualSensorMetaData(building.id),
        };
      else if (flowType === "cloudAutomation")
        return {
          calculation_flow: initialCloudAutomationGraph(),
          name: t("calculation-flow.new-cloud-automation"),
          ...initialCloudAutomationMetaData(building.id),
        };
      return {
        calculation_flow: initialFlexAutomationGraph(),
        name: t("calculation-flow.new-flex-automation"),
        ...initialFlexAutomationMetaData(building.id),
      };
    },
    [t],
  );
};
