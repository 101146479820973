import { useTranslations } from "@properate/translations";
import { Fragment, useMemo, useRef, useState } from "react";
import { cn, CustomDndProvider } from "@properate/ui";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Timeseries } from "@cognite/sdk";
import { useRequest } from "ahooks";
import { SensorInfo } from "@properate/common";
import { ReactComponent as DragHandleIcon } from "@/components/Draggable/icons/drag-handle.svg";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useSensorCategoryOptionsWithoutSetPoints } from "@/pages/common/SchemaView/TechnicalSchema/sensorQueries";
import GraphModal from "@/pages/common/SchemaGraph";
import { useFloorPlanTimeseriesLatestValue } from "./hooks/useFloorPlanPinTimeseriesLatestValue";
import { useFloorPlan } from "./FloorPlanContext";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";
import { useFloorPlanTimeseriesTitle } from "./hooks/useFloorPlanPinTimeseriesTitle";
import { useFloorPlanSensorAlarm } from "./hooks/useFloorPlanSensorAlarm";
import { FloorPlanAlarmSign } from "./FloorPlanAlarmSign";

type Props = {
  readOnly?: boolean;
  direction?: "vertical" | "horizontal";
};

export function FloorPlanMapSidebarKPIs({
  readOnly,
  direction = "vertical",
}: Props) {
  const t = useTranslations();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const categoryOptions = useSensorCategoryOptionsWithoutSetPoints();

  const floorPlan = useFloorPlan();
  const updateFloorPlan = useFloorPlanUpdate();

  const getSavedTimeseriesIds = () => {
    if (floorPlan.kpis) {
      return floorPlan.kpis.map((kpi) => kpi.timeseriesId);
    }

    return [];
  };

  const [timeseriesIds, setTimeseriesIds] = useState(getSavedTimeseriesIds);

  function handleChange(ids: number[]) {
    setTimeseriesIds(ids);

    updateFloorPlan.trigger(
      {
        kpis: ids.map((id) => ({
          timeseriesId: id,
        })),
      },
      {
        onError() {
          setTimeseriesIds(getSavedTimeseriesIds);
        },
      },
    );
  }

  function handleAdd(list: Timeseries[]) {
    handleChange(list.map((ts) => ts.id));
  }

  function handleRemove(timeseriesId: number) {
    handleChange(timeseriesIds.filter((id) => id !== timeseriesId));
  }

  function handleMove(fromIndex: number, toIndex: number) {
    const newTimeseriesIds = [...timeseriesIds];
    newTimeseriesIds.splice(fromIndex, 1);
    newTimeseriesIds.splice(toIndex, 0, timeseriesIds[fromIndex]);
    setTimeseriesIds(newTimeseriesIds);
  }

  function handleMoveEnd() {
    updateFloorPlan.trigger(
      {
        kpis: timeseriesIds.map((id) => ({
          timeseriesId: id,
        })),
      },
      {
        onError() {
          setTimeseriesIds(getSavedTimeseriesIds);
        },
      },
    );
  }

  return (
    <Fragment>
      <div
        className={cn("flex-1 flex flex-shrink-0 gap-2 overflow-y-auto", {
          "flex-col w-60 pb-12": direction === "vertical",
          "flex-row flex-wrap": direction === "horizontal",
        })}
      >
        {timeseriesIds.map((id, index) => (
          <FloorPlanKPI
            key={id}
            readOnly={readOnly}
            timeseriesId={id}
            index={index}
            onMove={handleMove}
            onMoveEnd={handleMoveEnd}
            onRemove={() => handleRemove(id)}
          />
        ))}
        {!readOnly && (
          <button
            className={cn(
              "h-28 box-border rounded-lg flex-shrink-0",
              "border border-dashed border-muted-foreground",
              "flex items-center justify-center gap-2",
              "bg-transparent hover:bg-muted-foreground/5",
              "transition-colors duration-150",
              "cursor-pointer",
            )}
            onClick={() => setIsModalOpen(true)}
          >
            <PlusCircleOutlined />
            {t("floor-plan-v2.add-kpi")}
          </button>
        )}
      </div>
      {!readOnly && (
        <TimeseriesSelectionModal
          open={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          selectedIds={timeseriesIds}
          categoryOptions={categoryOptions}
          initialFilters={{ category: "all" }}
          hiddenFilters={["building"]}
          onOk={handleAdd}
        />
      )}
    </Fragment>
  );
}

type DragObject = { timeseriesId: number; index: number };
type DropResult = undefined;
type DropCollectedProps = { handlerId: string | symbol | null };
type DragCollectedProps = { isDragging: boolean };

function FloorPlanKPI(props: {
  timeseriesId: number;
  index: number;
  readOnly?: boolean;
  onMove: (fromIndex: number, toIndex: number) => void;
  onMoveEnd: () => void;
  onRemove: () => void;
}) {
  const [showGraph, setShowGraph] = useState(false);
  const floorPlan = useFloorPlan();
  const floorPlanUpdate = useFloorPlanUpdate();
  const value = useFloorPlanTimeseriesLatestValue(props.timeseriesId);
  const title = useFloorPlanTimeseriesTitle(props.timeseriesId);
  const dragItemType = "floor-plan-kpi";
  const containerRef = useRef<HTMLButtonElement>(null);
  const alarms = useFloorPlanSensorAlarm(props.timeseriesId);

  const timeseriesInfo = useMemo(
    () => floorPlan.sensors?.[props.timeseriesId] ?? { id: props.timeseriesId },
    [floorPlan.sensors, props.timeseriesId],
  );

  const [{ handlerId }, drop] = CustomDndProvider.useDrop<
    DragObject,
    DropResult,
    DropCollectedProps
  >({
    accept: dragItemType,
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover: CustomDndProvider.buildHoverHandler({
      currentIndex: props.index,
      containerRef,
      onMove: props.onMove,
    }),
  });

  const [{ isDragging }, drag] = CustomDndProvider.useDrag<
    DragObject,
    DropResult,
    DragCollectedProps
  >({
    type: dragItemType,
    item: () => ({ timeseriesId: props.timeseriesId, index: props.index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: () => props.onMoveEnd(),
  });

  drop(containerRef);

  const { run: handleUpdateSensor } = useRequest(
    (sensor: SensorInfo) =>
      floorPlanUpdate.trigger({
        sensor,
      }),
    {
      debounceWait: 600,
      debounceTrailing: true,
      manual: true,
    },
  );

  return (
    <Fragment>
      <button
        ref={containerRef}
        onClick={() => setShowGraph(true)}
        className={cn(
          "h-28 box-border px-4 py-2 rounded-lg flex-shrink-0",
          "flex",
          "bg-card text-card-foreground",
          "border border-solid border-muted hover:border-primary",
          "transition-colors duration-150",
          "cursor-pointer text-left",
          "shadow-sm",
          { "opacity-50": isDragging },
        )}
      >
        <div className="flex-1 h-full flex gap-1">
          {!props.readOnly && (
            <div>
              <div
                ref={drag}
                data-handler-id={handlerId}
                className="-ml-3 -mt-1 w-7 h-7 flex items-center justify-center hover:bg-muted cursor-grab rounded-lg"
              >
                <DragHandleIcon width={16} height={16} className="-mb-1" />
              </div>
            </div>
          )}
          <div className="flex flex-1 justify-between flex-col">
            <div className="font-medium text-sm space-y-1">
              {title.isLoading ? (
                <div className="h-5 w-32 bg-muted-foreground/10 animate-pulse" />
              ) : (
                title.data
                  ?.split("\n")
                  .map((line) => (line ? <div key={line}>{line}</div> : null))
              )}
            </div>
            <div className="flex items-center justify-between gap-2">
              <div className="text-xl text-muted-foreground font-bold">
                {value.formattedValue}
              </div>
              <FloorPlanAlarmSign alarms={alarms} hideSuccess />
            </div>
          </div>
        </div>
      </button>
      {showGraph && (
        <GraphModal
          showAlerts
          showDocuments
          buildingId={floorPlan.buildingId}
          timeseriesInfo={timeseriesInfo}
          setTimeseriesInfo={handleUpdateSensor}
          deleteTimeseries={props.onRemove}
          hide={() => setShowGraph(false)}
        />
      )}
    </Fragment>
  );
}
