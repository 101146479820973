import { useState, useMemo, useRef, useCallback } from "react";
import { debounce } from "lodash";
import { Timeseries } from "@cognite/sdk";
import { Button } from "antd";
import { FilterOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { TimeseriesSelectionTable } from "../TimeseriesSelectionTable/TimeseriesSelectionTable";
import { TimeseriesSelectionFilters } from "../TimeseriesSelectionFilter/TimeseriesSelectionFilters";
import { TimeseriesSelectionMainFilter } from "../TimeseriesSelectionMainFilter/TimeseriesSelectionMainFilter";
import { getDefaultFilters } from "../../utils";
import {
  CategoryOption,
  TimeseriesHideableFilter,
  TimeseriesInitialFilters,
} from "../../types";
import { useSearchTimeseriesList } from "../../hooks";
import { useTableHeight } from "../../hooks/useTableHeight";
import { Container, FilterToggleContainer } from "./elements";

interface Props {
  hiddenFilters: TimeseriesHideableFilter[];
  selectedIds: number[];
  disabledIds: number[];
  categoryOptions: CategoryOption[];
  initialFilters?: TimeseriesInitialFilters;
  onChange: (timeseries: Timeseries) => unknown;
  onAdd: (timeseries: Timeseries) => unknown;
  onRemove: (id: number) => unknown;
  max: number;
  subBuildings: string[];
  initialSearch: string;
}

export function TimeseriesSelectionModalContent({
  hiddenFilters,
  selectedIds,
  disabledIds,
  initialFilters,
  categoryOptions,
  onChange,
  onAdd,
  onRemove,
  max,
  subBuildings,
  initialSearch,
}: Props) {
  const t = useTranslations();

  const defaultFilters = getDefaultFilters(categoryOptions);
  const currentBuildingId = useCurrentBuildingId(
    initialFilters?.buildingId !== undefined,
  );
  const [mainFilters, setMainFilters] = useState({
    search: initialSearch,
    // If initialFilters?.buildingId is not set, currentBuildingId will be set
    buildingId: (initialFilters?.buildingId || currentBuildingId)!,
  });
  const [filters, setFilters] = useState({
    category: initialFilters?.category || defaultFilters.category,
    system: initialFilters?.system || defaultFilters.system,
    subBuilding: initialFilters?.subBuilding || defaultFilters.subBuilding,
    unit: initialFilters?.unit || defaultFilters.unit,
  });
  // 'unit' is not possible to filter on, hence the -1
  const isFilterToggleAvailable =
    hiddenFilters.length !== Object.keys(defaultFilters).length - 1;
  const [filterVisible, setFilterVisible] = useState(isFilterToggleAvailable);
  const [sort, setSort] = useState<string>();
  const {
    searchableTimeseriesList,
    isLoading: isLoadingSearchableTimeseriesList,
    size,
    setSize,
  } = useSearchTimeseriesList(
    { ...mainFilters, ...filters },
    categoryOptions,
    sort,
  );
  const setSearchDebounced = useMemo(
    () =>
      debounce(
        (value: string) =>
          setMainFilters((mainFiltersCurrent) => ({
            ...mainFiltersCurrent,
            search: value,
          })),
        300,
      ),
    [setMainFilters],
  );
  const refTimeseriesFilter = useRef<HTMLDivElement>(null);
  const tableHeight = useTableHeight(refTimeseriesFilter);

  const areFiltersRemovable =
    Object.entries(filters)
      // Since unit can only ever be a hidden filter, it should never be shown
      .filter(([filterKey]) => filterKey !== "unit")
      .filter(
        ([filterKey, filterValue]) =>
          filterValue !==
          defaultFilters[
            filterKey as Exclude<TimeseriesHideableFilter, "building">
          ],
      )
      .filter(
        ([filterKey]) =>
          !hiddenFilters.includes(filterKey as TimeseriesHideableFilter),
      ).length > 0;

  function handleChangeSearch(search: string) {
    setSearchDebounced(search);
  }

  function handleChangeBuildingId(buildingId: number) {
    setMainFilters((currentMainFilters) => ({
      ...currentMainFilters,
      buildingId,
    }));
    removeFilters();
  }

  function handleChangeCategory(category: string) {
    setFilters((currentFilters) => ({
      ...currentFilters,
      category,
      system: defaultFilters.system,
      subBuilding: initialFilters?.subBuilding || defaultFilters.subBuilding,
    }));
  }

  const handleChangeSystem = useCallback((system: string | null) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      system,
    }));
  }, []);

  function handleChangeSubBuilding(subBuilding: string) {
    setFilters((currentFilters) => ({
      unit: currentFilters.unit,
      category: currentFilters.category,
      system: null,
      subBuilding,
    }));
  }

  function removeFilters() {
    setFilters(defaultFilters);
  }

  function handleReachedEndOfPage() {
    setSize(size + 1);
  }

  return (
    <Container>
      <Container ref={refTimeseriesFilter}>
        <TimeseriesSelectionMainFilter
          hideBuildingFilter={hiddenFilters.includes("building")}
          buildingId={mainFilters.buildingId}
          onChangeBuildingId={handleChangeBuildingId}
          onChangeSearch={handleChangeSearch}
          initialSearch={initialSearch}
        />
        {isFilterToggleAvailable && (
          <FilterToggleContainer>
            <Button
              icon={<FilterOutlined />}
              onClick={() =>
                setFilterVisible(
                  (filterVisibleCurrent) => !filterVisibleCurrent,
                )
              }
              type="text"
            >
              {t("common.timeseries-modal.filters")}
            </Button>{" "}
            {areFiltersRemovable && (
              <Button
                icon={<CloseOutlined />}
                onClick={removeFilters}
                type="text"
              >
                {t("common.timeseries-modal.remove-filters")}
              </Button>
            )}
          </FilterToggleContainer>
        )}
        {filterVisible && (
          <TimeseriesSelectionFilters
            filters={{
              buildingId: mainFilters.buildingId,
              ...filters,
            }}
            subBuildings={subBuildings}
            initialFilters={initialFilters}
            categoryOptions={categoryOptions}
            hiddenFilters={hiddenFilters}
            onChangeCategory={handleChangeCategory}
            onChangeSystem={handleChangeSystem}
            onChangeSubBulding={handleChangeSubBuilding}
          />
        )}
      </Container>
      {typeof tableHeight === "number" && (
        <TimeseriesSelectionTable
          searchableTimeseriesList={searchableTimeseriesList}
          isLoadingSearchableTimeseriesList={isLoadingSearchableTimeseriesList}
          onChangeSort={setSort}
          onReachedEndOfPage={handleReachedEndOfPage}
          bodyHeight={tableHeight}
          disabledIds={disabledIds}
          onChange={onChange}
          onAdd={onAdd}
          onRemove={onRemove}
          selectedIds={selectedIds}
          max={max}
        />
      )}
    </Container>
  );
}
