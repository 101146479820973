import useSWR from "swr";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { bisector } from "d3-array";
import { useContext, useMemo, useState } from "react";
import dayjs from "@properate/dayjs";
import {
  Col,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  App,
  DatePicker,
} from "antd";
import { CogniteClient } from "@cognite/sdk";
import _ from "lodash";
import { PercentageOutlined } from "@ant-design/icons/lib";
import { scaleLinear, scaleTime } from "@visx/scale";
import { curveBasis } from "@visx/curve";
import { Line, LinePath } from "@visx/shape";
import {
  defaultStyles,
  Tooltip,
  useTooltip,
  useTooltipInPortal,
} from "@visx/tooltip";
import styled, { ThemeContext } from "styled-components";
import { localPoint } from "@visx/event";
import { formatMeasurement } from "@properate/common";
import { deleteField } from "firebase/firestore";
import { useTranslations } from "@properate/translations";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import {
  ACCENT1,
  ACCENT2,
  ACCENT6,
  ACCENT8,
  NEUTRAL7,
  PRIMARY,
} from "@/utils/ProperateColors";
import {
  calculateSumOfEnergyDataPoints,
  disabledFutureDates,
} from "@/utils/helpers";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { ShowReferenceYear } from "@/components/AccumulatedEnergyGraph/ShowReferenceYear/ShowReferenceYear";
import { DashboardStandardWidget, NotificationInstance } from "@/utils/types";
import { WidgetHeader } from "../widget-components/WidgetHeader";
import { DraggableCard } from "../draggable/DraggableCard";

interface Props {
  buildingId: string;
  energy: Record<string, number>;
  width: number;
  height: number;
  organizations: string[];
  onClickRemoveButton: () => unknown;
  type: Extract<
    DashboardStandardWidget,
    | "accumulatedEnergyUse"
    | "accumulatedEnergyUseTempCorrectedGraddag"
    | "accumulatedEnergyUseTempCorrectedEtmod"
  >;
}

const StartDato = styled.div`
  margin-bottom: 20px;
`;
const aggregateGraphPoint =
  (addYear: number, name: string) =>
  (
    prev: Record<string, number>[],
    current: Record<string, number>,
    index: number,
  ) => {
    prev.push({
      time: dayjs(current.time).add(addYear, "year").valueOf(),
      [name]: current.value + (index === 0 ? 0 : prev[index - 1][name]),
    });
    return prev;
  };

const YEAR_MAP: Record<string, number> = {
  thisYear: 0,
  lastYear: 1,
  twoYearsAgo: 2,
  threeYearsAgo: 3,
};

const calculateAndReduce =
  (client: CogniteClient, notificationInstance: NotificationInstance) =>
  async (params: string) => {
    const {
      organization,
      year,
      referenceYear,
      correction,
      start,
      end,
      ...rest
    } = JSON.parse(params);

    const data = await calculateSumOfEnergyDataPoints(
      client,
      notificationInstance,
    )(
      JSON.stringify({
        ...rest,
        start: new Date(start),
        end: end ? new Date(end) : "now",
      }),
    );

    const dataMap = data!.reduce(
      aggregateGraphPoint(YEAR_MAP[year as string], year),
      [],
    );

    return year === referenceYear
      ? dataMap
          .filter((val) => val[referenceYear])
          .map((item) => {
            return {
              ...item,
              afterCorrection:
                item[referenceYear] - (item[referenceYear] * correction) / 100,
            };
          })
      : dataMap;
  };

export function AccumulatedEnergyWidget({
  energy,
  buildingId,
  width,
  height,
  organizations,
  onClickRemoveButton,
  type,
}: Props) {
  const t = useTranslations();
  const { notification } = App.useApp();
  const { client } = useCogniteClient();
  const themeContext = useContext(ThemeContext);
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  const { data: preferences } = useUserSettings();

  const preferencesAccumulatedEnergyUse =
    preferences?.buildings?.[buildingId]?.[type];

  const startDate = preferencesAccumulatedEnergyUse?.startDate;
  const referenceYear = preferencesAccumulatedEnergyUse?.referenceYear?.year;
  const correction = preferencesAccumulatedEnergyUse?.referenceYear?.correction;
  const showReferenceYear = Boolean(
    preferencesAccumulatedEnergyUse?.showReferenceYear,
  );

  const organization =
    preferencesAccumulatedEnergyUse?.organization || organizations[0];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const getStartIfBefore = (
    startYear: number,
    endYear: number,
    startDate?: number,
  ) => {
    if (!startDate) {
      return startYear;
    }
    if (startDate > endYear) {
      return undefined;
    }
    return startYear < startDate ? startDate : startYear;
  };

  const thisYear = useMemo(() => {
    const start = dayjs().startOf("year");
    const end = start.endOf("year");

    return [
      getStartIfBefore(start.valueOf(), end.valueOf(), startDate),
      end.valueOf(),
    ];
  }, [startDate]);

  const lastYear = useMemo(() => {
    const start = dayjs().subtract(1, "year").startOf("year");
    const end = start.endOf("year");
    return [
      getStartIfBefore(start.valueOf(), end.valueOf(), startDate),
      end.valueOf(),
    ];
  }, [startDate]);

  const twoYearsAgo = useMemo(() => {
    const start = dayjs().subtract(2, "year").startOf("year");
    const end = start.endOf("year");
    return [
      getStartIfBefore(start.valueOf(), end.valueOf(), startDate),
      end.valueOf(),
    ];
  }, [startDate]);

  const threeYearsAgo = useMemo(() => {
    const start = dayjs().subtract(3, "year").startOf("year");
    const end = start.endOf("year");
    return [
      getStartIfBefore(start.valueOf(), end.valueOf(), startDate),
      end.valueOf(),
    ];
  }, [startDate]);

  const { data: accumulatedDataThisYear } = useSWR(
    energy[organization] && thisYear[0]
      ? JSON.stringify({
          start: thisYear[0],
          end: thisYear[1],
          granularity: "d",
          items: [energy[organization]],
          year: "thisYear",
          referenceYear,
          correction,
        })
      : null,
    calculateAndReduce(client, notification),
  );

  const { data: accumulatedDataOneYearAgo } = useSWR(
    energy[organization] && lastYear[0]
      ? JSON.stringify({
          start: lastYear[0],
          end: lastYear[1],
          granularity: "d",
          items: [energy[organization]],
          year: "lastYear",
          referenceYear,
          correction,
        })
      : null,
    calculateAndReduce(client, notification),
  );

  const { data: accumulatedDataTwoYearsAgo } = useSWR(
    energy[organization] && twoYearsAgo[0]
      ? JSON.stringify({
          start: twoYearsAgo[0],
          end: twoYearsAgo[1],
          granularity: "d",
          items: [energy[organization]],
          year: "twoYearsAgo",
          referenceYear,
          correction,
        })
      : null,
    calculateAndReduce(client, notification),
  );

  const { data: accumulatedDataThreeYearsAgo } = useSWR(
    energy[organization] && threeYearsAgo[0]
      ? JSON.stringify({
          start: threeYearsAgo[0],
          end: threeYearsAgo[1],
          granularity: "d",
          items: [energy[organization]],
          year: "threeYearsAgo",
          referenceYear,
          correction,
        })
      : null,
    calculateAndReduce(client, notification),
  );

  const handleChangeDate = async (date: any) => {
    if (!date) {
      await mutateUserSettings({
        buildings: {
          [buildingId]: {
            [type]: {
              startDate: deleteField() as any,
            },
          },
        },
      });
    } else {
      await mutateUserSettings({
        buildings: {
          [buildingId]: {
            [type]: {
              startDate: dayjs(date).startOf("day").toDate().valueOf(),
            },
          },
        },
      });
    }
  };

  const handleChangeOrganization = async (organization: string) => {
    await mutateUserSettings({
      buildings: {
        [buildingId]: {
          [type]: {
            organization,
          },
        },
      },
    });
  };

  const updateCorrection = async (value: number | null) => {
    if (value && referenceYear) {
      await mutateUserSettings({
        buildings: {
          [buildingId]: {
            [type]: {
              referenceYear: {
                year: referenceYear,
                correction: value,
              },
            },
          },
        },
      });
    }
  };
  const DEFAULT_CORRECTION_PERCENTAGE = 10;
  const updateReferenceYear = async (e: RadioChangeEvent) => {
    const selectedYear = e.target.value;

    if (selectedYear) {
      await mutateUserSettings({
        buildings: {
          [buildingId]: {
            [type]: {
              referenceYear: {
                year: selectedYear,
                correction: correction || DEFAULT_CORRECTION_PERCENTAGE,
              },
              showReferenceYear: true,
            },
          },
        },
      });
    }
  };

  const showHideReferenceYear = async (showReferenceYear: boolean) => {
    await mutateUserSettings({
      buildings: {
        [buildingId]: {
          [type]: {
            showReferenceYear,
          },
        },
      },
    });
  };
  // bounds
  const padding = 24;
  const headerHeight = 46;
  const graphWidth = width > 0 ? width - padding * 2 : 0;
  const graphHeight = height - padding * 2 - headerHeight;

  const yTop = 104;
  const yAxisWidth = 50;
  const xAxisHeight = 30;

  const xMax = graphWidth > 0 ? graphWidth - yAxisWidth : 0;
  const yMax = graphHeight - yTop - xAxisHeight;

  const timeScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        round: true,
        domain: [
          dayjs().startOf("year").valueOf(),
          dayjs().endOf("year").valueOf(),
        ],
      }),
    [xMax],
  );

  const data = useMemo(
    () =>
      Object.values(
        _.merge(
          _.keyBy(accumulatedDataThisYear, "time"),
          _.keyBy(accumulatedDataOneYearAgo, "time"),
          _.keyBy(accumulatedDataTwoYearsAgo, "time"),
          _.keyBy(accumulatedDataThreeYearsAgo, "time"),
        ),
      ).sort((a, b) => a.time - b.time),
    [
      accumulatedDataThisYear,
      accumulatedDataOneYearAgo,
      accumulatedDataTwoYearsAgo,
      accumulatedDataThreeYearsAgo,
    ],
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [
          0,
          Math.ceil(
            Math.max(
              ...(data || []).map((gv) =>
                Math.max(
                  gv.thisYear || 0,
                  gv.lastYear || 0,
                  gv.twoYearsAgo || 0,
                  gv.threeYearsAgo || 0,
                  gv.afterCorrection || 0,
                ),
              ),
            ),
          ),
        ],
      }),
    [yMax, data],
  );

  const bisectDate = bisector<any, Date>((d) => new Date(d.time)).left;

  const getDataForEvent = (coords: {
    x: number;
    y: number;
  }): Record<string, number> | void => {
    const x0 = timeScale.invert(coords.x - yAxisWidth);
    const index = bisectDate(data, x0, 1);
    const d0 = data[index - 1];
    const d1 = data[index];
    let d = d0;
    if (d1 && d1.time) {
      d =
        x0.valueOf() - d0.time.valueOf() > d1.time.valueOf() - x0.valueOf()
          ? d1
          : d0;
    }
    return d;
  };

  const handleTooltip = (
    event: React.MouseEvent<SVGRectElement, MouseEvent>,
  ) => {
    const point = event.currentTarget.ownerSVGElement
      ? localPoint(event.currentTarget.ownerSVGElement, event)
      : null;
    const coords = point || { x: 0, y: 0 };
    const d = getDataForEvent(coords);

    if (
      typeof d?.lastYear === "number" ||
      typeof d?.twoYearsAgo === "number" ||
      typeof d?.threeYearsAgo === "number" ||
      typeof d?.thisYear === "number" ||
      typeof d?.afterCorrection === "number"
    ) {
      showTooltip({
        tooltipData: {
          date: dayjs(d.time).format("DD. MMMM"),
          thisYear: formatMeasurement({ value: d.thisYear, unit: "kWh" }),
          lastYear: formatMeasurement({ value: d.lastYear, unit: "kWh" }),
          twoYearsAgo: formatMeasurement({ value: d.twoYearsAgo, unit: "kWh" }),
          threeYearsAgo: formatMeasurement({
            value: d.threeYearsAgo,
            unit: "kWh",
          }),
          afterCorrection: formatMeasurement({
            value: d.afterCorrection,
            unit: "kWh",
          }),
        },
        tooltipLeft: coords.x,
        // yAxisWidth + timeScale(d.time) + Math.ceil(xMax / (24 * 7) / 2 / 2),
        tooltipTop: coords.y,
      });
    } else {
      hideTooltip();
    }
  };

  function getWidgetHeaderTitle() {
    const isOwnerOrganization = organization === organizations.at(0);

    const organizationOwnerType =
      typeof organization === "string"
        ? isOwnerOrganization
          ? "owner"
          : "tenant"
        : "";

    return t("dashboard.widgets.energy-consumption.accumulated-title", {
      type,
      organizationOwnerType,
    });
  }

  const correctionYear = data && data.filter((val) => val.afterCorrection);

  return (
    <div>
      <DraggableCard
        style={{ height }}
        styles={{
          body: { padding, position: "relative" },
        }}
        title={
          <WidgetHeader
            isDraggable
            text={getWidgetHeaderTitle()}
            onClickRemoveButton={onClickRemoveButton}
            onClickSettingsButton={() => setIsModalVisible(true)}
          />
        }
      >
        <div ref={containerRef} style={{ position: "relative" }}>
          <svg width={graphWidth} height={graphHeight}>
            <AxisLeft
              top={yTop}
              left={yAxisWidth}
              scale={yScale}
              tickStroke={themeContext.neutral4}
              stroke={themeContext.neutral4}
              numTicks={5}
              hideAxisLine
              hideTicks
              tickFormat={(val: any) => `${(val || 0) / 1000}K`}
              tickLabelProps={{
                fill: themeContext.neutral4,
              }}
            />
            <GridRows
              numTicks={5}
              scale={yScale}
              width={xMax}
              top={yTop}
              left={yAxisWidth}
              fill={themeContext.neutral7}
            />
            <AxisBottom
              top={yMax + yTop}
              left={yAxisWidth}
              scale={timeScale}
              numTicks={12}
              stroke={themeContext.neutral4}
              tickStroke={themeContext.neutral4}
              tickFormat={(date: any) => {
                const m = dayjs(date);
                return m.format("MMM");
              }}
              tickLabelProps={{
                fill: themeContext.neutral4,
                scaleToFit: true,
              }}
            />
            <g>
              {accumulatedDataThreeYearsAgo && (
                <LinePath
                  data={data}
                  x={(d: any) => yAxisWidth + timeScale(d.time)}
                  y={(d: any) => yScale(d.threeYearsAgo || 0) + yTop}
                  defined={(d: any) => typeof d.threeYearsAgo === "number"}
                  stroke={ACCENT6}
                  strokeWidth={2}
                  curve={curveBasis}
                />
              )}
              {accumulatedDataTwoYearsAgo && (
                <LinePath
                  data={data}
                  x={(d: any) => yAxisWidth + timeScale(d.time)}
                  y={(d: any) => yScale(d.twoYearsAgo || 0) + yTop}
                  defined={(d: any) => typeof d.twoYearsAgo === "number"}
                  stroke={ACCENT8}
                  strokeWidth={2}
                  curve={curveBasis}
                />
              )}
              {accumulatedDataOneYearAgo && (
                <LinePath
                  data={data}
                  x={(d: any) => yAxisWidth + timeScale(d.time)}
                  y={(d: any) => yScale(d.lastYear || 0) + yTop}
                  defined={(d: any) => typeof d.lastYear === "number"}
                  stroke={ACCENT2}
                  strokeWidth={2}
                  curve={curveBasis}
                />
              )}
              {showReferenceYear && correctionYear && (
                <LinePath
                  curve={curveBasis}
                  strokeDasharray="4"
                  data={correctionYear}
                  x={(d: any) => yAxisWidth + timeScale(d.time)}
                  y={(d: any) => yScale(d.afterCorrection || 0) + yTop}
                  defined={(d: any) => typeof d.afterCorrection === "number"}
                  stroke={NEUTRAL7}
                  strokeWidth={2}
                />
              )}
              {accumulatedDataThisYear &&
                data &&
                data
                  .filter((data) => data.thisYear)
                  .map((val) => {
                    const barHeight = yMax - yScale(val.thisYear || 0);
                    const barY = yMax - barHeight + yTop;
                    return (
                      <rect
                        key={val.time}
                        x={yAxisWidth + timeScale(val.time)}
                        y={barY}
                        width={Math.ceil(xMax / (24 * 365) / 2)}
                        height={barHeight}
                        fill={ACCENT1}
                      />
                    );
                  })}
            </g>
            <rect
              x={yAxisWidth}
              y={yTop}
              width={xMax}
              height={yMax}
              fill="transparent"
              onMouseMove={(event) => handleTooltip(event)}
              onMouseLeave={() => {
                hideTooltip();
              }}
            />
            {tooltipData && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: yTop }}
                  to={{ x: tooltipLeft, y: yTop + yMax }}
                  stroke={themeContext.neutral5}
                  strokeWidth={2}
                  pointerEvents="none"
                  strokeDasharray="5,2"
                />
              </g>
            )}
          </svg>
          {tooltipOpen && (
            <>
              <TooltipInPortal
                // set this to random so that it correctly updates with parent bounds
                key={Math.random()}
                top={tooltipTop! - 34}
                left={tooltipLeft}
              >
                <table cellPadding={4}>
                  <tbody>
                    {showReferenceYear &&
                      correctionYear &&
                      correctionYear.length > 0 && (
                        <tr>
                          <td>
                            {" "}
                            {t(
                              "dashboard.widgets.energy-consumption.measure-curve",
                            )}
                          </td>
                          <td style={{ color: NEUTRAL7, fontSize: "24px" }}>
                            •
                          </td>
                          <td>
                            {(tooltipData as any)?.afterCorrection || "--"}
                          </td>
                        </tr>
                      )}
                    {accumulatedDataThisYear && (
                      <tr>
                        <td>
                          {t("dashboard.widgets.energy-consumption.this-year")}
                        </td>
                        <td style={{ color: PRIMARY, fontSize: "24px" }}>•</td>
                        <td>{(tooltipData as any)?.thisYear || "--"}</td>
                      </tr>
                    )}
                    {accumulatedDataOneYearAgo && (
                      <tr>
                        <td>
                          {t("dashboard.widgets.energy-consumption.last-year")}
                        </td>
                        <td style={{ color: ACCENT2, fontSize: "24px" }}>•</td>
                        <td>{(tooltipData as any)?.lastYear || "--"}</td>
                      </tr>
                    )}
                    {accumulatedDataTwoYearsAgo && (
                      <tr>
                        <td>
                          {t(
                            "dashboard.widgets.energy-consumption.last-2-years",
                          )}
                        </td>
                        <td style={{ color: ACCENT8, fontSize: "24px" }}>•</td>
                        <td>{(tooltipData as any)?.twoYearsAgo || "--"}</td>
                      </tr>
                    )}
                    {accumulatedDataThreeYearsAgo && (
                      <tr>
                        <td>
                          {t(
                            "dashboard.widgets.energy-consumption.last-3-years",
                          )}
                        </td>
                        <td style={{ color: ACCENT6, fontSize: "24px" }}>•</td>
                        <td>{(tooltipData as any)?.threeYearsAgo || "--"}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </TooltipInPortal>
              <Tooltip
                top={yTop - 20}
                left={tooltipLeft}
                style={{
                  ...defaultStyles,
                  textAlign: "center",
                  transform: "translate(-100%,-100%)",
                  marginLeft: 0,
                }}
              >
                {(tooltipData as any)?.date}
              </Tooltip>
            </>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            top: 18,
            right: 24,
            fontSize: "13px",
            lineHeight: "13px",
            color: themeContext.neutral4,
            textAlign: "right",
          }}
        >
          <table cellPadding={4}>
            <tbody>
              <tr>
                {(showReferenceYear &&
                  correctionYear &&
                  correctionYear.length > 0 && (
                    <>
                      <td>
                        {t(
                          "dashboard.widgets.energy-consumption.measure-curve",
                        )}
                      </td>
                      <td style={{ color: NEUTRAL7, fontSize: "24px" }}>--</td>
                    </>
                  )) || (
                  <>
                    <td />
                    <td />
                  </>
                )}
                {accumulatedDataThisYear && (
                  <>
                    <td>
                      {t("dashboard.widgets.energy-consumption.this-year")}
                    </td>
                    <td style={{ color: PRIMARY, fontSize: "24px" }}>•</td>
                  </>
                )}
              </tr>
              {accumulatedDataOneYearAgo && (
                <tr>
                  <td />
                  <td />
                  <td>{t("dashboard.widgets.energy-consumption.last-year")}</td>
                  <td style={{ color: ACCENT2, fontSize: "24px" }}>•</td>
                </tr>
              )}
              {accumulatedDataTwoYearsAgo && (
                <tr>
                  <td />
                  <td />
                  <td>
                    {t("dashboard.widgets.energy-consumption.last-2-years")}
                  </td>
                  <td style={{ color: ACCENT8, fontSize: "24px" }}>•</td>
                </tr>
              )}
              {accumulatedDataThreeYearsAgo && (
                <tr>
                  <td />
                  <td />
                  <td>
                    {t("dashboard.widgets.energy-consumption.last-3-years")}
                  </td>
                  <td style={{ color: ACCENT6, fontSize: "24px" }}>•</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isModalVisible && (
          <Modal
            title={t(
              "dashboard.widgets.energy-consumption.settings-for-accumulated-energy-use",
            )}
            open={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
          >
            <Row style={{ marginBottom: 8 }}>
              <Col span={24}>
                <Space>
                  {t("dashboard.widgets.energy-consumption.organization")}{" "}
                  <Select
                    style={{ width: 200 }}
                    options={organizations.map((organization) => ({
                      label: t("energy.owner-name", {
                        name: organization || "",
                        index: organization === organizations[0] ? 0 : 1,
                      }),
                      value: organization,
                    }))}
                    value={organization}
                    onChange={handleChangeOrganization}
                  />
                </Space>
              </Col>
            </Row>
            <StartDato>
              <Space>
                <h4>
                  {t("dashboard.widgets.energy-consumption.start-date")}:{" "}
                </h4>
                <DatePicker
                  value={startDate ? dayjs(startDate) : undefined}
                  disabledDate={disabledFutureDates}
                  onChange={handleChangeDate}
                  allowClear
                />
              </Space>
            </StartDato>

            <>
              <Row>
                <Col span={24}>
                  <h4 style={{ marginBottom: 20 }}>
                    {t(
                      "dashboard.widgets.energy-consumption.measure-curve-description",
                    )}
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Space>
                    <h4>
                      {t("dashboard.widgets.energy-consumption.reference-year")}
                      :{" "}
                    </h4>
                    <ReferenceYear
                      startDate={startDate}
                      onChangeRadioButton={updateReferenceYear}
                      referenceYear={referenceYear}
                    />
                  </Space>
                </Col>
                <Col span={16}>
                  <Space>
                    {t("dashboard.widgets.energy-consumption.measure-curve")}:
                    <InputNumber
                      className="min-w-[80px]"
                      disabled={!referenceYear}
                      placeholder="10"
                      value={correction}
                      min={-100}
                      max={100}
                      addonAfter={<PercentageOutlined />}
                      onChange={updateCorrection}
                      decimalSeparator=","
                    />
                    <ShowReferenceYear
                      showReferenceYear={showReferenceYear}
                      setShowHideReferenceYear={showHideReferenceYear}
                    />
                  </Space>
                </Col>
              </Row>
            </>
          </Modal>
        )}
      </DraggableCard>
    </div>
  );
}
type ReferenceYearProps = {
  startDate: number | undefined;
  onChangeRadioButton: (e: RadioChangeEvent) => void;
  referenceYear?: string;
};

const YEAR_AVAILABLE: Record<number, string> = {
  0: "lastYear",
  1: "twoYearsAgo",
  2: "threeYearsAgo",
};

const getYearList = (startDate: number | undefined) => {
  const HISTORICAL_DATA_FROM = dayjs().subtract(3, "year").year(); // 4 years data historic
  const LAST_YEAR = dayjs().subtract(1, "year").year();

  let startYear = dayjs(startDate).year();
  const yearList = [];
  let count = 0;

  if (!startDate) {
    for (let i = 0; i < 3; i++) yearList.push(YEAR_AVAILABLE[i]);
  }

  if (startYear === LAST_YEAR) {
    yearList.push(YEAR_AVAILABLE[0]);

    return yearList;
  }

  if (startYear && startYear >= HISTORICAL_DATA_FROM) {
    while (startYear < LAST_YEAR) {
      startYear = dayjs(startDate).add(count, "year").year();
      yearList.push(YEAR_AVAILABLE[count]);
      count++;
    }
  } else {
    for (let i = 0; i < 3; i++) yearList.push(YEAR_AVAILABLE[i]);
  }
  return yearList;
};

const ReferenceYear = ({
  startDate,
  onChangeRadioButton,
  referenceYear,
}: ReferenceYearProps) => {
  const yearList = getYearList(startDate);

  return (
    <Radio.Group onChange={onChangeRadioButton} value={referenceYear}>
      <Space direction="vertical">
        {yearList.map((year) => (
          <Radio key={year} value={year}>
            {dayjs().subtract(YEAR_MAP[year], "year").year()}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};
