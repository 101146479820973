import { useParams } from "react-router-dom";
import { useUser } from "@properate/auth";
import { AccessModules } from "@properate/common";
import { App } from "antd";
import { useTranslations } from "@properate/translations";
import { useRootAssets } from "@/hooks/useRootAssets";
import { allAccessModules } from "@/features/organizationAdmin";
import { useOrganization } from "@/services/organization";
import { OrganizationAdminModules } from "@/pages/organizationAdmin/OrganizationAdminModules";
import { useAliasConfig } from "@/services/alias";
import { useBuildingSpec } from "@/services/buildingSpec";
import { BuildingInfo } from "@/components/Building/BuildingInfo";
import { deleteBuildingModules, updateBuildingModules } from "@/eepApi";

export const OrganizationAdminBuildingModules = () => {
  const t = useTranslations();
  const { organizationId, dataSetId } = useParams();
  const rootAssets = useRootAssets();
  const { message } = App.useApp();

  const { ownerAlias } = useUser();
  const aliasConfig = useAliasConfig(ownerAlias);

  const selectedBuilding = rootAssets.find(
    (asset) => asset.dataSetId === Number(dataSetId),
  );

  const buildingsSpec = useBuildingSpec(selectedBuilding?.id);

  const organizationName = organizationId
    ? decodeURIComponent(organizationId)
    : selectedBuilding?.metadata?.owner || "";

  const { data: organizationData, isLoading } =
    useOrganization(organizationName);

  const organizationModules = organizationData?.modules || allAccessModules;

  const savedModules =
    (!!dataSetId &&
      organizationData?.buildings?.[Number(dataSetId)]?.modules) ||
    organizationModules;

  const onUpdate = async (values: AccessModules[]) => {
    const shouldBeRemoved =
      values.length === organizationModules.length &&
      values.every((module) => organizationModules.includes(module));

    if (shouldBeRemoved) {
      try {
        await deleteBuildingModules(organizationName, Number(dataSetId));
        message.success(t("organization-admin.access.update-success"));
      } catch (error) {
        message.error(t("organization-admin.access.update-failed"));
        console.error("Error updating building modules", error);
      }
      return;
    }

    try {
      await updateBuildingModules(organizationName, Number(dataSetId), values);
      message.success(t("organization-admin.access.update-success"));
    } catch (error) {
      message.error(t("organization-admin.access.update-failed"));
      console.error("Error updating building modules", error);
    }
  };

  const buildingAddress = selectedBuilding?.metadata?.Adresse || "";
  const buildingAlias =
    selectedBuilding && aliasConfig?.buildings?.[selectedBuilding.id];

  const fullBuildingInfo = selectedBuilding ? (
    <BuildingInfo
      street={buildingAddress}
      place={selectedBuilding.metadata?.Poststed?.trimStart() || ""}
      alias={buildingAlias}
      imageUrl={buildingsSpec?.imageUrl}
    />
  ) : null;

  return (
    <OrganizationAdminModules
      breadcrumb={buildingAlias || buildingAddress}
      savedModules={savedModules}
      organizationName={organizationName}
      buildingContent={fullBuildingInfo}
      isLoading={isLoading}
      onUpdate={onUpdate}
    />
  );
};
