import { useEffect } from "react";
import { Button, Form, Input, InputNumber, Radio, Select } from "antd";
import { useTranslations } from "@properate/translations";
import { MeterSystemSelect } from "@/components/MeterSystemSelect";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import {
  DEFAULT_CATEGORY_OPTIONS,
  useAvailableFilterOptions,
} from "@/features/timeseries";
import { BaseVirtualSetpoint } from "../../components/MeterSelectionModal/types";

const FormItem = Form.Item;

export interface VirtualSetpointCreationFormProps {
  virtualSetpoint: BaseVirtualSetpoint;
  onFinish: (
    virtualSetpointValues: Pick<
      BaseVirtualSetpoint,
      | "description"
      | "unit"
      | "system"
      | "subBuilding"
      | "min_value"
      | "max_value"
    >,
  ) => void;
  isLoading: boolean;
  onCancel: () => void;
}

export function VirtualSetPointCreationForm({
  virtualSetpoint,
  onFinish,
  isLoading,
  onCancel,
}: VirtualSetpointCreationFormProps) {
  const t = useTranslations();

  const buildingId = useCurrentBuildingId();

  const [form] = Form.useForm<BaseVirtualSetpoint>();

  const { subBuildings } = useAvailableFilterOptions(
    {
      category: "default",
      subBuilding: virtualSetpoint.subBuilding,
      unit: null,
      system: null,
      buildingId,
    },
    DEFAULT_CATEGORY_OPTIONS.map((item) => ({
      ...item,
      label: t(`common.timeseries-modal.filter-categories.${item.label}`),
    })),
  );
  const subBuildingOptions = subBuildings.filter((subBuilding) => {
    return subBuilding !== "Common";
  });

  useEffect(() => {
    if (subBuildingOptions.length !== 1) {
      return;
    }

    form.setFieldValue("subBuilding", subBuildingOptions[0]);
  }, [subBuildingOptions, form]);

  const UNITS = [
    { translationKey: "percent", unit: "%" },
    { translationKey: "bq-per-m3", unit: "Bq/m3" },
    {
      translationKey: "degree-by-hundred",
      unit: "Deg-by-hundred",
    },
    {
      translationKey: "degree-by-ten",
      unit: "Deg-by-ten",
    },
    { translationKey: "gigawatt", unit: "GW" },
    { translationKey: "gigawatt-hours", unit: "GWh" },
    { translationKey: "hertz", unit: "Hz" },
    { translationKey: "kelvin", unit: "K" },
    { translationKey: "megawatt", unit: "MW" },
    { translationKey: "megawatt-hours", unit: "MWh" },
    { translationKey: "nok", unit: "NOK" },
    { translationKey: "nok-per-kwh", unit: "NOK/kWh" },
    { translationKey: "pascal", unit: "Pa" },
    { translationKey: "volt", unit: "V" },
    { translationKey: "watt", unit: "W" },
    { translationKey: "watt-hours", unit: "watt-hours" },
    {
      translationKey: "amperes",
      unit: "amperes",
    },
    { translationKey: "centimeter", unit: "cm" },
    { translationKey: "count-per-ton", unit: "cnt/t" },
    { translationKey: "carbon-dioxide", unit: "co2" },
    { translationKey: "decibels-milliwatt", unit: "dBm" },
    { translationKey: "decibels", unit: "dB" },
    { translationKey: "hectopascal", unit: "hPa" },
    { translationKey: "hours", unit: "hours" },
    { translationKey: "kilopascal", unit: "kPa" },
    { translationKey: "kilovolt-amperes", unit: "kVA" },
    {
      translationKey: "kilowatt-hours",
      unit: "kilowatt-hours",
    },
    {
      translationKey: "kilowatts",
      unit: "kilowatts",
    },
    { translationKey: "liters", unit: "l" },
    { translationKey: "liters-per-hour", unit: "l/h" },
    { translationKey: "liters-per-second", unit: "m³/s" },
    { translationKey: "light", unit: "light" },
    {
      translationKey: "lumens",
      unit: "lumens",
    },
    { translationKey: "lux", unit: "lux" },
    { translationKey: "meter", unit: "m" },
    { translationKey: "millibar", unit: "mBar" },
    { translationKey: "millivolt", unit: "mV" },
    {
      translationKey: "milliamperes",
      unit: "milliamperes",
    },
    { translationKey: "minutes", unit: "minutes" },
    { translationKey: "millimeter", unit: "mm" },
    { translationKey: "cubic-meter", unit: "m³" },
    {
      translationKey: "cubic-meter-per-hour",
      unit: "m³/h",
    },
    { translationKey: "cubic-meter-per-second", unit: "m³/s" },
    {
      translationKey: "on-off",
      unit: "on/off",
    },
    { translationKey: "parts-per-billion", unit: "ppb" },
    { translationKey: "parts-per-million", unit: "ppm" },
    {
      translationKey: "ppm-co2-equivalents",
      unit: "ppm-CO2-equivalents",
    },
    { translationKey: "psi", unit: "psi" },
    { translationKey: "rpm", unit: "r/min" },
    { translationKey: "rssi", unit: "rssi" },
    { translationKey: "seconds", unit: "seconds" },
    { translationKey: "ton", unit: "ton" },
    { translationKey: "voc-co2-eq", unit: "voc_co2_eq" },
    { translationKey: "celsius", unit: "°C" },
    { translationKey: "microgram", unit: "μg" },
    {
      translationKey: "no-units",
      unit: "no-units",
    },
  ] as const;

  const unitsSelectOptions = UNITS.map((item) => ({
    label: t(`tfm.system.units.${item.translationKey}`),
    value: item.unit,
  }));

  function renderFormContents() {
    return (
      <>
        <FormItem<BaseVirtualSetpoint>
          label={t(
            "writable.virtual-set-point-creation-form.description-label",
          )}
          name="description"
          rules={[
            {
              required: true,
              message: t(
                "writable.virtual-set-point-creation-form.description-rule-label",
              ),
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          name="unit"
          label={t(
            "writable.virtual-set-point-creation-form.virtual-set-point-unit-label",
          )}
          rules={[{ required: true }]}
        >
          <Select
            optionFilterProp="label"
            showSearch
            options={unitsSelectOptions}
            autoFocus
            style={{ width: "160px" }}
          />
        </FormItem>
        <FormItem<BaseVirtualSetpoint>
          label={t(
            "writable.virtual-set-point-creation-form.virtual-set-point-min-label",
          )}
          name="min_value"
          rules={[
            {
              validator(_, minValue) {
                const maxValue = form.getFieldValue("max_value");
                if (
                  minValue !== undefined &&
                  maxValue !== undefined &&
                  minValue >= maxValue
                ) {
                  return Promise.reject(
                    new Error(
                      t(
                        "writable.virtual-set-point-creation-form.virtual-set-point-min-greater-than-max-rule-label",
                      ),
                    ),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber decimalSeparator="," style={{ width: "160px" }} />
        </FormItem>
        <FormItem<BaseVirtualSetpoint>
          label={t(
            "writable.virtual-set-point-creation-form.virtual-set-point-max-label",
          )}
          name="max_value"
          rules={[
            {
              validator(_, maxValue) {
                const minValue = form.getFieldValue("min_value");
                if (
                  minValue !== undefined &&
                  maxValue !== undefined &&
                  maxValue <= minValue
                ) {
                  return Promise.reject(
                    new Error(
                      t(
                        "writable.virtual-set-point-creation-form.virtual-set-point-min-greater-than-max-rule-label",
                      ),
                    ),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber decimalSeparator="," style={{ width: "160px" }} />
        </FormItem>
        <FormItem<BaseVirtualSetpoint>
          label={t("timeseries.meter-selection-form.meter-building-label")}
          name="subBuilding"
          rules={[
            {
              required: true,
              message: t(
                "timeseries.meter-selection-form.meter-building-rule-description-label",
              ),
            },
          ]}
        >
          <Radio.Group>
            {subBuildingOptions.map((option) => (
              <Radio key={option} value={option}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
        </FormItem>
        <MeterSystemSelect buildingId={buildingId} />
      </>
    );
  }

  function renderFooter() {
    return (
      <div className="ant-modal-footer">
        <Button
          type="default"
          htmlType="button"
          onClick={() => onCancel()}
          disabled={isLoading}
        >
          {t("writable.virtual-set-point-creation-form.cancel-button-label")}
        </Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          {t("writable.virtual-set-point-creation-form.ok-button-label")}
        </Button>
      </div>
    );
  }

  return (
    <Form
      initialValues={virtualSetpoint}
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      labelAlign="left"
      requiredMark={false}
    >
      {renderFormContents()}
      {renderFooter()}
    </Form>
  );
}
