import { PageHeader } from "@ant-design/pro-layout";
import { Fragment, useMemo } from "react";
import { Button, message, Popconfirm, Select, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import {
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useFloorPlanDelete } from "./hooks/useFloorPlanDelete";
import { useFloorPlanAssets } from "./hooks/useFloorPlanAssets";
import { useFloorPlan, useFloorPlanEditMode } from "./FloorPlanContext";
import { FloorPlanHeaderTitle } from "./FloorPlanHeaderTitle";
import { FloorPlanHeaderPinBar } from "./FloorPlanHeaderPinBar";
import { useFloorPlanBackgroundUpdate } from "./hooks/useFloorPlanBackgroundUpdate";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";
import {
  FloorPlanFloorSelect,
  useFloorPlanFloorOptions,
} from "./FloorPlanFloorSelect";
import { useFloorPlanNavigation } from "./hooks/useFloorPlans";

export function FloorPlanHeader() {
  const t = useTranslations();
  const navigate = useNavigate();
  const assets = useFloorPlanAssets();
  const floorPlan = useFloorPlan();
  const building = useCurrentBuilding();
  const floorPlanDelete = useFloorPlanDelete();
  const [isEditing, setIsEditing] = useFloorPlanEditMode();
  const floorPlanUpdate = useFloorPlanUpdate();
  const floorPlanBackgroundUpdate = useFloorPlanBackgroundUpdate();

  const timeseriesIdsSet = useMemo(() => {
    const set = new Set<number>();

    for (const pin of floorPlan.pins) {
      if (pin.type !== "room" && pin.type !== "label") {
        set.add(pin.timeseriesId);
      }
    }

    return set;
  }, [floorPlan.pins]);

  async function handleBeforeUpload(file: RcFile) {
    const maxSizeMegaBytes = 8;
    const maxSizeBytes = maxSizeMegaBytes * 1024 * 1024;

    if (!file.type.startsWith("image/")) {
      message.error(t("floor-plan-v2.messages.file-not-supported"));
      return false;
    }

    if (file.size > maxSizeBytes) {
      message.error(t("floor-plan-v2.messages.file-too-large"));
      return false;
    }

    return true;
  }

  return (
    <div className="flex flex-col">
      <PageHeader
        title={<FloorPlanHeaderTitle />}
        extra={
          <div className="flex gap-2">
            <div className="pr-2 border-r border-border border-solid border-0 flex gap-1 items-center">
              <div className="text-sm text-muted-foreground">
                {t("floor-plan-v2.rooms-view")}:
              </div>
              <Select
                value={floorPlan.roomsView}
                className="w-48"
                onChange={(value) =>
                  floorPlanUpdate.trigger({
                    roomsView: value,
                  })
                }
                defaultValue={"temperature" as const}
              >
                <Select.Option value="temperature">
                  {t("room-info.temperature")}
                </Select.Option>
                <Select.Option value="motion">
                  {t("room-info.motion")}
                </Select.Option>
                <Select.Option value="co2">{t("room-info.CO2")}</Select.Option>
                <Select.Option value="VOC">{t("room-info.VOC")}</Select.Option>
                <Select.Option value="radon">
                  {t("room-info.radon")}
                </Select.Option>
                <Select.Option value="humidity_sensor">
                  {t("room-info.moisture")}
                </Select.Option>
              </Select>
            </div>
            {!isEditing && <Navigation />}
            {isEditing && (
              <Fragment>
                <FloorPlanFloorSelect
                  showSearch
                  loading={assets.isLoading}
                  disabled={floorPlanUpdate.isMutating}
                  defaultValue={floorPlan.floor}
                  className="w-48"
                  onChange={(value) =>
                    floorPlanUpdate.trigger({
                      floor: value,
                    })
                  }
                />
                <Upload
                  showUploadList={false}
                  multiple={false}
                  beforeUpload={handleBeforeUpload}
                  accept="image/*"
                  maxCount={1}
                  customRequest={(opts) =>
                    floorPlanBackgroundUpdate.trigger({
                      file: opts.file as RcFile,
                    })
                  }
                >
                  <Button
                    icon={<UploadOutlined />}
                    loading={floorPlanBackgroundUpdate.isMutating}
                  >
                    {t("floor-plan-v2.update-image")}
                  </Button>
                </Upload>
                <Popconfirm
                  placement="top"
                  title={t("floor-plan-v2.delete-pop-title")}
                  okText={t("floor-plan-v2.delete-pop-ok-text")}
                  cancelText={t("floor-plan-v2.delete-pop-cancel-text")}
                  onConfirm={() =>
                    floorPlanDelete.trigger(undefined, {
                      onSuccess() {
                        message.success(t("floor-plan-v2.messages.deleted"));
                        navigate(`/asset/${building.id}/floor-plan`);
                      },
                      onError() {
                        message.error(
                          t("floor-plan-v2.messages.deleted-failed"),
                        );
                      },
                    })
                  }
                >
                  <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    loading={floorPlanDelete.isMutating}
                    danger
                  >
                    {t("floor-plan-v2.delete")}
                  </Button>
                </Popconfirm>
              </Fragment>
            )}
            <Button type="default" onClick={() => setIsEditing(!isEditing)}>
              {isEditing
                ? t("floor-plan-v2.edit-exit")
                : t("floor-plan-v2.edit-enter")}
            </Button>
            <Fragment>
              <ToggleSidebarButton
                hiddenWhenSidebarVisible
                className="ml-2"
                sidebarHiddenContent={t("notes.show-notes-button")}
                rootClassName="!ml-0"
              />
              <NotesSidebar
                noteSource={NoteSource.WEB_FLOOR_PLAN}
                assetFilterMode={NotesAssetFilterMode.TimeseriesList}
                idSet={timeseriesIdsSet}
                buildings={[
                  {
                    id: building.dataSetId as number,
                    name: building.name,
                  },
                ]}
              />
            </Fragment>
          </div>
        }
      />
      {isEditing ? (
        <div className="pb-4 pl-4">
          <FloorPlanHeaderPinBar />
        </div>
      ) : null}
    </div>
  );
}

function Navigation() {
  const t = useTranslations();
  const navigate = useNavigate();
  const buildingId = useCurrentBuildingId();
  const floorPlan = useFloorPlan();
  const { next, previous, all: floors } = useFloorPlanNavigation();
  const buttons: JSX.Element[] = [];
  const { getFloorName } = useFloorPlanFloorOptions();

  if (previous) {
    buttons.push(
      <Button
        key="previous"
        onClick={() =>
          navigate(`/asset/${buildingId}/floor-plan/${previous.snapshotId}`)
        }
      >
        <div className="flex gap-2 items-center w-24">
          <LeftOutlined />
          <div className="flex-1 truncate text-right">
            {getFloorName(previous) || previous.name || t("ui.previous")}
          </div>
        </div>
      </Button>,
    );
  }

  if (floors.length > 1) {
    buttons.push(
      <Select
        key="select"
        className="w-32"
        value={floorPlan.snapshotId}
        onChange={(snapshotId) =>
          navigate(`/asset/${buildingId}/floor-plan/${snapshotId}`)
        }
      >
        {floors.map((floor) => (
          <Select.Option key={floor.snapshotId} value={floor.snapshotId}>
            {getFloorName(floor) ||
              floor.name ||
              `${t("ui.notSet")} - ${floor.snapshotId}`}
          </Select.Option>
        ))}
      </Select>,
    );
  }

  if (next) {
    buttons.push(
      <Button
        key="next"
        onClick={() =>
          navigate(`/asset/${buildingId}/floor-plan/${next.snapshotId}`)
        }
      >
        <div className="flex gap-2 items-center w-24">
          <div className="flex-1 truncate text-left">
            {getFloorName(next) || next.name || t("ui.next")}
          </div>
          <RightOutlined />
        </div>
      </Button>,
    );
  }

  if (buttons.length === 0) {
    return null;
  }

  return (
    <div className="flex gap-2 pr-2 border-0 border-r border-border border-solid">
      {buttons}
    </div>
  );
}
