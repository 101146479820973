import useSWR from "swr";
import { SystemMessage } from "@properate/ui/components/system-message";
import { useLocale } from "@properate/translations";
import { useRouteLoaderData } from "react-router-dom";
import { Asset } from "@cognite/sdk";
import { getSystemMessages } from "@/eepApi";

export function SystemMessages() {
  const locale = useLocale();
  const currentBuilding = useRouteLoaderData("building") as Asset | null;

  const {
    data = [],
    isLoading,
    error,
  } = useSWR(
    ["system-messages", locale, currentBuilding?.externalId],
    async ([, locale, buildingExternalId]) =>
      getSystemMessages({
        locale,
        buildingExternalId,
      }),
    {
      refreshInterval: 5 * 60 * 1000,
    },
  );

  if (isLoading || error) {
    return null;
  }

  return data.map((message) => (
    <SystemMessage
      key={message.id}
      title={message.title}
      body={message.body}
      priority={message.priority}
    />
  ));
}
