import { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Modal } from "antd";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { HeatMap } from "@/features/heatmap";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { createHeatMap } from "@/services/heatMap";

export function HeatMapImport() {
  const t = useTranslations();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const heatMap = useLoaderData() as HeatMap;
  const { snapshotId } = useParams() as { snapshotId: string };
  const currentBuildingId = useCurrentBuildingId();
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  async function handleOK() {
    try {
      setIsLoading(true);
      await createHeatMap({
        ...heatMap,
        buildingId: currentBuildingId,
        owner: user.email,
        created: Date.now(),
        importedFrom: snapshotId,
      });
      navigate(`/asset/${currentBuildingId}/analysis/heat-map`);
    } finally {
      setIsLoading(false);
    }
  }

  function handleCancel() {
    navigate(`/asset/${currentBuildingId}/analysis/heat-map`);
  }

  return (
    <Modal
      open={isModalOpen}
      title={t("analysis.heat-map.import-heatmap")}
      onOk={handleOK}
      onCancel={handleCancel}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <p>
        {t("analysis.heat-map.do-you-want-to-import", {
          name: heatMap.name,
        })}
      </p>
      <p>{t("analysis.heat-map.import-description")}</p>
    </Modal>
  );
}
