import { ParentSize } from "@visx/responsive";
import { Link, useLocation } from "react-router-dom";
import { Card, Popconfirm, Button, Space } from "antd";
import { DeleteOutlined, ImportOutlined } from "@ant-design/icons";
import { WithSnapshotId } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { ShareButtonWithDialog } from "@/components/ShareButtonWithDialog/ShareButtonWithDialog";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { SimpleGraph } from "../SimpleGraph/SimpleGraph";
import { ScatterplotGraph } from "../ScatterplotGraph/ScatterplotGraph";
import {
  useGetImmutableTimeSpan,
  useRemoveSettingsTimeseriesHiddenRootAsset,
} from "../../hooks";
import { isScatterplotAnalysis } from "../../utils";
import { Analysis, SettingsTimeseriesScatterplot } from "../../types";

interface Props {
  analysis: WithSnapshotId<Analysis>;
  onConfirmRemoval: (snapshotId: string) => unknown;
}

export function AnalysisListCard({ analysis, onConfirmRemoval }: Props) {
  const t = useTranslations();
  const currentBuildingId = useCurrentBuildingId();
  const { overrideUnits } = useTimeseriesSettings(currentBuildingId);
  const settingsTimeseriesList = useRemoveSettingsTimeseriesHiddenRootAsset(
    analysis.settingsTimeseriesList,
    overrideUnits,
  ).filter((settingsTimeseries) => !settingsTimeseries.hidden);
  const timeSpan = useGetImmutableTimeSpan(analysis.settingsTimeseriesList);
  const location = useLocation();
  const shareLink = `${window.location.origin}${location.pathname}/${analysis.snapshotId}/import`;

  function getTitle() {
    return (
      <Space>
        {analysis.title || t("analysis.no-name")}
        {typeof analysis.importedFrom === "string" && (
          <ImportOutlined title={t("analysis.imported")} />
        )}
      </Space>
    );
  }

  return (
    <Card
      key={analysis.snapshotId}
      title={getTitle()}
      extra={
        <Space>
          <ShareButtonWithDialog
            title={t("analysis.share-analysis")}
            shareLink={shareLink}
          >
            {t("analysis.share-text.analysis")}
          </ShareButtonWithDialog>
          <Popconfirm
            title={t("analysis.are-you-sure-to-delete")}
            onConfirm={() => onConfirmRemoval(analysis.snapshotId)}
            okText={t("analysis.delete")}
            cancelText={t("analysis.cancel")}
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      }
      styles={{ body: { height: 300 } }}
      hoverable
    >
      <Link
        to={analysis.snapshotId}
        style={{ display: "block", width: "100%", height: "100%" }}
        aria-label={t("analysis.see-detailed-analysis-with-name", {
          name: analysis.title || t("analysis.no-name"),
        })}
      >
        <ParentSize>
          {({ width, height }) =>
            isScatterplotAnalysis(analysis) ? (
              <ScatterplotGraph
                width={width}
                height={height}
                zoomedTimeSpan={timeSpan}
                granularity={analysis.granularity}
                settingsTimeseriesList={
                  settingsTimeseriesList as SettingsTimeseriesScatterplot[]
                }
                settingsTimeseriesX={analysis.settingsTimeseriesX}
                mergeUnits={analysis.mergeUnits}
              />
            ) : (
              <SimpleGraph
                width={width}
                height={height}
                timeSpan={timeSpan}
                zoomedTimeSpan={timeSpan}
                settingsTimeseriesList={settingsTimeseriesList}
                mergeUnits={analysis.mergeUnits}
              />
            )
          }
        </ParentSize>
      </Link>
    </Card>
  );
}
