import useSWR from "swr";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { bisector } from "d3-array";
import { useContext, useMemo, useState } from "react";
import { CogniteClient } from "@cognite/sdk";
import dayjs from "@properate/dayjs";
import _ from "lodash";
import {
  formatMeasurement,
  isOperationalHour,
  OperationalHoursType,
} from "@properate/common";
import { scaleLinear, scaleTime } from "@visx/scale";
import { Group } from "@visx/group";
import { Line, LinePath } from "@visx/shape";
import {
  defaultStyles,
  Tooltip,
  useTooltip,
  useTooltipInPortal,
} from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { curveBasis } from "@visx/curve";
import { ThemeContext } from "styled-components";
import { Modal, Space, App } from "antd";
import { useTranslations } from "@properate/translations";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import hd from "@/utils/holidays";
import { ACCENT3, PRIMARY } from "@/utils/ProperateColors";
import { useCogniteClient } from "@/context/CogniteClientContext";
import {
  calculateSumOfEnergyDataPoints,
  numberFormatter,
} from "@/utils/helpers";
import { SelectOrganization } from "@/components/SelectOrganization/SelectOrganization";
import { DashboardStandardWidget, NotificationInstance } from "@/utils/types";
import { DraggableCard } from "../draggable/DraggableCard";
import { WidgetHeader } from "../widget-components/WidgetHeader";

interface Props {
  buildingId: number;
  organizationsToTotalEnergyTimeseriesIds: Record<string, number>;
  closedPublicHolidays: boolean;
  operationalHours: OperationalHoursType[];
  width: number;
  height: number;
  organizations: string[]; // owner org is expected to be the first one
  onClickRemoveButton: () => unknown;
  type: Extract<
    DashboardStandardWidget,
    | "energyUseThisWeek"
    | "energyUseThisWeekTempCorrectedEtmod"
    | "energyUseThisWeekTempCorrectedGraddag"
  >;
}

type OverviewPoint = {
  empty?: boolean;
  time: number;
  value?: number;
  lastWeek?: number;
};

const getEnergy =
  (
    client: CogniteClient,
    closedPublicHolidays: boolean,
    operationalHours: OperationalHoursType[],
    notificationInstance: NotificationInstance,
  ) =>
  async (props: string) => {
    const result = await calculateSumOfEnergyDataPoints(
      client,
      notificationInstance,
    )(props);
    return result!.map(
      (d) =>
        ({
          empty:
            !isOperationalHour(
              dayjs(d.time),
              operationalHours,
              closedPublicHolidays,
            ) ||
            (closedPublicHolidays && hd.isHoliday(new Date(d.time))),
          ...d,
        }) as OverviewPoint,
    );
  };

const getEnergyLastWeek =
  (client: CogniteClient, notificationInstance: NotificationInstance) =>
  async (props: string) => {
    const result = await calculateSumOfEnergyDataPoints(
      client,
      notificationInstance,
    )(props);
    return result!.map(
      (d) =>
        ({
          time: d.time + 7 * 24 * 60 * 60 * 1000,
          lastWeek: d.value,
        }) as OverviewPoint,
    );
  };

export function EnergyUseThisWeekWidget({
  buildingId,
  organizationsToTotalEnergyTimeseriesIds,
  closedPublicHolidays,
  operationalHours,
  width,
  height,
  organizations,
  onClickRemoveButton,
  type,
}: Props) {
  const t = useTranslations();

  const { notification } = App.useApp();
  const { client } = useCogniteClient();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: preferences } = useUserSettings();
  const organization =
    preferences?.buildings?.[buildingId]?.[type]?.organization ||
    organizations.at(0);
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const {
    tooltipData: lastWeekTooltipData,
    tooltipLeft: lastWeekTooltipLeft,
    tooltipTop: lastWeekTooltipTop,
    tooltipOpen: lastWeekTooltipOpen,
    showTooltip: lastWeekShowTooltip,
    hideTooltip: lastWeekHideTooltip,
  } = useTooltip();

  const themeContext = useContext(ThemeContext);
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });
  const [start, end] = useMemo(
    () => [dayjs().startOf("w"), dayjs().endOf("w")],
    [],
  );

  const { data } = useSWR(
    typeof organization === "string" &&
      organizationsToTotalEnergyTimeseriesIds[organization]
      ? JSON.stringify({
          granularity: "h",
          start: start.toDate().toISOString(),
          end: end.toDate().toISOString(),
          items: [organizationsToTotalEnergyTimeseriesIds[organization]],
        })
      : [],
    getEnergy(client, closedPublicHolidays, operationalHours, notification),
  );

  const { data: lastWeek } = useSWR(
    typeof organization === "string" &&
      organizationsToTotalEnergyTimeseriesIds[organization]
      ? JSON.stringify({
          granularity: "h",
          start: start.subtract(1, "w").toDate().toISOString(),
          end: start.toDate().toISOString(),
          items: [organizationsToTotalEnergyTimeseriesIds[organization]],
        })
      : [],
    getEnergyLastWeek(client, notification),
  );

  const graphValues = Object.values(
    _.merge(_.keyBy(data, "time"), _.keyBy(lastWeek, "time")),
  ).sort((a, b) => a.time - b.time);

  // bounds
  const padding = 24;
  const headerHeight = 46;
  const graphWidth = width > 0 ? width - padding * 2 : 0;
  const graphHeight = height - padding * 2 - headerHeight;

  const yTop = 104;
  const yAxisWidth = 30;
  const xAxisHeight = 30;

  const xMax = graphWidth > 0 ? graphWidth - yAxisWidth : 0;
  const yMax = graphHeight - yTop - xAxisHeight;

  const timeScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        round: true,
        domain: [start.valueOf(), end.valueOf()],
      }),
    [xMax, start, end],
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [
          0,
          Math.max(
            ...(graphValues || []).map((gv) => gv.value || 0),
            ...(graphValues || []).map((gv) => gv.lastWeek || 0),
          ),
        ],
      }),
    [yMax, graphValues],
  );

  const bisectDate = bisector<any, Date>((d) => new Date(d.time)).left;

  const getDataForEvent = (
    event: React.MouseEvent<SVGRectElement, MouseEvent>,
  ): OverviewPoint | void => {
    const point = event.currentTarget.ownerSVGElement
      ? localPoint(event.currentTarget.ownerSVGElement, event)
      : null;
    const coords = point || { x: 0, y: 0 };
    const x0 = timeScale.invert(coords.x - yAxisWidth);
    const index = bisectDate(graphValues, x0, 1);
    const d0 = graphValues[index - 1];
    const d1 = graphValues[index];
    let d = d0;
    if (d1 && d1.time) {
      d =
        x0.valueOf() - d0.time.valueOf() > d1.time.valueOf() - x0.valueOf()
          ? d1
          : d0;
    }
    return d;
  };

  const handleLastWeekTooltip = (
    event: React.MouseEvent<SVGRectElement, MouseEvent>,
  ) => {
    const d = getDataForEvent(event);

    if (!d || !d.lastWeek) {
      return;
    }

    lastWeekShowTooltip({
      tooltipData: {
        date: dayjs(d.time).format("dddd H:mm"),
        value: formatMeasurement({ value: d.lastWeek, unit: "kWh" }),
      },
      tooltipLeft:
        yAxisWidth + timeScale(d.time) + Math.ceil(xMax / (24 * 7) / 2 / 2),
      tooltipTop: yScale(d.lastWeek) + yTop,
    });
  };

  const handleTooltip = (
    event: React.MouseEvent<SVGRectElement, MouseEvent>,
  ) => {
    handleLastWeekTooltip(event);
    const d = getDataForEvent(event);

    if (!d) {
      return;
    }

    if (typeof d.value === "number") {
      showTooltip({
        tooltipData: {
          date: dayjs(d.time).format("dddd H:mm"),
          value: formatMeasurement({ value: d.value, unit: "kWh" }),
          operational: !d.empty,
        },
        tooltipLeft:
          yAxisWidth + timeScale(d.time) + Math.ceil(xMax / (24 * 7) / 2 / 2),
        tooltipTop: yScale(d.value) + yTop,
      });
    } else {
      hideTooltip();
    }
  };

  function handleChangeOrganization(organization: string) {
    mutateUserSettings({
      buildings: {
        [buildingId]: {
          [type]: {
            organization,
          },
        },
      },
    });
  }

  function getWidgetHeaderTitle() {
    const isOwnerOrganization = organization === organizations.at(0);
    const organizationOwnerType =
      typeof organization === "string"
        ? isOwnerOrganization
          ? "owner"
          : "tenant"
        : "";
    return t("dashboard.widgets.energy-consumption.title", {
      type,
      organizationOwnerType,
    });
  }

  return (
    <div ref={containerRef}>
      <DraggableCard
        style={{ height }}
        bordered={false}
        styles={{
          body: { padding, position: "relative" },
        }}
        title={
          <WidgetHeader
            text={getWidgetHeaderTitle()}
            onClickRemoveButton={onClickRemoveButton}
            onClickSettingsButton={() => setIsModalVisible(true)}
            isDraggable
          />
        }
      >
        <div
          style={{
            position: "absolute",
            top: 24,
            right: 24,
            fontSize: "13px",
            lineHeight: "13px",
            color: themeContext.neutral4,
            textAlign: "right",
          }}
        >
          <table cellPadding={4}>
            <tbody>
              <tr>
                <td>{t("dashboard.widgets.energy-consumption.energy-use")}</td>
                <td style={{ color: PRIMARY, fontSize: "24px" }}>•</td>
              </tr>
              <tr>
                <td>
                  {t(
                    "dashboard.widgets.energy-consumption.outside-operating-hours",
                  )}
                </td>
                <td style={{ color: ACCENT3, fontSize: "24px" }}>•</td>
              </tr>
              <tr>
                <td>{t("dashboard.widgets.energy-consumption.last-week")}</td>
                <td
                  style={{
                    color: themeContext.neutral5,
                    fontSize: "24px",
                  }}
                >
                  —
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <svg width={graphWidth} height={graphHeight}>
          <AxisLeft
            top={yTop}
            left={yAxisWidth}
            scale={yScale}
            tickStroke={themeContext.neutral4}
            stroke={themeContext.neutral4}
            numTicks={5}
            tickFormat={(val: any) => numberFormatter(val)}
            hideAxisLine
            hideTicks
            tickLabelProps={{
              fill: themeContext.neutral4,
            }}
          />
          <GridRows
            numTicks={5}
            scale={yScale}
            width={xMax}
            top={yTop}
            left={yAxisWidth}
            fill={themeContext.neutral7}
          />
          <AxisBottom
            top={yMax + yTop}
            left={yAxisWidth}
            scale={timeScale}
            numTicks={7}
            stroke={themeContext.neutral4}
            tickStroke={themeContext.neutral4}
            tickFormat={(date: any) => {
              const m = dayjs(date);
              return m.format("dddd");
            }}
            tickLabelProps={{
              fill: themeContext.neutral4,
              scaleToFit: true,
            }}
          />
          <Group>
            {graphValues.map((val) => {
              const barHeight = yMax - yScale(val.value || 0);
              const barY = yMax - barHeight + yTop;
              return (
                <rect
                  key={val.time}
                  x={yAxisWidth + timeScale(val.time)}
                  y={barY}
                  width={Math.ceil(xMax / (24 * 7) / 2)}
                  height={barHeight}
                  fill={val.empty ? ACCENT3 : PRIMARY}
                />
              );
            })}
          </Group>
          <g>
            <LinePath
              data={graphValues}
              x={(d: any) => yAxisWidth + timeScale(d.time)}
              y={(d: any) => yScale(d.lastWeek || 0) + yTop}
              stroke={themeContext.neutral5}
              strokeWidth={2}
              curve={curveBasis}
            />
          </g>
          <rect
            x={yAxisWidth}
            y={yTop}
            width={xMax}
            height={yMax}
            fill="transparent"
            onMouseMove={(event) => handleTooltip(event)}
            onMouseLeave={() => {
              hideTooltip();
              lastWeekHideTooltip();
            }}
          />
          {lastWeekTooltipData && typeof lastWeekTooltipTop === "number" && (
            <g>
              <Line
                from={{ x: lastWeekTooltipLeft, y: yTop }}
                to={{ x: lastWeekTooltipLeft, y: yTop + yMax }}
                stroke={themeContext.neutral5}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={lastWeekTooltipLeft}
                cy={lastWeekTooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={lastWeekTooltipLeft}
                cy={lastWeekTooltipTop}
                r={4}
                fill={themeContext.neutral5}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
          {tooltipData && typeof tooltipTop === "number" && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: yTop }}
                to={{ x: tooltipLeft, y: yTop + yMax }}
                stroke={themeContext.neutral5}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={(tooltipData as any).operational ? PRIMARY : ACCENT3}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
        {isModalVisible && (
          <Modal
            title={t(
              "dashboard.widgets.energy-consumption.settings-for-energy-consumption-this-week",
            )}
            open={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
          >
            <Space>
              <label htmlFor={`${type}-organization`}>
                {t("dashboard.widgets.energy-consumption.organization")}
              </label>
              <SelectOrganization
                id={`${type}-organization`}
                organization={organization}
                organizations={organizations}
                handleChangeOrganization={handleChangeOrganization}
              />
            </Space>
          </Modal>
        )}
      </DraggableCard>
      {tooltipOpen && (
        <>
          <TooltipInPortal
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft! + 24}
          >
            <span
              style={{
                color: (tooltipData as any)?.operational ? PRIMARY : ACCENT3,
                fontSize: "24px",
                verticalAlign: "middle",
                marginRight: "5px",
              }}
            >
              •
            </span>
            {(tooltipData as any)?.value}
          </TooltipInPortal>
          <Tooltip
            top={yTop - 5}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              //minWidth: 72,
              textAlign: "center",
              transform: "translate(-50%,-100%)",
            }}
          >
            {(tooltipData as any)?.date}
          </Tooltip>
        </>
      )}
      {lastWeekTooltipOpen && (
        <>
          <TooltipInPortal
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={lastWeekTooltipTop}
            left={lastWeekTooltipLeft}
            style={{ position: "absolute" }}
          >
            <div
              style={{
                ...defaultStyles,
                whiteSpace: "nowrap",
                textAlign: "center",
                transform: "translate(-100%,0)",
              }}
            >
              <span
                style={{
                  color: themeContext.neutral5,
                  fontSize: "24px",
                  verticalAlign: "middle",
                  marginRight: "5px",
                }}
              >
                —
              </span>
              {(lastWeekTooltipData as any)?.value}
            </div>
          </TooltipInPortal>
          {
            <Tooltip
              top={yTop - 5}
              left={lastWeekTooltipLeft}
              style={{
                ...defaultStyles,
                margin: 0,
                textAlign: "center",
                transform: "translate(-50%,-100%)",
              }}
            >
              {(lastWeekTooltipData as any)?.date}
            </Tooltip>
          }
        </>
      )}
    </div>
  );
}
