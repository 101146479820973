import { Select, Space, DatePicker } from "antd";
import dayjs from "@properate/dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import CheckableTag from "antd/es/tag/CheckableTag";
import { useTranslations } from "@properate/translations";

type Props = {
  start: Date;
  setStart: (date: Date) => void;
  end: Date;
  setEnd: (date: Date) => void;
};

const getEndDates = (start: Date, end?: Date) => {
  if (
    dayjs(start).tz("Europe/Oslo").endOf("day").isSame(start) ||
    dayjs(start)
      .tz("Europe/Oslo")
      .endOf("day")
      .subtract(30, "minutes")
      .isSameOrBefore(start)
  ) {
    return [dayjs(start).tz("Europe/Oslo").endOf("day").toDate()];
  }
  const endDates = [];
  let next = dayjs(start).tz("Europe/Oslo");
  if (next.minute() % 15 !== 0) {
    if (next.minute() < 15) {
      next = next.minute(15);
    } else if (next.minute() < 30) {
      next = next.minute(30);
    } else if (next.minute() < 45) {
      next = next.minute(45);
    } else {
      next = next.add(1, "hour").minute(0);
    }
    endDates.push(next.toDate());
  }

  next = next.add(15, "minutes");
  const endOfDay = dayjs(start).tz("Europe/Oslo").startOf("day").add(1, "day");

  while (next.isSameOrBefore(endOfDay)) {
    endDates.push(next.toDate());
    next = next.add(15, "minutes");
  }
  if (end && !endDates.find((d) => d.valueOf() === end.valueOf())) {
    return [...endDates, end].sort((a, b) => a.valueOf() - b.valueOf());
  }
  return endDates;
};

const numberFormat = new Intl.NumberFormat("NO", { maximumFractionDigits: 2 });

const SelectTime = ({ start, setStart, end, setEnd }: Props) => {
  const t = useTranslations();

  const [endDates, setEndDates] = useState<Date[]>(getEndDates(start, end));
  const [allDay, setAllDay] = useState(false);

  useEffect(() => {
    setEndDates(getEndDates(start, end));
  }, [start, end, setEndDates]);

  const getPeriodLengthAsString = useCallback(
    (current: Date, start: Date) => {
      const minuteDiff = dayjs(current)
        .tz("Europe/Oslo")
        .diff(start, "minutes");
      const hours = Math.floor(minuteDiff / 60);
      const minutes = minuteDiff % 60;

      if (hours === 0) {
        return t("calendar.minutes", { value: minutes });
      }

      return t("calendar.hour", {
        value: numberFormat.format(hours + minutes / 60),
      });
    },
    [t],
  );

  const options = useMemo(
    () =>
      endDates.map((date) => ({
        value: date.valueOf(),
        label: `${dayjs(date)
          .tz("Europe/Oslo")
          .format("HH:mm")} (${getPeriodLengthAsString(date, start)})`,
      })),
    [endDates, start, getPeriodLengthAsString],
  );

  const handleAllDay = (allDay: boolean) => {
    setAllDay(allDay);
    if (allDay) {
      setStart(dayjs(start).tz("Europe/Oslo").startOf("day").toDate());
      setEnd(dayjs(start).tz("Europe/Oslo").endOf("day").toDate());
    }
  };

  return (
    <>
      <Space>
        <span>
          <DatePicker
            picker="time"
            value={dayjs(start)}
            onCalendarChange={(values) => {
              if (!values) return;

              const newStart = Array.isArray(values) ? values[0] : values;

              if (newStart) {
                setStart(newStart.toDate());
                setEndDates(getEndDates(newStart.toDate()));
                if (dayjs(newStart).add(1, "hour").isAfter(end)) {
                  setEnd(
                    new Date(
                      Math.min(
                        dayjs(newStart)
                          .tz("Europe/Oslo")
                          .add(1, "hour")
                          .valueOf(),
                        dayjs(newStart)
                          .tz("Europe/Oslo")
                          .startOf("day")
                          .add(1, "day")
                          .valueOf(),
                      ),
                    ),
                  );
                }
                if (allDay) {
                  setAllDay(false);
                }
              }
            }}
            size="large"
            format="HH:mm"
          />
          {" - "}
          <Select
            options={options}
            value={end.valueOf()}
            size="large"
            style={{ width: 200 }}
            onSelect={(value: number) => {
              setEnd(new Date(value));
              if (allDay) {
                setAllDay(false);
              }
            }}
          />
        </span>

        <CheckableTag checked={allDay} onChange={handleAllDay}>
          {t("calendar.whole-day")}
        </CheckableTag>
      </Space>
    </>
  );
};

export default SelectTime;
