import { useMemo } from "react";
import { useTranslations } from "@properate/translations";

export const SENSOR_QUERIES = {
  all: "(labels = real_value OR labels = set_point)",
  "coil-cool": "labels = real_value AND labels = cooling",
  "dx-cool": "labels = real_value AND labels = cooling",
  "coil-heat-el": "labels = real_value AND labels = heat_battery",
  "coil-heat-liq": "labels = real_value AND labels = heat_battery",
  "coil-comb-liq":
    "labels = real_value AND (labels = cooling OR labels = heat_battery)",
  pump: "labels = real_value AND labels = pump",
  "heat-pump":
    "labels = real_value AND (labels = pump OR labels = status OR labels = heat_pump)",
  compressor: "labels = real_value AND labels = refrigeration_unit",

  "heat-recovery-liq":
    "labels = real_value AND (labels = inverter OR labels = heat_recovery OR labels = motor)",
  "heat-recovery-rot":
    "labels = real_value AND (labels = inverter OR labels = heat_recovery OR labels = motor)",
  "heat-recovery-plate":
    "labels = real_value AND (labels = inverter OR labels = heat_recovery OR labels = motor)",
  damper:
    "labels = real_value AND (labels = VAV OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "damper-supply-motor":
    "labels = real_value AND labels = supply AND (labels = VAV OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "damper-extract-motor":
    "labels = real_value AND labels = extract AND (labels = VAV OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "damper-recirculate":
    "labels = real_value AND (labels = VAV OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "gauge-temperature": "labels = real_value AND labels = temperature",
  "gauge-pressure-diff":
    "labels = real_value AND (labels = differential_pressure OR labels = pressure_transducer)",
  "gauge-pressure":
    "labels = real_value AND (labels = differential_pressure OR labels = pressure_transducer)",
  "gauge-level": "labels = real_value",
  "gauge-general":
    "(labels = real_value OR (labels = set_point AND labels != writable))",
  "gauge-volume": "labels = real_value AND labels = flowmeter",
  "gauge-relhumidity": "labels = real_value AND labels = humidity_sensor",
  "gauge-gas":
    "labels = real_value AND (labels = co2 OR labels = VOC OR labels = radon OR labels = humidity_sensor OR labels = flowmeter OR labels = gas_detector)",
  "gauge-motion": "labels = real_value AND labels = motion",
  fan: "labels = real_value AND (labels = fan OR labels = energy_meter OR labels = energy_power OR labels = flowmeter)",
  "fan-supply":
    "labels = real_value AND labels = supply AND (labels = fan OR labels = energy_meter OR labels = energy_power OR labels = flowmeter)",
  "fan-extract":
    "labels = real_value AND labels = extract AND (labels = fan OR labels = energy_meter OR labels = energy_power OR labels = flowmeter)",
  "expansion-vessel": "labels = real_value",
  "accumulator-tank": "labels = real_value",
  "boiler-vvb-el": "labels = real_value",
  "boiler-vvb-liq": "labels = real_value",
  "boiler-oil": "labels = real_value",
  "boiler-gas": "labels = real_value",
  "boiler-el": "labels = real_value",
  "boiler-solid-state": "labels = real_value",
  "valve-3dir-motor":
    "labels = real_value AND (labels = valve OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "valve-3dir-manual":
    "labels = real_value AND (labels = valve OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "valve-4dir":
    "labels = real_value AND (labels = valve OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "valve-2dir-control-manual":
    "labels = real_value AND (labels = valve OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "valve-2dir-control-motor":
    "labels = real_value AND (labels = valve OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "valve-2dir-general":
    "labels = real_value AND (labels = valve OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "valve-2dir-nonreturn":
    "labels = real_value AND (labels = valve OR labels = motor OR labels = control_valve_motorized OR labels = actuator)",
  "floor-sensor-general":
    "labels = real_value AND (labels = motion OR labels = VOC OR labels = co2 OR labels = radon OR labels = humidity_sensor OR labels = temperature)",
  "floor-sensor-temperature": "labels = real_value AND labels = temperature",
  "floor-sensor-humidity": "labels = real_value AND labels = humidity_sensor",
  "floor-sensor-gas":
    "labels = real_value AND (labels = VOC OR labels = co2 OR labels = radon OR labels = co)",
  "floor-sensor-motion": "labels = real_value AND labels = motion",
  "set-point-general":
    "labels = set_point AND labels = writable AND labels != schedule",
};

const sensorQueryKeys = Object.keys(
  SENSOR_QUERIES,
) as (keyof typeof SENSOR_QUERIES)[];

export const useSensorCategoryOptions = () => {
  const t = useTranslations();

  return useMemo(
    () =>
      sensorQueryKeys.map((key) => ({
        value: key,
        query: SENSOR_QUERIES[key],
        label: t(`common.setpoints-query-labels.${key}`),
      })),
    [t],
  );
};

export const useSensorCategoryOptionsWithoutSetPoints = () => {
  const t = useTranslations();
  const options = useSensorCategoryOptions();

  return options
    .filter(
      (option) =>
        option.value !== "set-point-general" && option.value !== "all",
    )
    .concat({
      value: "all",
      query: "(labels = real_value AND labels != set_point)",
      label: t("common.setpoints-query-labels.all-without-setpoints"),
    });
};
